import React from "react";


import Card from "./Card";

import stillsa from "../../../img/web/Est/stillsaBig.jpg";
import stillsb from "../../../img/web/Est/stillsbBig.webp";

const Card_Stills = () => {
  return (
    <>
      <Card
        Id_Card="Id15"
        Header_Title="Still Images.
              Workflow."
        Header_SubTitle="FOR ARCHITECTURE."
        Size_Card="small"
        Footer_Paragraph="Renderings have become vital for promoting products and real estate projects. They are an integral part of presentations as they add extra value to every design.

                Here you can check our usal workflow.."
        Image_Src_Jpg={stillsa}
        Image_Src_Webp={stillsb}
        SetLink="/work/Stills"
      />
    </>
  );
};
export default Card_Stills;
