import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import Burger from "../../../components/Nav/Burger.jsx";

import Footer from "../../Home/Footer";

import { AeroportFontCont } from "../../../fonts/AeroportFont_Cont.js";
import { HLineMinMax } from "../../../components/HLine.js";

import "swiper/css/free-mode";
import "swiper/css/pagination";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

import "./SwiperCss.css";

import Card_Berilo from "../Cards/CardInidividual_Berilo.js"; // dev
import Card_Numa from "../Cards/CardInidividual_Numa.js"; //dev
import Card_Stills from "../Cards/CardInidividual_Stills.js"; //dev
import reactGA from "react-ga4";

import HeaderPic from "../WorksComponents/HeaderPic.js";
import OnePic from "../WorksComponents/OnePic.js";
import OneVideo from "../WorksComponents/OneVideo.js";

import Spy from "../../../Spy.js";

import OneVideoVimeo from "../WorksComponents/OneVideoVimeo.js";

//-------------- styled Components abajo-------------------

//-CONTENEDOR PRINCIPAL (CntFrame) -START-------------------------------------------------------------------------------------
const CntNavBar = styled.div`
  //background-color: #EEEEEE;

  z-index: 50;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  position: fixed;
  justify-items: center;
  top: 0;
  right: 0;
  height: 5%;
  width: 50vw;
  justify-self: stretch;
  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 100vw;
    grid-template-columns: 1rem 1rem auto 1rem 80px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    width: 100vw;
    grid-template-columns: 2.5rem 1vw auto 1vw 100px;
  }
  @media screen and (min-width: 769px) {
    grid-template-columns: 2.5vw 1vw auto 1vw 120px;
  }
`;

const CntFrame = styled.div`
  width: 100vw;
  background-color: #eeeeee;
  color: black;
  //opacity: 0.3;
`;

//-CONTENEDOR PRINCIPAL (CntFrame) -END----------------------------------------------------------------------------------------

//-HEADER-START----------------------------------------------------------------------------------------------------------------
const CntHeaderFrame = styled.div`
  width: 100%; //auto;º
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    align-items: start;
    justify-content: start;
    height: 100vh;
  }
  @media screen and (min-width: 769px) {
    padding-top: 120px;
  }
  @media (max-height: 1050px) and (max-width: 480px) {
    width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const CntHeaderInside = styled.div`
  //background-color: blueviolet  ;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  top: 0px;
  left: 0px;
  margin-right: 10vw;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr auto;
    width: 100vw;
    height: 100vh;
  }
  @media screen and (min-width: 769px) and (max-width: 1279px) {
    grid-template-columns: 55% 45%;
  }
  @media screen and (min-width: 1280px) {
    grid-template-columns: 65% 35%;
  }
`;

const CntHeaderTxTSuperScreen_S_P = styled.div`
  display: grid;
  align-items: end;

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  height: auto;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

const CntHeaderExtra = styled.div`
  flex-direction: column;
  width: fit-content;
  margin-left: 5vw;
  margin-bottom: 5vw;
`;

const CntHeaderTxTSuperScreen_M_B = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    display: none;
  }
`;

const CntHeaderTxTinferior = styled.div`
  justify-self: end;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: fit-content;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-top: 5vw;
    margin-bottom: 10vw;
  }
`;

const CntHeaderTxt = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  margin: 0;
  display: grid;

  @media screen and (min-width: 769px) {
    padding-left: 2.5vw;
    padding-top: 2.2vw;
  }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    display: none;
  }
`;

const CntHeaderParrafo = styled.div`
  line-height: 0.5;
  width: 100%;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    width: calc(100% - 10vw);
    margin-left: 5vw;
  }
  @media screen and (min-width: 1279px) and (max-width: 1570px) {
    div {
      font-size: 15px;
    }
  }
  @media screen and (min-width: 1279px) and (max-width: 1340px) {
    div {
      font-size: 13px;
    }
  }
`;

const CntHeaderTitle = styled.div`
  margin-left: -0.08em;
  @media screen and (min-width: 1279px) and (max-width: 1500px) {
    font-size: 90px;
  }
  @media screen and (min-width: 769px) and (max-width: 830px) {
    font-size: 40px;
  }
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-right: 5vw;
  }
`;

const CntTextoHeaderPhone = styled.div`
  opacity: 1;
  width: 100vw;
  height: fit-content;
  background-color: #cfcfcf;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
//-HEADER-END---------------------------------------------------------------------------------------------------------

//-TEXTBODY_1-START---------------------------------------------------------------------------------------------------
const CntTextBody_1Frame = styled.div`
  opacity: 1;
  width: 100vw;
  height: fit-content;
  background-color: #eeeeee;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
`;

const CntTextBody_1TxtParf = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 5%;
  margin-bottom: 5%;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 10vw;
    margin-bottom: 10vw;
    width: calc(100vw - 10vw);
  }
`;
//-TEXTBODY_1-END---------------------------------------------------------------------------------------------------

//-TEXTBODY_2-START---------------------------------------------------------------------------------------------------
const CntTextBody_2Frame = styled.div`
  opacity: 1;
  width: 100vw;
  height: fit-content;
  background-color: #eeeeee;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const CntTextBody_2TxtParf = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 5%;
  margin-bottom: 5%;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 20vw;
    margin-bottom: 20vw;
  }
`;
//-TEXTBODY_2-END---------------------------------------------------------------------------------------------------

//-TEXTBODY_3-START---------------------------------------------------------------------------------------------------
const CntTextBody_3Frame = styled.div`
  opacity: 1;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 10vw;
  width: 50vw;
  height: fit-content;
  background-color: #eeeeee;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding: 4vh 0 4vh 0;
  }
`;

const CntTextBody_3FrameLeft = styled.div`
  opacity: 1;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 40vw;
  width: 50vw;
  height: fit-content;
  background-color: #eeeeee;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding: 4vh 0 4vh 0;
  }
`;

const CntTextBody_3TxtParf1 = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 0%;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin: 5vw;
    margin-top: 10vw;
    width: calc(100vw - 10vw);
  }
`;

const CntTextBody_3TxtParf2 = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-top: 2%;
  margin-right: 10vw;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin: 5vw;
    margin-bottom: 10vw;
    width: calc(100vw - 10vw);
  }
`;
//-TEXTBODY_3-END---------------------------------------------------------------------------------------------------

//-MORESTORIES-START--------------------------------------------------------------------------------------------------

const CntMSService = styled.div`
  padding-top: 10px;
  width: 100vw;
  height: auto;
  background-color: #eeeeee;
  margin-bottom: 80px;
`;

const SliderMS = styled.div`
  width: 100vw;
  height: auto;
  overflow-x: visible;
  grid-auto-flow: column;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
`;

const CntMSTxtTitle = styled.div`
  a {
    //quita el espacio izq de la primera letra
    margin-left: -0.04em;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 40px;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding-left: 5vw;
    padding-bottom: 5vw;
  }
  @media screen and (min-width: 769px) {
    padding-left: 2.5vw;
    padding-bottom: 2.5vw;
  }
`;

const CntMSImgGeneral = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 17.5px);

  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: calc(100vw);
  }
  @media screen and (min-width: 0px) and (max-width: 315px) {
    width: calc(200vw - 17.5px);
  }
  @media screen and (min-width: 316px) and (max-width: 480px) {
    width: calc(220vw - 17.5px);
  }
  @media screen and (min-width: 481px) and (max-width: 939px) {
    width: calc(180vw - 17.5px);
  }
  @media screen and (min-width: 940px) and (max-width: 979px) {
    width: calc(120vw - 17.5px);
  }
  @media screen and (min-width: 980px) {
    width: calc(100vw - 17.5px);
  }
`;

//-MORESTORIES-END--------------------------------------------------------------------------------------------------

// import de Imagenes------------------------------------------
import image_1a from "../../../img/Work/Car/Car_1Big.jpg";
import image_1b from "../../../img/Work/Car/Car_1Big.webp";

//import image_2a from "../../../img/Work/Car/Car_2Big.jpg";
//import image_2b from "../../../img/Work/Car/Car_2Big.webp";

//import image_3a from "../../../img/Work/Car/Car_3Big.jpg";
//import image_3b from "../../../img/Work/Car/Car_3Big.webp";

//import image_4a from "../../../img/Work/Car/Car_4Big.jpg";
//import image_4b from "../../../img/Work/Car/Car_4Big.webp";

import image_5a from "../../../img/Work/Car/Car_5Big.jpg";
import image_5b from "../../../img/Work/Car/Car_5Big.webp";

const Work_Individual_Car = () => {
  Spy("OpenPages", "CarWorkAccess");

  /*--- A TENER EN CUENTA ---

  imagenes se cargan mas arriba por variable.s
  los textos se escriben igual que en una plantilla HTML

  el componente : <AeroportFontCont>
  es una caja de texto donde ya lleva las mediaQuerys 
  incluidas para modificar el tamaño de letra y el espaciado vertical.
  asi mismo si uno hace un DIV hijo puede agregar otro tamaño de letra con una mediaQuery nueva.
                  
                  sizePhone=  (min-width:0px) and (max-width: 480px) 
                  sizeSmall=  (min-width:481px) and (max-width: 768px) 
                  sizeMedium= (min-width:769px) and (max-width: 1279px) 
                  sizeBig=    (min-width:1280px)

                  lHeightPhone= igual que para el size
                  lHeightSmall=
                  lHeightMedium=
                  lHeightBig=
  --------------------------------------- */
  document.body.scrollTop = document.documentElement.scrollTop = 0;
  const cardAnimRef = useRef(null);

  useEffect(() => {
    //carrousel MORE STORIES START-----------------------------------------------------------------
    //drag & move
    const slider = document.getElementById("sliderMoreStories");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      slider.style.userSelect = "none";
      isDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
    });

    slider.addEventListener("mouseup", () => {
      isDown = false;
    });

    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
    //carrousel MORE STORIES END-----------------------------------------------------------------
  }, []);

  //funcion para quiebre de linea-----------------------------------------------------------------
  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));
  //-----------------------------------------------------------------------------------------

  return (
    <>
      <CntFrame>
        <CntNavBar id="boton">
          <Burger />
        </CntNavBar>

        {/*-HEADER-START----------------------------------------------------------------------------*/}
        <CntHeaderFrame id="Header">
          <CntHeaderInside>
            {/*------- ESTO SOLO APARECE EN WIDTH < 769 -----------------*/}
            {/*SE HIZO PARA PODER INTERCALAR TEXTO DE ABAJO HACIA ARRIBA */}

            <CntHeaderTxTSuperScreen_S_P>
              <CntHeaderExtra>
                <AeroportFontCont
                  sizePhone="45px"
                  sizeSmall="75px"
                  lHeightDefault="1"
                >
                  <CntHeaderTitle>
                    <a>MOVIE WORKFLOW.</a>
                  </CntHeaderTitle>
                </AeroportFontCont>

                <AeroportFontCont
                  sizePhone="12px"
                  sizeSmall="16px"
                  lHeightPhone="1.5"
                  lHeightSmall="1.8"
                >
                  <p>FOR ARCHITECTURE.</p>
                </AeroportFontCont>
              </CntHeaderExtra>
            </CntHeaderTxTSuperScreen_S_P>
            {/*--------------------------------------------------------*/}

            <HeaderPic
              imgWebpBig={image_1a}
              imgAltBig={image_1b}
              altTxt1={"texto alternativo"}
            />

            {/*------- ESTO SOLO APARECE EN WIDTH > 770 ----------------*/}
            <CntHeaderTxt>
              <CntHeaderTxTSuperScreen_M_B>
                <AeroportFontCont
                  sizeMedium="10px"
                  sizeBig="16px"
                  lHeightMedium="1.8"
                  lHeightBig="2.6"
                >
                  <p>FOR ARCHITECTURE.</p>
                </AeroportFontCont>
                <AeroportFontCont
                  sizeMedium="55px"
                  sizeBig="70px"
                  lHeightMedium="0.8"
                  lHeightBig="0.8"
                >
                  <CntHeaderTitle>
                    <a>MOVIE WORKFLOW.</a>
                  </CntHeaderTitle>
                </AeroportFontCont>
              </CntHeaderTxTSuperScreen_M_B>
              <CntHeaderTxTinferior>
                <CntHeaderParrafo>
                  <HLineMinMax
                    startPx="100px"
                    endPx="3000px"
                    width="calc(100%)"
                    left="0px"
                    mBottom=""
                    mtop="0"
                  />
                  <AeroportFontCont
                    sizeMedium="14px"
                    sizeBig="21px"
                    lHeightMedium="1"
                    lHeightBig="1.2"
                    style={{ marginTop: "2.5vw" }}
                  >
                    <p>
                      Over the past few years, video has risen into popularity
                      across the globe. Software like Twinmotion and Lumion are
                      some of the solutions that allow architects to make decent
                      images and videos with little time and experience. So when
                      should you call a specialist like us? <br />
                      <br />
                      When your project is too complex, the quality you are
                      getting is not enough, or when you need a custom solution.
                      <br />
                      <br />
                      Below you can check our workflow and some custom solutions
                      we offer.
                    </p>
                  </AeroportFontCont>
                </CntHeaderParrafo>
              </CntHeaderTxTinferior>
            </CntHeaderTxt>
          </CntHeaderInside>
        </CntHeaderFrame>
        {/*----------------------------------------------------------*/}

        {/*------- ESTO SOLO APARECE EN WIDTH < 769 -----------------*/}
        <CntTextoHeaderPhone>
          <CntHeaderTxTinferior>
            <CntHeaderParrafo>
              <AeroportFontCont
                sizePhone="14px"
                sizeSmall="14px"
                lHeightPhone="1"
                lHeightSmall="0.9"
                style={{ marginTop: "2.5vw" }}
              >
                <p>
                  Video has grown in popularity over the last few years all
                  around the world. It's on television, social media,
                  billboards, online advertisements, and restaurant menus
                  everywhere.
                  <br />
                  <br />
                  Software like Twinmotion and Lumion are some of the solutions
                  that enable architects to create decent images and videos with
                  little experience. <br />
                  <br />
                  So when should you call a specialist like us? When your
                  project is too complex, the quality you are reciving is
                  insufficient, or when you need a custom solution.
                  <br />
                  <br />
                  Below you can check our workflow and some custom solutions we
                  offer. Hope this solves some doubts you have about video
                  production.
                </p>
              </AeroportFontCont>
            </CntHeaderParrafo>
          </CntHeaderTxTinferior>
        </CntTextoHeaderPhone>
        {/*----------------------------------------------------------*/}
        {/*-HEADER-END----------------------------------------------------------------------------*/}

        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3Frame>
          <CntTextBody_3TxtParf1>
            <AeroportFontCont
              sizePhone="20px"
              sizeSmall="20px"
              sizeMedium="27px"
              sizeBig="40px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                <b>Stage 01: Cameras and Mood.</b>
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf1>
          <CntTextBody_3TxtParf2>
            <AeroportFontCont
              sizePhone="14px"
              sizeSmall="14px"
              sizeMedium="14px"
              sizeBig="21px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                We start working in our script and storyboard. It's important to
                make a discovery meeting where everyone involved gets a general
                idea of what the video is going to show and what's the story
                behind the scenes.
                <br />
                <br />
                <b>We Deliver:</b> •Storyboard with the scenes and mood in
                general. •A selection of music tracks. <br />
                <br />
                <b>You Deliver:</b> •Your camera selection. •Comments in the
                scenes order. •Confirm mood and ligthing. •Chose a music track
                for the movie.
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf2>
        </CntTextBody_3Frame>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}

        {/*-BODYONEPIC-START----------------------------------------------------------------------------*/}
        <OnePic
          imgWebpBig={image_5a}
          imgAltBig={image_5b}
          altTxt1={"texto alternativo"}
        />
        {/*-BODYONEPIC-END----------------------------------------------------------------------------*/}

        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3FrameLeft>
          <CntTextBody_3TxtParf1>
            <AeroportFontCont
              sizePhone="20px"
              sizeSmall="20px"
              sizeMedium="27px"
              sizeBig="40px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                <b>Stage 02: Animatic and stills.</b>
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf1>
          <CntTextBody_3TxtParf2>
            <AeroportFontCont
              sizePhone="14px"
              sizeSmall="14px"
              sizeMedium="14px"
              sizeBig="21px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                In this stage everything start coming into motion. We use
                different kind of animatics depending on what we want to show
                and how much time we have.
                <br />
                <br />
                <b>We Deliver:</b> •An animated storyboard. •Still images render
                in 2k of each scene with materials in place and fully equipped
                in 3d. •Graphic design for titles. <br />
                <br />
                <b>You Deliver:</b> •Materials. •3d models (cars, landscape,
                furniture, landscape). •Lighting. •Camera and elements movement.
                •Graphic design and titles.
                <br />
                <br />
                If a camera changes or it's completely removed in this stage,
                additional charges may apply.
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf2>
        </CntTextBody_3FrameLeft>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}

        {/*-BODYONEPIC-START----------------------------------------------------------------------------

        <OneVideo src={"https://stream.mux.com/j3xSUA5anTz1riD4ajOyotfBdBIFZVKAu4Vmw4EEgAk.m3u8"} referencia={"videoWorkerA"} altTxt1={"texto alternativo"} />*/}

        <OneVideoVimeo url={"https://vimeo.com/853121355"} />
        

        {/*-BODYONEPIC-END----------------------------------------------------------------------------*/}

        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3Frame>
          <CntTextBody_3TxtParf1>
            <AeroportFontCont
              sizePhone="20px"
              sizeSmall="20px"
              sizeMedium="27px"
              sizeBig="40px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                <b>Stage 03: Final Movie.</b>
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf1>
          <CntTextBody_3TxtParf2>
            <AeroportFontCont
              sizePhone="14px"
              sizeSmall="14px"
              sizeMedium="14px"
              sizeBig="21px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                <b>We Deliver:</b> •The movie in it's final form. Only small
                changes in postproduction or composing will be done if
                necessary. <br />
                <br />
                <b>Extra Stages:</b> •In case the project gets modified, or the
                client feels it is necessary to continue working on the movie,
                more stages of comments can be added for an extra.
                <br />
                <br />
                In the video below, you can see the same scene in the three
                different stages.
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf2>
        </CntTextBody_3Frame>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}

        {/*-BODYONEPIC-START----------------------------------------------------------------------------*/}
                
      <OneVideoVimeo url={"https://vimeo.com/853121401"} />
        
        {/*-BODYONEPIC-END----------------------------------------------------------------------------*/}

        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3FrameLeft>
          <CntTextBody_3TxtParf1>
            <AeroportFontCont
              sizePhone="20px"
              sizeSmall="20px"
              sizeMedium="27px"
              sizeBig="40px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                <b>Camera Tracking and Drone Videos.</b>
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf1>
          <CntTextBody_3TxtParf2>
            <AeroportFontCont
              sizePhone="14px"
              sizeSmall="14px"
              sizeMedium="14px"
              sizeBig="21px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                Nothing create a more perfect illusion that merging your project
                in a drone video. Using tracking and composing software, we are
                able to match the camera movement, lighting and colors of our 3d
                model with reality.
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf2>
        </CntTextBody_3FrameLeft>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}

        {/*-BODYONEPIC-START----------------------------------------------------------------------------*/}

               <OneVideoVimeo url={"https://vimeo.com/853121495"}/>

        {/*-BODYONEPIC-END----------------------------------------------------------------------------*/}

        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3Frame>
          <CntTextBody_3TxtParf1>
            <AeroportFontCont
              sizePhone="20px"
              sizeSmall="20px"
              sizeMedium="27px"
              sizeBig="40px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                <b>Motion Graphics and Concept Animations.</b>
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf1>
          <CntTextBody_3TxtParf2>
            <AeroportFontCont
              sizePhone="14px"
              sizeSmall="14px"
              sizeMedium="14px"
              sizeBig="21px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                There is nothing better to illustrate complex concepts, than
                combining schematic models with colors, text, and movement to
                highlight different aspects of our proposal.
                <br />
                <br />
                Also, since realistic rendering is time-consuming, using the
                above combination is a cost-effective solution for projects that
                are in early stages of development.
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf2>
        </CntTextBody_3Frame>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}

        {/*-BODYONEPIC-START----------------------------------------------------------------------------*/}

        <OneVideoVimeo url={"https://vimeo.com/853121520"}/>

        {/*-BODYONEPIC-END----------------------------------------------------------------------------*/}

        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3FrameLeft>
          <CntTextBody_3TxtParf1>
            <AeroportFontCont
              sizePhone="20px"
              sizeSmall="20px"
              sizeMedium="27px"
              sizeBig="40px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                <b>Cartoon People.</b>
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf1>
          <CntTextBody_3TxtParf2>
            <AeroportFontCont
              sizePhone="14px"
              sizeSmall="14px"
              sizeMedium="14px"
              sizeBig="21px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                Cartoon People helps you be more inclusive with your characters
                by allowing you to modify all their aspects, such as different
                types of body, hairstyle, skin tone, and physical abilities.
                This allows seeing how the users will interact with their
                surroundings and the ambient of the project.
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf2>
        </CntTextBody_3FrameLeft>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}

        {/*-BODYONEPIC-START----------------------------------------------------------------------------*/}

        <OneVideoVimeo url={"https://vimeo.com/853121464"} />

        {/*-BODYONEPIC-END----------------------------------------------------------------------------*/}

        {/*-MORESTORIES-START----------------------------------------------------------------------------*/}
        <CntMSService>
          <CntMSTxtTitle>
            <AeroportFontCont
              sizePhone="55px"
              sizeSmall="85px"
              sizeMedium="55px"
              sizeBig="110px"
              lHeightDefault="1"
              pTop="30px"
            >
              <a>More Stories</a>
            </AeroportFontCont>
          </CntMSTxtTitle>
          <SliderMS id="sliderMoreStories">
            <CntMSImgGeneral ref={cardAnimRef}>
              <Card_Berilo />
              <Card_Numa />
              <Card_Stills />
            </CntMSImgGeneral>
          </SliderMS>
        </CntMSService>
        {/*-MORESTORIES-END----------------------------------------------------------------------------*/}

        <Footer />
      </CntFrame>
    </>
  );
};

export default Work_Individual_Car;
