import { createSlice } from '@reduxjs/toolkit'

export const VideoEstadoWorked3Slice = createSlice({
  

  name: 'videoEstadoWorked3',
  initialState: {
    value: false,
  },
  reducers: {
    PlayingW3: (state) => {
      state.value = true
    },
    PausedW3: (state) => {
      state.value = false
    },
  },



})


export const { PlayingW3, PausedW3 } = VideoEstadoWorked3Slice.actions

export default VideoEstadoWorked3Slice.reducer

