import { createSlice } from '@reduxjs/toolkit'

export const HeightStateSlice = createSlice({
  

  name: 'HeightEstado',
  initialState: {value: 0},  //{ value: 0 }
  reducers: {
    heightState: (state, action ) => {
      //state.value,
      state.value = action.payload
     
    },

  },



})


export const { heightState } = HeightStateSlice.actions

export default HeightStateSlice.reducer



//esto iria del otro lado

//let heightImageFirstCard = useSelector((state) => state.HeightEstado.value)