import React, { useEffect, useRef, useState } from "react";
import styled  from "styled-components";
import ReactPlayer from "react-player";
import gsap from "gsap";

const VideoContainer = styled.div`  
  display: flex;
  justify-content: start;
  align-content: start;
  grid-row-start:1;
  grid-row-end: 2;
  grid-column-start:1;
  grid-column-end: 2;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  background-color: gray;
  height: auto;
`;


const VideoVimeoComponent = ({ url, fullScreen, videoId, videoRefs  }) => {
  const playerRef = useRef(null);
  const videoWrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const pauseVideo = () => {
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().pause();
    }
  };

  const playVideo = () => {
    
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().play();
    }
  };
  
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (videoWrapperRef.current) {
      observer.observe(videoWrapperRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      gsap.fromTo(
        videoWrapperRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 1, delay: 1 }
      );
    }
  }, [isVisible]);

  useEffect(() => {
    if (videoRefs) {
      videoRefs.current[videoId] = {
        playVideo: playVideo,
        pauseVideo: pauseVideo,
      };
    }
  }, [videoRefs, videoId]);

 

  return (
    <>
      <VideoContainer>       
        <VideoWrapper ref={videoWrapperRef}>
          <ReactPlayer
            style={{
              position: "absolute",
              top: "0",
              left: "0",
            }}
            width="100%"
            height="100%"
            ref={playerRef}
            url={url}
            controls={fullScreen === true ? true : false}
            muted={fullScreen === true ? false : true}
            autoPlay={fullScreen === true ? true : false}            
            config={{
              vimeo: {
                quality: "auto",
              },
            }}    
          />
        </VideoWrapper>
      </VideoContainer>
    </>
  );
};

export { VideoVimeoComponent };
