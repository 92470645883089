import { createSlice } from '@reduxjs/toolkit'

export const LoaderRun = createSlice({


  name: 'loaderRuned',
  initialState: {
    value: "no",
  },
  reducers: {
    yesRuned: (state) => {
      state.value = "yes"
      console.log("it has runned the loader")
    },
    noRuned: (state) => {
      state.value = "no" 
      console.log("it hasnt runned the loader")
    },
  },




})


export const { yesRuned,noRuned} = LoaderRun.actions

export default LoaderRun.reducer
