import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

import { AeroportFontCont } from "../../fonts/AeroportFont_Cont";
import Burger from "../../components/Nav/Burger.jsx";
import CargaImagenes from "./CargaImagenes";
import Footer from "../Home/Footer";
import useMediaQuery from "../../hooks/MediaQueryhook";
import Spy from "../../Spy";
import { colorRojoNod } from "../../VariablesGlobales.js";
//--------------------------------------

import { ImageWebp } from "../../components/ImageWebp.js";

import cara from "../../img/web/Est/caraBig.jpg";
import carb from "../../img/web/Est/carbBig.webp";

import { MargenesCards } from "../../VariablesGlobales.js";

const varMargenBig = MargenesCards("A");
const varMargenSmall = MargenesCards("B");

import { img } from "./photos";

const FirstFrame = styled.div`
  background-color: #eeeeee;
  div {
    //opacity: 0.7;
    //  border-color: black;
    // border-style: solid;
    //  border-width: 1px;
  }
`;

const WrapperdivAux = styled.div`
  position: relative;
  opacity: 0;
  // background-color: red;

  @media screen and (min-width: 0px) and (max-width: 529px) {
    width: 100%;
  }
  @media screen and (min-width: 530px) and (max-width: 768px) {
    width: 50%;
  }
`;

const ContenedorImagen = styled.div`
  background-color: blue;
  //z-index: 20;
  opacity: 0;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  overflow: hidden;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin: ${varMargenSmall} ${varMargenSmall};
    width: calc(100% - calc(${varMargenSmall} * 2));
  }
  @media screen and (min-width: 769px) {
    display: none;
    margin: ${varMargenBig} ${varMargenBig};
    width: calc(100% - calc(${varMargenBig} * 2));
  }
`;

const ContainerNavBar = styled.div`
  //z-index: 50;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  position: fixed;
  justify-items: center;
  top: 0;
  right: 0;
  height: 5%;
  width: 50vw;
  justify-self: stretch;
  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 100vw;
    grid-template-columns: 1rem 1rem auto 1rem 80px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    width: 100vw;
    grid-template-columns: 2.5rem 1vw auto 1vw 100px;
  }
  @media screen and (min-width: 769px) {
    grid-template-columns: 2.5vw 1vw auto 1vw 120px;
  }
`;

const ContainerService = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100vw;
  background-color: #eeeeee;
  //background-color: orange;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    height: calc(100vh - ${(props) => props.hValueAuxMarginFirstImg}px);
    //height:400px;
  }
`;

const ConTxT = styled.div`
  z-index: 9;
  //background-color: red;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding-left: 5vw;
    padding-bottom: 5vw;
  }
  @media screen and (min-width: 769px) {
    padding-left: 2.5vw;
    padding-bottom: 2.5vw;
  }
`;

const ContTxtTitle = styled.div`
  //background-color: brown;
  a {
    //quita el espacio izq de la primera letra
    margin-left: -0.04em;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;

  @media screen and (min-width: 0px) and (max-width: 768px) {
    //height: 100vh;
    //height: calc(100vh - ${(props) => props.hValueAuxMarginFirstImg}px);
  }
`;

const ContImgGeneral = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  //z-index: 0;
  background-color: #eeeeee;
  //background-color:brown;
  display: grid;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    //padding-top: 10px;
  }
  padding-bottom: 2.5vw;
  width: 100%;
`;

const Filtros = styled.div`
  cursor: pointer;
  a:hover {
    color: #ef4125;
  }
`;

const MaxCont = styled.div`
  background-color: #eeeeee;
  display: grid;
  grid-template-columns: 1fr;
  //grid-template-rows: 2fr;
`;

const CardFrame = styled.div`
  border-color: #d6d6d6;
  border-width: 1px;
  border-style: solid;
  display: grid;
  cursor: pointer;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding: ${varMargenSmall} ${varMargenSmall};
    //width: calc(100% - calc( ${varMargenSmall} * 2 ))  ;
  }
  @media screen and (min-width: 769px) {
    padding: ${varMargenBig} ${varMargenBig};
    //width: calc(100% - calc( ${varMargenBig} * 2 ))  ;
  }
`;

//-loader---

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const LoaderCircle = styled.div`
  border: 4px solid ${colorRojoNod}; /* Color rojo */
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const GalleryImages = () => {
  Spy("OpenPages", "Catalog");

  //document.body.scrollTop = document.documentElement.scrollTop = 0;

  var isMediaQuery769px = useMediaQuery("(max-width: 769px)");
  var isMediaQueryMin769px = useMediaQuery("(min-width: 769px)");
  const ContTxtTitleRef = useRef(null);
  const cardAnimRef = useRef(null);

  const divAuxiliartoHeight = useRef(null);
  const [hValueAuxMarginFirstImg, setHValueAuxMarginFirstImg] = useState(0);

  const [filterName, setFilterName] = useState("all");

  const Reset = () => {
    document.getElementById("idALL").style.color = "#ef4125";
    document.getElementById("idRESIDENTIAL").style.color = "black";
    document.getElementById("idCULTURAL").style.color = "black";
    document.getElementById("idOFFICES").style.color = "black";
    // document.getElementById("idINTERIORISM").style.color = "black";
    document.getElementById("idURBANISM").style.color = "black";
  };

  const AllOff = () => {
    document.getElementById("idALL").style.color = "black";
  };

  const TextColor = (rojo) => {
    document.getElementById("idALL").style.color = "black";
    document.getElementById("idRESIDENTIAL").style.color = "black";
    document.getElementById("idCULTURAL").style.color = "black";
    document.getElementById("idOFFICES").style.color = "black";
    // document.getElementById("idINTERIORISM").style.color = "black";
    document.getElementById("idURBANISM").style.color = "black";
    document.getElementById(rojo).style.color = "#ef4125";
  };

  const Anima = () => {
    gsap.fromTo(
      cardAnimRef.current,
      {
        duration: 1,
        opacity: 0,
        delay: 1,
      },
      {
        duration: 1,
        opacity: 1,
        delay: 1,
      }
    ); //&:
  };

  window.addEventListener(
    "resize",
    function (event) {
      if (isMediaQuery769px == true) {
        setHValueAuxMarginFirstImg(divAuxiliartoHeight.current.clientHeight);
        //    console.log("1 hValueAuxMarginFirstImg", hValueAuxMarginFirstImg);
      }
    },
    true
  );

  function filterImagesByFilterName(filtros) {
    const filteredImages2 = img.filter((item) => item.filter.includes(filtros));

    const result = filteredImages2.map((item) => ({
      big: item.big,
      small: item.small,
      tipo: item.tipo,
    }));

    return result;
  }

  let filteredImages = filterImagesByFilterName(filterName);

  useEffect(() => {
    // Función que se ejecuta cuando la imagen se ha cargado completamente
  
    const handleImageLoad = () => {
      setHValueAuxMarginFirstImg(divAuxiliartoHeight.current.clientHeight);
    };

    // Agregar un event listener para el evento 'load' de la imagen referenciada
    divAuxiliartoHeight.current.addEventListener("load", handleImageLoad);

    // Retirar el event listener cuando el componente se desmonte
    return () => {
      // divAuxiliartoHeight.current.removeEventListener("load", handleImageLoad);
    };
  
  }, []);

  //------------------------------- loader ------------------------------------------------
  const [showLoader, setShowLoader] = useState(true);

  

  //opcion 2 de loader ver en netlify

  useEffect(() => {
    const checkPageLoad = () => {
      if (document.readyState === "complete") {
        setShowLoader(false);
      } else {
        window.requestAnimationFrame(checkPageLoad);
      }
    };
    // Comienza la verificación en el próximo ciclo de animación.  


    const requestCheckload = () => {
      window.requestAnimationFrame(checkPageLoad);
    };

    document.addEventListener("DOMContentLoaded", requestCheckload);
    window.addEventListener("load", requestCheckload);

    const timer = setTimeout(requestCheckload, 2000);

    return () => {
      clearTimeout(timer);
    };


    
  }, []);
  //------------------------------- loader-end ------------------------------------------------

  const SvgComponentLogo = () => (
    <svg
      width="50" // Cambia el ancho a 50 para que coincida con LoaderCircle
      height="50" // Cambia la altura a 50 para que coincida con LoaderCircle
      viewBox="0 0 84 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        display: "block",
        margin: "auto", // Centra horizontalmente
        marginTop: "auto", // Centra verticalmente
        marginBottom: "auto", // Centra verticalmente
        maxWidth: "100%", // Asegura que el SVG no sea más grande que el contenedor
        maxHeight: "100%", // Asegura que el SVG no sea más grande que el contenedor
        justifySelf: "center",
        alignSelf: "center",
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 1,
        gridRowEnd: 2,
        zIndex: 20,
      }}
    >
      <path
        d="M80.0368 2.07218C80.0362 1.71937 80.1403 1.37431 80.336 1.08071C80.5316 0.787111 80.81 0.558179 81.1358 0.422888C81.4617 0.287597 81.8203 0.252042 82.1664 0.320726C82.5125 0.38941 82.8303 0.559241 83.0798 0.808716C83.3293 1.05819 83.4991 1.37609 83.5678 1.72215C83.6365 2.06821 83.6009 2.42687 83.4656 2.75272C83.3304 3.07856 83.1014 3.35692 82.8078 3.55256C82.5142 3.7482 82.1692 3.85231 81.8164 3.85172C81.5809 3.85838 81.3465 3.8169 81.1276 3.72983C80.9087 3.64276 80.7098 3.51192 80.5432 3.34532C80.3766 3.17873 80.2458 2.9799 80.1587 2.76098C80.0716 2.54207 80.0302 2.30769 80.0368 2.07218ZM83.2732 2.07218C83.2702 1.78472 83.1822 1.50455 83.0203 1.26698C82.8585 1.0294 82.6299 0.845021 82.3635 0.737068C82.097 0.629115 81.8046 0.602408 81.523 0.660311C81.2414 0.718214 80.9833 0.858134 80.781 1.06246C80.5788 1.26678 80.4415 1.52638 80.3865 1.80856C80.3315 2.09073 80.3613 2.38287 80.4719 2.64819C80.5826 2.91351 80.7693 3.14014 81.0086 3.29956C81.2478 3.45898 81.5289 3.54405 81.8164 3.54407C82.0101 3.54827 82.2027 3.51294 82.3823 3.44026C82.5619 3.36758 82.7249 3.25907 82.8612 3.12134C82.9975 2.98361 83.1044 2.81955 83.1752 2.63917C83.246 2.45879 83.2793 2.26587 83.2732 2.07218ZM82.2899 2.96194L81.8103 2.16869H81.4876V2.96194H81.1558V1.07685H81.8676C81.9441 1.06829 82.0215 1.07609 82.0947 1.09971C82.1679 1.12333 82.2352 1.16224 82.2923 1.21386C82.3493 1.26547 82.3947 1.32862 82.4255 1.39912C82.4563 1.46961 82.4718 1.54585 82.4708 1.62277C82.4751 1.72424 82.4486 1.82462 82.3947 1.91074C82.3409 1.99687 82.2623 2.0647 82.1692 2.10536L82.6971 2.96194H82.2899ZM81.4876 1.87312H81.8224C82.0214 1.87312 82.124 1.77659 82.124 1.62277C82.124 1.46895 82.0154 1.37545 81.8224 1.37545H81.4876V1.87312Z"
        fill="black"
      />
      <path
        d="M0.000732422 18.6313V51.236H5.19456L10.3914 32.6052V0.000488281H5.19456L0.000732422 18.6313Z"
        fill="black"
      />
      <path
        d="M11.4553 18.6308V51.2355H18.6157L25.773 32.6047V0H18.6157L11.4553 18.6308Z"
        fill="black"
      />
      <path
        d="M26.8384 18.6308V51.2355H38.9724L51.1034 32.6047V0H38.9724L26.8384 18.6308Z"
        fill="black"
      />
      <path
        d="M52.1672 31.4857V51.2355H76.4322V0H72.6711L52.1672 31.4857Z"
        fill="black"
      />
      <path
        d="M27.0218 55.1567V85.1193H21.5625L4.95556 62.4981V85.1193H0V55.1567H5.45926L22.0662 77.7779V55.1567H27.0218Z"
        fill="black"
      />
      <path
        d="M52.6173 74.6042C52.6173 80.9683 47.7432 85.7067 41.3821 85.7067C34.9275 85.7067 30.0986 80.9683 30.0986 74.6042C30.0986 68.2401 34.9245 63.4595 41.3821 63.4595C47.7432 63.4595 52.6173 68.1888 52.6173 74.6042ZM47.8336 74.6042C47.8336 70.7254 45.1704 67.8812 41.3821 67.8812C37.5938 67.8812 34.8823 70.7254 34.8823 74.6042C34.8823 78.3955 37.636 81.285 41.3821 81.285C45.1281 81.285 47.8336 78.3955 47.8336 74.6042Z"
        fill="black"
      />
      <path
        d="M76.3538 54.918V85.1188H71.5702V83.1794C70.0802 84.7598 67.6491 85.7069 64.9346 85.7069C58.0759 85.7069 54.1941 80.3804 54.1941 74.6044C54.1941 68.8285 58.0759 63.4597 64.9346 63.4597C67.6491 63.4597 70.0802 64.4068 71.5702 65.9873V54.918H76.3538ZM65.2513 67.8874C61.5053 67.8874 58.9777 70.9549 58.9777 74.6105C58.9777 78.266 61.5053 81.2913 65.2513 81.2913C68.545 81.2913 71.7512 79.1256 71.7512 74.6105C71.7512 70.0953 68.545 67.8874 65.2513 67.8874Z"
        fill="black"
      />
    </svg>
  );

  const loaderRef = React.createRef();



  function obtenerAlturaBarraNavegacion() {
    const alturaVentana = window.innerHeight;
    const alturaTotal = window.screen.height;
    const alturaBarraNavegacion = alturaTotal - alturaVentana;
    return alturaBarraNavegacion;
  }

  const altura = obtenerAlturaBarraNavegacion();
  

  useEffect(() => {
   console.log("altura",altura, "  hValueAuxMarginFirstImg",hValueAuxMarginFirstImg)
  });
  

  
  return (
    <>
      {showLoader && (
        <>
          <LoaderWrapper ref={loaderRef}>
            <LoaderCircle
              style={{
                gridColumnStart: 1,
                gridColumnEnd: 2,
                gridRowStart: 1,
                gridRowEnd: 2,
              }}
            />

            <SvgComponentLogo />
          </LoaderWrapper>
        </>
      )}

      <ContainerNavBar id="boton" style={{ zIndex: "15" }}>
        <Burger />
      </ContainerNavBar>
      <FirstFrame>
        <MaxCont>
          <ContainerService id="cServ">
            <ContTxtTitle
              ref={ContTxtTitleRef}
              //hValueAuxMarginFirstImg={hValueAuxMarginFirstImg}
              style={{
                ...(isMediaQuery769px ? {height: `calc( ${window.innerHeight}px - ${hValueAuxMarginFirstImg}px - 10px)`} : {})
                ,
              }}
            >
              <ConTxT>
                <AeroportFontCont
                  sizePhone="55px"
                  sizeSmall="85px"
                  sizeMedium="55px"
                  sizeBig="110px"
                  lHeightDefault="1"
                  pTop="30px"
                >
                  <a>Our Catalog</a>
                </AeroportFontCont>
                <AeroportFontCont
                  sizePhone="16px"
                  sizeSmall="16px"
                  sizeMedium="10px"
                  sizeBig="16px"
                  lHeightPhone="1.5"
                  lHeightSmall="1.8"
                  lHeightMedium="1.5"
                  lHeightBig="1.6"
                >
                  <Filtros id="filtros2">
                    <a
                      id="idALL"
                      style={{ color: "#ef4125" }}
                      onClick={() => Reset() & Anima() & setFilterName("all")}
                    >
                      ALL,{" "}
                    </a>
                    <a
                      id="idRESIDENTIAL"
                      onClick={() =>
                        AllOff() &
                        //setStateRenders(true) &
                        Anima() &
                        TextColor("idRESIDENTIAL") &
                        setFilterName("residential")
                      }
                    >
                      RESIDENTIAL,{" "}
                    </a>
                    <a
                      id="idCULTURAL"
                      onClick={() =>
                        AllOff() &
                        //setStateMovies(true) &
                        Anima() &
                        TextColor("idCULTURAL") &
                        setFilterName("cultural")
                      }
                    >
                      CULTURAL,{" "}
                    </a>
                    <a
                      id="idOFFICES"
                      onClick={() =>
                        AllOff() &
                        //setStateWebVr(true) &
                        Anima() &
                        TextColor("idOFFICES") &
                        setFilterName("offices")
                      }
                    >
                      COMMERCIAL,{" "}
                    </a>
                    <br />
                    {/* <a
                      id="idINTERIORISM"
                      onClick={() =>
                        AllOff() &
                        //setStateArchitects(true) &
                        Anima() &
                        TextColor("idINTERIORISM") &
                        setFilterName("interiorism")
                      }
                    >
                      INTERIORISM,{" "}
                    </a> */}
                    <a
                      id="idURBANISM"
                      onClick={() =>
                        AllOff() &
                        //setStateDevelopers(true) &
                        Anima() &
                        TextColor("idURBANISM") &
                        setFilterName("urbanism")
                      }
                    >
                      URBANISM AND LANDSCAPE
                    </a>
                  </Filtros>
                </AeroportFontCont>
              </ConTxT>
            </ContTxtTitle>
          </ContainerService>

          {/*---------------- imagen auxiliar para tomar el alto --------------------*/}

          <img // se usa img porque se ajusta automaticamente
            ref={divAuxiliartoHeight}
            style={{
              zIndex: "-1",
              display: "block",
              opacity: 0,
              position: "absolute",
              top: 0,
              left: 0,
              width: "calc(100% - 20px)",
              height: "auto",
              margin: "10px",
            }}
            src={filteredImages[0].small}
            alt="Descripción de la imagen"
          />

      

            

          {/*---------------- imagen auxiliar para tomar el alto --------------------*/}

          <ContImgGeneral ref={cardAnimRef}>
            <CargaImagenes images={filteredImages} />
          </ContImgGeneral>
        </MaxCont>
        <Footer />
      </FirstFrame>
    </>
  );
};

export { GalleryImages };
