import { createSlice } from '@reduxjs/toolkit'

export const VideoEstadoSlice = createSlice({
  

  name: 'videoEstado',
  initialState: {
    value: false,
  },
  reducers: {
    Playing: (state) => {
      state.value = true
    },
    Paused: (state) => {
      state.value = false 
    },
  },



})


export const { Playing, Paused } = VideoEstadoSlice.actions

export default VideoEstadoSlice.reducer

