import { createGlobalStyle } from "styled-components";

import Aeroport from "./woff/Aeroport.woff";
import Aeroport_Bold from "./woff/Aeroport_Bold.woff";
import Aeroport_Light from "./woff/Aeroport-Light_1.woff";
import Aeroport_Medium from "./woff/Aeroport_Medium.woff";
import Aeroport_Mono from "./woff/Aeroport_Mono.woff";
import SuisseIntl_Light from "./woff/SuisseIntl_Light.woff";
import Switzer_Light from "./woff/Switzer_Light.woff";
import MadeynSans_Light from "./woff/MadeynSans-Light.woff";
import Heebo_Thin from "./woff/Heebo-Thin.woff";








const GlobalStyle = createGlobalStyle`


*,
  *:before,
  *:after {
     // box-sizing: border-box;
     margin: 0;
     padding: 0;
  }


  

@media (max-width: 800px) {
  html, body {
  overflow-x: hidden;
  }
}
@media screen and (min-width: 768px) {
  html, body {
    overflow-x: hidden;
  }
}


  html, body {

    

    width: 100vw;
  margin: 0; 
  padding: 0;
      //font-family: Ubuntu, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
     //overflow: hidden;
      text-rendering: optimizeLegibility;
  }
  
  
    
  
    @font-face {
        font-family: 'Aeroport';
        src: local('Aeroport'), local('Aeroport'),
        url(${Aeroport}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Aeroport_Light';
        src: local('Aeroport_Light'), local('Aeroport_Light'),
        url(${Aeroport_Light}) format('woff');
        font-weight: 100;
        font-style: normal;
    }
    @font-face {
        font-family: 'Aeroport_Bold';
        src: local('Aeroport_Bold'), local('Aeroport_Bold'),
        url(${Aeroport_Bold}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Aeroport_Medium';
        src: local('Aeroport_Medium'), local('Aeroport_Medium'),
        url(${Aeroport_Medium}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Aeroport_Mono';
        src: local('Aeroport_Mono'), local('Aeroport_Mono'),
        url(${Aeroport_Mono}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
`;

export { GlobalStyle};
