
import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import styled from 'styled-components';

const VideoContainer = styled.div`
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    position: relative;
    z-index: 2;
    height:auto;
    align-self: center;
    justify-self: center;
    object-fit: cover;
    margin-left: 0px;
    @media screen and (min-width:0px) and (max-width: 768px) {
        width: calc(100vw );
    }
    @media screen and (min-width:769px)  {
        width: calc(100vw - 10vw - 10vw);
    }
`;

const VideoCont = styled.div`
background-color: red;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  overflow: hidden;
  background-size:cover;
  object-fit: cover;  
`;


const Video = (props) => {
  const videoNode = useRef(null);
  const [player, setPlayer] = useState(null);
  useEffect(() => {
   // console.log("state:" + props.changeState)
    if (videoNode.current) {
      const _player = videojs(videoNode.current, props);
      setPlayer(_player);
      _player.ready(function () {

        _player.src({ src: props.src, type: 'application/x-mpegURL' }); // el source aca viene por el props.src
      });
      return () => {
        if (player !== null) {
          player.dispose();
        }
      };
    }

    videojs('_player', { autoplay: false });
  }, []);

  return (
    <>
      <video
        ref={videoNode}
        id={props.referencia}
        className="video-js "
      />
    </>
  );
};



const FuncVideo = ({ src, referencia }) => {
  const play = {
    aspectRatio: '16:9',
    fill: false,
    fluid: false,
    autoplay: true,
    controls: true,
    responsive: false,
    muted: true,
    loop: true,
    preload: "metadata"
  };


  return (
    <>     
        <VideoCont >
          <VideoContainer>
            <Video {...play} src={src} referencia={referencia} />
          </VideoContainer>
        </VideoCont>
     </>
  );
}


export { FuncVideo };


