import React from 'react';
import styled from 'styled-components';
import { Widget } from '@typeform/embed-react'



const FormCont = styled.div`


   width:auto;
    height:auto;

   cursor: default;
`;




function Form() {

  
return (<>

    <Widget id="kbAlu7sM" style={{ width: '100%'  ,height:'100vh'}} className="my-form" />


    </>);
}

export {Form};


