import { createSlice } from '@reduxjs/toolkit'

export const DevelopersEstadoSlice = createSlice({
  

  name: 'DevelopersEstado',
  initialState: {
    value: false,
  },
  reducers: {
    ActivadoFiltroDevelopers: (state) => {
      state.value = true
    },
    DesactivadoFiltroDevelopers: (state) => {
      state.value = false 
    },
  },



})


export const { ActivadoFiltroDevelopers, DesactivadoFiltroDevelopers } = DevelopersEstadoSlice.actions

export default DevelopersEstadoSlice.reducer

