import React from "react";

import styled from "styled-components";
import { HLineMinMax } from "../../components/HLine.js";

import { AeroportFontCont } from "../../fonts/AeroportFont_Cont.js";
import { BTNBlackWhite } from "../../components/BTNBlackWh.js";
import { Arrow } from "../../components/Arrow.js";
import { useState,  useRef ,useEffect } from "react";
import gsap from "gsap";
import useMediaQuery from '../../hooks/MediaQueryhook';
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { InPageHome, NotInPageHome } from '../../redux/InPage'
import { ActivadoFiltroArchitects, DesactivadoFiltroArchitects } from '../../redux/Filter_Architects'
import { ActivadoFiltroDevelopers, DesactivadoFiltroDevelopers } from '../../redux/Filter_Developers'
import { ImageWebp } from "../../components/ImageWebp.js"

import image_1a from "../../img/web/FooterBig.jpg";
import image_1b from "../../img/web/FooterBig.webp";
import image_1d from "../../img/web/FooterBig.jpg";



import SpyGA from "../../Observer.js";

const DivFirst = styled.div`


cursor: default;

  //background-color: aliceblue;
  opacity: 1;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  //grid-template-rows: 10fr 0.25 1fr 0.05fr 0.01fr;
  top: 0px;
  left: 0px;
  height: 100%;
  background-color: #eeeeee;
  margin: 0;
  @media screen and (min-width: 0px) and (max-width: 480px) {
    grid-template-rows: 1fr;
    height: auto;
    width: 100vw;
  }
  /*div{
    background-color: #ef4125;
    //color: white;
  }*/
`;

const ContVid = styled.div`
  //background-color: azure;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 4;
  margin: 0;
  overflow: hidden;
  padding: 0;
  opacity: 1;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
`;

const ContLetsTalk = styled.div`

  padding: 2vw;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  background-color: #eeeeee;
  justify-self: start;
  align-self: center;
  margin-left: 5vw;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: 0px) and (max-width: 480px) {
    grid-template-rows: repeat(3, 1fr);
    //background-color: red;
    margin-top: 30vw;
    margin-left: 0;
    margin-bottom: 5vw;
    padding: 0;
    height: 100vw;
    width: 100vw;
  }
  @media screen and (min-width: 481px) and (max-width: 705px) {
    
    width: 37vw;
    height: 40vw;
  }
  @media screen and (min-width: 706px) and (max-width: 1279px) {
    width: 30vw;
    height: 35vw;
  }
  @media screen and (min-width: 1280px) {
    width: 25vw;
    height: 35vw;
  }
`;


const ContTxtSup = styled.div`
  //background-color: rebeccapurple;
  justify-self: start;
  align-self: start;
  display: inline-block;
  line-height: 0.6;
  
  @media screen and (min-width: 0px) and (max-width: 480px) {
    line-height: 1;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    justify-self: start;
    align-self: end;
    width:94vw;
    margin-left: 1rem;
    margin-bottom: 1.5rem;
  }

a {   //quita el espacio izq de la primera letra
    margin-left: -0.08em;
  }
`;

const ContTxtInf = styled.div`
 // background-color: darkslategrey;
 
  align-items: end;
  justify-items: start;
  align-content: end;
  width: 100%;
  height: 100%;
  line-height: 0.8;
  display: grid;
  @media screen and (min-width: 0px) and (max-width: 480px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    margin-left: 1rem;
    width:calc(100vw - 2rem);
   // margin-left: 3vw;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    line-height: 0.6;
  }
  @media screen and (min-width: 769px) and (max-width: 1279px) {
    line-height: 0.8;
  }
  @media screen and (min-width: 1280px) {
    line-height: 1;
  }
`;

//-----------------------------------------------------

const ContFooterContact = styled.div`
  //background-color: blue;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  justify-self: start;
  align-self: start;
  width: 100vw;
  height: auto;
  z-index: 10;
  padding: 0;
  display: grid;
  margin-left: 5vw;
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
  grid-template-columns: 0.9fr 0.9fr 2fr 1fr 1fr 1fr 0.6fr;
  grid-template-rows: repeat(1, 1fr);

  @media screen and (min-width: 0px) and (max-width: 480px) {
    display: none;
  }
`;


const DivFootSub = styled.div`
  //background-color: gold;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ContEnd = styled.div`
//cursor: default;

a{
  text-decoration:none;
  color:black;
}

/*
&:hover{
      cursor: pointer;
      color: #ef4125;
  
  }*/

/*
li:hover{
      color: #ef4125;
      cursor: pointer;
  }
  */
  //opacity: 0.5;
  z-index: 10;
  background-color: #eeeeee;
  width: 100vw;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  align-self: end;
  display: grid;

  //height: 150px;
`;



const FlechaDiv = styled.div`
 // background-color: red;
  opacity: 0.01;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
   
`;
const ContSocial = styled.div`
display:grid;
grid-template-columns: 1fr 1fr;
grid-template-rows: repeat(1, 1fr);

`;
const ContTxtSocial = styled.div`
  //background-color: blue;
  //opacity: 0.01;
 

  a:hover{
      cursor: pointer;
      color: #ef4125;
  
  }
  &:hover{
    color: #ef4125;
    cursor: pointer;
  }
  li:hover{
      color: black;
  
  }
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  padding: 0;
  
   
`;

const Imagen = styled.div`
width:     100%; //110%; //aca
height:     auto ; //aca
object-fit: cover;// ${props => props.iObjetFit}; //aca
overflow: hidden;
`;


const ContainerButton = styled.div`
padding-top: 2vw;
padding-bottom: 2vw;
@media screen and (min-width: 0px) and (max-width: 480px) {
  padding-top: 1rem;
padding-bottom: 1rem;
  }
`;


const ContenedorParrafo = styled.div`
padding-top: 2vw;
@media screen and (min-width: 0px) and (max-width: 480px) {
  padding-top: 1rem;
  
  }
`;

const Footer = () => {

  const flechaRef1 = useRef(null);
  const flechaRef2 = useRef(null);
  //const flechaRef3 = useRef(null);
  //const flechaRef4 = useRef(null);
  const flechaRef5 = useRef(null);

  const TxTRef1 = useRef(null);
  const TxTRef2 = useRef(null);
  //const TxTRef3 = useRef(null);
  //const TxTRef4 = useRef(null);
  const TxTRef5 = useRef(null);

  const isMediaQueryMax768px = useMediaQuery('(max-width: 768px)');
  const isMediaQueryMax1279ppx = useMediaQuery('(min-width: 769px) and (max-width: 1279px)');
  const isMediaQueryMin1280ppx = useMediaQuery('(min-width: 1280px)');



  

  const FlechaOn = (flecha, txt) => {
    gsap.to(flecha.current, { opacity: 1, duration: 0.5 })

    isMediaQueryMax768px ?
      gsap.to(txt.current, { translateX: 8, duration: 0.5 }) : "";
    isMediaQueryMax1279ppx ?
      gsap.to(txt.current, { translateX: 15, duration: 0.5 }) : "";
    isMediaQueryMin1280ppx ?
      gsap.to(txt.current, { translateX: 18, duration: 0.5 }) : "";

  }

  const FlechaOff = (flecha, txt) => {
    gsap.to(flecha.current, { opacity: 0, duration: 0.5 })
    gsap.to(txt.current, { translateX: 0, duration: 0.5 })
  }

  const dispatch = useDispatch()

 

 
 const [mouseOver, setMouseOVer] = useState(false);
 useEffect(() => {
   mouseOver ? SpyGA("GoOver", "FOOTER"):""
 },[]);


  return (
    <>

      <DivFirst id='Footer' onMouseEnter={() => setMouseOVer(true)}>
        <ContVid>
          <Imagen>
            <ImageWebp webPBigSrc={image_1a} imgAltBigSrc={image_1b}  imgAltSmallSrc={image_1d} />
          </Imagen>
        </ContVid>

        <ContLetsTalk>
          <ContTxtSup>
            <AeroportFontCont


              sizePhone="60px"
              sizeSmall="25px"
              sizeMedium="45px"
              sizeBig="65px"

            >
              <p>Let's Talk</p>
            </AeroportFontCont>
          </ContTxtSup>

          <ContTxtInf>

            <HLineMinMax
              startPx="0px"
              endPx="3000px"
              width="100%"
              left="0px"

              mtop="1px"
            />

            <ContenedorParrafo>
              <AeroportFontCont
                lHeightPhone="1.2"
                lHeightSmall="1.2"
                lHeightMedium="1.2"
                lHeightBig="1.2"
                sizePhone="12px"
                sizeSmall="9px"
                sizeMedium="12px"
                sizeBig="18px"

              >
                <p>
                  We have already helped to land over 500 projects, in 40
                  different countries, for almost every typology that is out
                  there. So instead of spending countless hours trying to figure
                  it out give us a call. <br />
                  <br />
                  The chances are we have a solution to help you get where you
                  want to go a hell of a lot faster than if you were to do it
                  alone.
                </p>

              </AeroportFontCont>
            </ContenedorParrafo>
            <ContainerButton onClick={() => SpyGA("Clicked", "BT_LETSTALK_HOME_FOOTER")}>
              <Link to="/Form">
                <BTNBlackWhite txt="Let's Talk" />
              </Link>
            </ContainerButton>
            <HLineMinMax
              startPx="0px"
              endPx="3000px"
              width="100%"
              left="0px"
              mBottom="1px"
              mtop="2vw"
            />
          </ContTxtInf>
        </ContLetsTalk>


      </DivFirst>

      <ContEnd>
        <ContFooterContact>
          <DivFootSub>
            <AeroportFontCont
              sizeDefault="12px"
              sizePhone="9px"
              sizeSmall="7px"
              sizeMedium="10px"
              sizeBig="12px"
              pBottom="10px"
            >
              <p>MAPSITE</p>
            </AeroportFontCont>
            <AeroportFontCont
              sizeDefault="12px"
              sizePhone="12px"
              sizeSmall="9px"
              sizeMedium="12px"
              sizeBig="18px"
              lHeightDefault="1.2">

              <ContTxtSocial>
                <Link to="/" onClick={() => dispatch(InPageHome()) & SpyGA("Clicked", "BT_FOOTER_HOME") & 
                  setTimeout(() => { document.getElementById('Landing').scrollIntoView({ behavior: "smooth" }) }, 1000)}>
                  Home.
                </Link>
              </ContTxtSocial>

              <ContTxtSocial>
                <Link to="/" onClick={() => dispatch(InPageHome()) & SpyGA("Clicked", "BT_FOOTER_CONTACT") & 
                  setTimeout(() => { document.getElementById('Footer').scrollIntoView({ behavior: "smooth" }) }, 1000)}>
                  Contact.
                </Link>
              </ContTxtSocial>
              <ContTxtSocial>
                <Link to="/" onClick={() => dispatch(InPageHome()) & SpyGA("Clicked", "BT_FOOTER_CLIENT") & 
                  setTimeout(() => { document.getElementById('Worked').scrollIntoView({ behavior: "smooth" }) }, 1000)}>
                  Clients.
                </Link>
              </ContTxtSocial>
              <ContTxtSocial>
                <Link to="/Work" onClick={() => dispatch(NotInPageHome()) & SpyGA("Clicked", "BT_FOOTER_WORK") &  
                  window.removeEventListener('scroll', onscroll)} >
                  Work.
                </Link>
              </ContTxtSocial>

            </AeroportFontCont>
          </DivFootSub>

          <DivFootSub>
            <AeroportFontCont
              sizeDefault="12px"
              sizePhone="9px"
              sizeSmall="7px"
              sizeMedium="10px"
              sizeBig="12px"
              pBottom="10px"
            >
              <p>CLIENTS</p>
            </AeroportFontCont>
            <AeroportFontCont
              sizeDefault="12px"
              sizePhone="12px"
              sizeSmall="9px"
              sizeMedium="12px"
              sizeBig="18px"
              lHeightDefault="1.2"
            >

              <Link to="/LowMenuFirst" >
                <ContTxtSocial onClick={() =>
                  dispatch(NotInPageHome()) &
                  window.removeEventListener('scroll', onscroll) &
                  dispatch(DesactivadoFiltroArchitects()) &
                  dispatch(ActivadoFiltroDevelopers()) & SpyGA("Clicked", "BT_FOOTER_ARCHITECTS")   
                 
                }>
                  Architects.
                </ContTxtSocial>
              </Link>

             {/* <Link to="/LowMenuSecond" >
                <ContTxtSocial onClick={() => //console.log("clickeado") &
                  dispatch(NotInPageHome()) &
                  window.removeEventListener('scroll', onscroll) &
                  dispatch(ActivadoFiltroArchitects()) &
                  dispatch(DesactivadoFiltroDevelopers())}>

                  Developers.

                </ContTxtSocial>
              </Link>*/}

              <Link to="/LowMenuThird" >
                <ContTxtSocial onClick={() => //console.log("clickeado") &
                  dispatch(NotInPageHome()) &
                  window.removeEventListener('scroll', onscroll) &
                  dispatch(ActivadoFiltroArchitects()) &
                  dispatch(DesactivadoFiltroDevelopers()) & SpyGA("Clicked", "BT_FOOTER_URBANISTS") 
                  }>

                  Urbanist.

                </ContTxtSocial>
              </Link>



            </AeroportFontCont>
          </DivFootSub>

          <DivFootSub>
            <AeroportFontCont
              sizeDefault="12px"
              sizePhone="9px"
              sizeSmall="7px"
              sizeMedium="10px"
              sizeBig="12px"
              pBottom="10px"
            >
              <p>CONTACT US</p>
            </AeroportFontCont>
            <AeroportFontCont
              sizeDefault="12px"
              sizePhone="12px"
              sizeSmall="9px"
              sizeMedium="12px"
              sizeBig="18px"ppp
              lHeightDefault="1.2"
            >
              <ContTxtSocial  onClick={() => SpyGA("Clicked", "BT_FOOTER_EMAIL")}>
                <a href="mailto:hello@estudionod.com">E: hello@estudionod.com</a>
              </ContTxtSocial>
              <ContTxtSocial onClick={() => SpyGA("Clicked", "BT_FOOTER_CELPHONE")}>
                <a href="tel:+1 302 538 9684">C: +1 302 538 9684</a>
              </ContTxtSocial>
              <ContTxtSocial>
                <p>Schedule a call.</p>
              </ContTxtSocial>
            </AeroportFontCont>
          </DivFootSub>
          <div />
          <div />

          <DivFootSub>
            <AeroportFontCont
              sizeDefault="12px"
              sizePhone="9px"
              sizeSmall="7px"
              sizeMedium="10px"
              sizeBig="12px"
              pBottom="10px"
            >
              <p style={{ width: "100px" }}>JOIN US HERE</p>
            </AeroportFontCont>
            <AeroportFontCont
              sizeDefault="12px"
              sizePhone="12px"
              sizeSmall="9px"
              sizeMedium="12px"
              sizeBig="18px"
              lHeightDefault="1.2"
            >

              <ContSocial onMouseEnter={() => FlechaOn(flechaRef1, TxTRef1)} onMouseLeave={() => FlechaOff(flechaRef1, TxTRef1)}>
                <FlechaDiv ref={flechaRef1}>
                  <Arrow
                    scaleNormal="10"
                    scalePhone="10"
                    scaleSmall="6"
                    scaleMiddle="10"
                    scaleBig="10"
                  />
                </FlechaDiv>
                <ContTxtSocial ref={TxTRef1} onClick={() => SpyGA("Clicked", "BT_FOOTER_LINKEDIN")} >
                  <a href="https://www.linkedin.com/company/nod3d/">LinkedIn.</a>
                </ContTxtSocial>
              </ContSocial>

              <ContSocial onMouseEnter={() => FlechaOn(flechaRef2, TxTRef2)} onMouseLeave={() => FlechaOff(flechaRef2, TxTRef2)}>
                <FlechaDiv ref={flechaRef2}>
                  <Arrow
                    scaleNormal="10"
                    scalePhone="10"
                    scaleSmall="6"
                    scaleMiddle="10"
                    scaleBig="10"
                  />
                </FlechaDiv >
                <ContTxtSocial ref={TxTRef2} onClick={() => SpyGA("Clicked", "BT_FOOTER_INSTAGRAM")}>
                  <a href="https://www.instagram.com/estudionod/">Instagram.</a>
                </ContTxtSocial>
              </ContSocial>





              <ContSocial onMouseEnter={() => FlechaOn(flechaRef5, TxTRef5)} onMouseLeave={() => FlechaOff(flechaRef5, TxTRef5)}>
                <FlechaDiv ref={flechaRef5}>
                  <Arrow
                    scaleNormal="10"
                    scalePhone="10"
                    scaleSmall="6"
                    scaleMiddle="10"
                    scaleBig="10"
                  />
                </FlechaDiv>
                <ContTxtSocial ref={TxTRef5} onClick={() => SpyGA("Clicked", "BT_FOOTER_BEHANCE")} >
                  <a href="https://www.behance.net/estudionod">Behance.</a>
                </ContTxtSocial>
              </ContSocial>
            </AeroportFontCont>
          </DivFootSub>
        </ContFooterContact>

        <HLineMinMax
          style={{
            gridColumnStart: "1",
            gridColumnEnd: "2",
            gridRowStart: "2",
            gridRowEnd: "3",
          }}
          startPx="460px"
          endPx="3000px"
          width="100%"
          left="0px"
          mBottom="0.1vh"
          mtop="0.1vh"
        />

      </ContEnd>
    </>
  );
};

export default Footer;

