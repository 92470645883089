import React from 'react';
import styled from 'styled-components';
import { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import useMediaQuery from './hooks/MediaQueryhook';
import { useDispatch } from "react-redux";
import { yesRuned } from './redux/LoaderRun'

const Full = styled.div`
 // opacity: 0.5;
    display: block;
  
`;
//#ef4125;

const LoaderDiv = styled.div`
    background-color: #eeeeee;
    width:100vw;
    height:130vh;
    z-index: 105;
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-items: start;

    @media screen and (min-width:0px) and (max-width: 480px) {
      font-size: 35px;
    }
    @media screen and (min-width:481px) and (max-width: 799px) {
      font-size: 50px;
      align-items: center;
      justify-items: center;
      justify-content: center;
    }
    @media screen and (min-width:800px) and (max-width: 1023px) {
      font-size: 35px; 
    }
    @media screen and (min-width:1024px) and (max-width: 1479px) {
      font-size: calc(38px + 8 * ((100vw - 320px) / 960));
    }
    @media screen and (min-width:1470px){
      font-size: calc(55px + 8 * ((100vw - 320px) / 960));
    }
    
    
    

`;



const ContTextExt = styled.div`

    background-color: #eeeeee;
   // background-color: aqua;
    width:100vw;
    height:calc(calc(100vh / 2)  );
    z-index: 105;
    cursor: default;
    display: flex;
    flex-direction:row;
    justify-items: flex-start;
    align-items: center;
    @media screen and (min-width:0px) and (max-width: 480px) {
      height:calc(calc(100vh / 2) - 100px );
    }
    @media screen and (min-width:481px) and (max-width: 768px) {
      
    }
    @media screen and (min-width:769px) and (max-width: 1279px) {
          
    }
    @media screen and (min-width:1280px){
          
    }
    
`;

const ContText = styled.div`
    width:100vw;
    height:100px;
    z-index: 104;
    cursor: default;
    display: flex;
    flex-direction:row;
    justify-items: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    @media screen and (min-width:0px) and (max-width: 480px) {
      flex-direction:column; 
      align-items: center;
    }
    @media screen and (min-width:481px) and (max-width: 799px) {
      align-items: center;
      flex-direction:column; 
    }
    @media screen and (min-width:800px) and (max-width: 1279px) {
          
    }
    @media screen and (min-width:1280px){
          
    }
    
 
`;

const ContIzq = styled.div`
//background-color: red;
background-color: #eeeeee;
    opacity: 0;
    //width:fit-content;
    height: auto;
    display:grid;
    align-items: center;
    justify-items: right;
    margin-left: 5vw;
    color:black;

    @media screen and (min-width:0px) and (max-width: 480px) {
      
      display:none;
      align-items: center;
      justify-items: center;
      width:100vw;
      height:100px;
      margin-left: 0;
      justify-content: center;
      align-content: center;
      z-index: 110;
      
    }
    @media screen and (min-width:481px) and (max-width: 799px) {
      display:none;
      align-items: center;
      justify-items: center;
      margin-left: 0;
      padding-left: 0;
      width:100vw;
      height:100px;
      justify-content: center;
      align-content: center;
      z-index: 110;
    }
   
    
`;
const ContCentr = styled.div`

    opacity: 0;
    background-color: #ef4125;
    width:1px;
    height: 95%;
    margin-left:5vw;
    margin-right:5vw;
    @media screen and (min-width:0px) and (max-width: 480px) {
      height:100%;
      width:100vw;
      background-color: #eeeeee;
    }
    @media screen and (min-width:481px) and (max-width: 799px) {
      width:100vw;
      height:100%;
      width:1px;
      background-color: #eeeeee;
    }
  
`;
const ContDer = styled.div`
//background-color: blue;
    width:fit-content;
    height: auto;
    position: relative;
    color:black;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    top: 100px;
    flex-wrap: nowrap;
    display: grid;
    @media screen and (min-width:0px) and (max-width: 480px) {
      display: grid;
      align-items: center;
    justify-items: center;
      height:auto;
    width:100vw;
    margin-left: 0;
    }
    @media screen and (min-width:481px) and (max-width: 799px) {
      display: grid;
      align-items: center;
    justify-items: center;
    height:auto;
    width:100vw;
    margin-left: 0;
    }

`;

const ContDerGrid = styled.div`
    position: relative;
    display:grid; 
    align-items: start;
    justify-items: start;
    grid-template-columns: 1fr; 
    grid-template-rows:1fr ; 
  
`;






function Loader() {
  const [loader, SetLoader] = useState(true);
  const ContainerloaderRef1 = useRef(null);
  const lista1 = useRef(null);
  const lista2 = useRef(null);
  const lista3 = useRef(null);
  const lista4 = useRef(null);
  const lista5 = useRef(null);
  const lista6 = useRef(null);
  const lista7 = useRef(null);
  const lista8 = useRef(null);
  const lista9 = useRef(null);
  const lista10 = useRef(null);
  const lista11 = useRef(null);
  const lista12 = useRef(null);

  const weWorkedFor = useRef(null);
  const line = useRef(null);
  const contDerecho = useRef(null);

  function disableScroll() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () { window.scrollTo(x, y) };
  }

  function disableScroll() {
    window.scrollTo(0, 0);
  }

  const isMediaQuery769px = useMediaQuery('(min-width: 769px)');

  const dispatch = useDispatch();

  useEffect(() => {
    if (loader) {
      gsap.to(line.current, { duration: 2, opacity: 1 })
      gsap.to(weWorkedFor.current, { duration: 2, opacity: 1 })

      window.addEventListener('scroll', disableScroll);

      let tl = gsap.timeline();
      let vel = 0.1
      let velDelay = 0.2

      let velB = 1;

      (isMediaQuery769px) ? velB = 1:velB =0;

      setTimeout(() => {

        tl.to(lista1.current,
          { duration: vel, translateY: -100 })
          .to(lista1.current,
            { duration: vel, translateY: -200, delay: velDelay })
          .to(lista2.current,
            { duration: vel, translateY: -100 })
          .to(lista2.current,
            { duration: vel, translateY: -200, delay: velDelay })
          .to(lista3.current,
            { duration: vel, translateY: -100 })
          .to(lista3.current,
            { duration: vel, translateY: -200, delay: velDelay })
          .to(lista4.current,
            { duration: vel, translateY: -100 })
          .to(lista4.current,
            { duration: vel, translateY: -200, delay: velDelay })
          .to(lista5.current,
            { duration: vel, translateY: -100 })
          .to(lista5.current,
            { duration: vel, translateY: -200, delay: velDelay })
          .to(lista6.current,
            { duration: vel, translateY: -100 })
          .to(lista6.current,
            { duration: vel, translateY: -200, delay: velDelay })
          .to(lista7.current,
            { duration: vel, translateY: -100 })
          .to(lista7.current,
            { duration: vel, translateY: -200, delay: velDelay })
          .to(lista8.current,
            { duration: vel, translateY: -100 })
          .to(lista8.current,
            { duration: vel, translateY: -200, delay: velDelay })
          .to(lista9.current,
            { duration: vel, translateY: -100 })
          .to(lista9.current,
            { duration: vel, translateY: -200, delay: velDelay })
            .to(lista10.current,
              { duration: vel, translateY: -100 })
            .to(lista10.current,
              { duration: vel, translateY: -200, delay: velDelay })
              .to(lista11.current,
                { duration: vel, translateY: -100 })
              .to(lista11.current,
                { duration: vel, translateY: -200, delay: velDelay })
                .to(lista12.current,
                  { duration: vel, translateY: -100 })
                .to(lista12.current,
                  { duration: vel, translateY: -200, delay: velDelay })
           /* .to(lista10.current,
              { duration: vel, translateY: -100 })
            .to(lista10.current,
              { duration: vel, translateY: -200, delay: velDelay })*/
            
              
          .to(weWorkedFor.current, { height: "0vh", duration: velB, opacity: 0 })
          .to(line.current, { height: "0vh", duration: velB, opacity: 0 })
          .to(ContainerloaderRef1.current, { height: "0vh", duration: 2, opacity: 0 })
      }, 1500);








      setTimeout(() => {

        // weWorkedFor
        // gsap.to(ContainerloaderRef1.current, { height: "0vh", duration: 1, opacity: 0})

        window.removeEventListener('scroll', disableScroll);
        SetLoader(false);
        dispatch(yesRuned()) // estp es para que no vuelva a cargar el loader
      }, "8000");
    }

    

  }, [loader]);

  return (
    <>
      <Full>
        <LoaderDiv ref={ContainerloaderRef1} >
          <ContTextExt />
          <ContText>
            <ContIzq ref={weWorkedFor}>
              <a style={{ fontFamily: ' Arial' }}>We Worked For</a>

            </ContIzq>

            <ContCentr ref={line} />

            <ContDerGrid ref={contDerecho}>
              <ContDer ref={lista1}><a style={{ fontFamily: 'Arial' }}>Foster.</a></ContDer>
              <ContDer ref={lista2}><a style={{ fontFamily: 'Arial' }}>Google.</a></ContDer>
              <ContDer ref={lista3}><a style={{ fontFamily: 'Arial' }}>Arkitema.</a></ContDer>
              <ContDer ref={lista4}><a style={{ fontFamily: 'Arial' }}>Heatherwick.</a></ContDer>
              <ContDer ref={lista5}><a style={{ fontFamily: 'Arial' }}>Arup.</a></ContDer>
              <ContDer ref={lista6}><a style={{ fontFamily: 'Arial' }}>KPF.</a></ContDer>
              <ContDer ref={lista7}><a style={{ fontFamily: 'Arial' }}>WSP.</a></ContDer>
              <ContDer ref={lista8}><a style={{ fontFamily: 'Arial' }}>James Corner FO.</a></ContDer>
              <ContDer ref={lista9}><a style={{ fontFamily: 'Arial' }}>Acciona.</a></ContDer>
              <ContDer ref={lista10}><a style={{ fontFamily: 'Arial' }}>Ortiz León.</a></ContDer>
              <ContDer ref={lista11}><a style={{ fontFamily: 'Arial' }}>IDOM.</a></ContDer>
              <ContDer ref={lista12}><a style={{ fontFamily: 'Arial' }}>HCMA.</a></ContDer>
                    </ContDerGrid>

          </ContText>
          <ContTextExt />
        </LoaderDiv>

    
      </Full>
    </>
  );
}

export default Loader;

