import AGI_1_Big from "../../img/gallery/BIG/AGI_1_Big.jpg";
import AGI_2_Big from "../../img/gallery/BIG/AGI_2_Big.jpg";
import Arkitema_1_Big from "../../img/gallery/BIG/Arkitema_1_Big.jpg";
//import Arkitema_5_Big from "../../img/gallery/BIG/Arkitema_5_Big.jpg";
//import Atica_1_Big from "../../img/gallery/BIG/Atica_1_Big.jpg";
import Berilo_13_Big from "../../img/gallery/BIG/Berilo_13_Big.jpg";
//import Berilo_17_Big from "../../img/gallery/BIG/Berilo_17_Big.jpg";
//import Berilo_4_Big from "../../img/gallery/BIG/Berilo_4_Big.jpg";
//import Berilo_7_Big from "../../img/gallery/BIG/Berilo_7_Big.jpg";
//import BTNY_1_Big from "../../img/gallery/BIG/BTNY_1_Big.jpg";
import Burj_2_Big from "../../img/gallery/BIG/Burj_2_Big.jpg";
import Burj_2_small from "../../img/gallery/small/Burj_2_small.jpg";
import IDOM_1_Big from "../../img/gallery/BIG/IDOM_1_Big.jpg";
import IDOM_1_Small from "../../img/gallery/SMALL/IDOM_1_Small.jpg";

import HW_TheCore_A_Big from "../../img/gallery/BIG/HW_TheCore_A_Big.jpg";
import HW_TheCore_A_Small from "../../img/gallery/Small/HW_TheCore_A_Small.jpg";
import C69_1_Big from "../../img/gallery/BIG/C69_1_Big.jpg";
//import C69_2_Big from "../../img/gallery/BIG/C69_2_Big.jpg";
//import Cunef_1_Big from "../../img/gallery/BIG/Cunef_1_Big.jpg";
import Dipa_1_Big from "../../img/gallery/BIG/Dipa_1_Big.jpg";
import FO_MN_4_Big from "../../img/gallery/BIG/FO_MN_4_Big.jpg";
//import FO_MN_7_Big from "../../img/gallery/BIG/FO_MN_7_Big.jpg";
import Glamping_1_Big from "../../img/gallery/BIG/Glamping_1_Big.jpg";
//import GMP_1_Big from "../../img/gallery/BIG/GMP_1_Big.jpg";
import GMP_3_Big from "../../img/gallery/BIG/GMP_3_Big.jpg";
//import GMP_6_Big from "../../img/gallery/BIG/GMP_6_Big.jpg";
//import Mars_1_Big from "../../img/gallery/BIG/Mars_1_Big.jpg";
import Miami_1_Big from "../../img/gallery/BIG/Miami_1_Big.jpg";
import Numa_1_Big from "../../img/gallery/BIG/Numa_1_Big.jpg";
import Numa_3_Big from "../../img/gallery/BIG/Numa_3_Big.jpg";
import Numa_9_Big from "../../img/gallery/BIG/Numa_9_Big.jpg";
//import OL_1_Big from "../../img/gallery/BIG/OL_1_Big.jpg";
//import OL_2_Big from "../../img/gallery/BIG/OL_2_Big.jpg";
//import OL_3_Big from "../../img/gallery/BIG/OL_3_Big.jpg";
import OP_1_Big from "../../img/gallery/BIG/OP_1_Big.jpg";
import Oria_1_Big from "../../img/gallery/BIG/Oria_1_Big.jpg";
import Oria_2_Big from "../../img/gallery/BIG/Oria_2_Big.jpg";
import Oria_3_Big from "../../img/gallery/BIG/Oria_3_Big.jpg";
import PA_1_Big from "../../img/gallery/BIG/PA_1_Big.jpg";
import PA_3_Big from "../../img/gallery/BIG/PA_3_Big.jpg";
import PA_1_Small from "../../img/gallery/Small/PA_1_Small.jpg";
// import PA_3_Small from "../../img/gallery/Small/PA_3_Small.jpg";
//import SantaPola_1_Big from "../../img/gallery/BIG/SantaPola_1_Big.jpg";
import SDSU_1_Big from "../../img/gallery/BIG/SDSU_1_Big.jpg";
import SDSU_2_Big from "../../img/gallery/BIG/SDSU_2_Big.jpg";
import Sigator_1_Big from "../../img/gallery/BIG/Sigator_1_Big.jpg";
import Stills_2_Big from "../../img/gallery/BIG/Stills_2_Big.jpg";
import Rawbank_Big from "../../img/gallery/big/Rawbank_Big.jpg";
import RawbankExterior_Big from "../../img/gallery/big/RawbankExterior_Big.jpg";
import AlexPoulsenArk_MuseumInterior_Courtyardv03_Big from "../../img/gallery/big/AlexPoulsenArk_MuseumInterior_Courtyardv03_Big.jpg";
import ArupB_Big from "../../img/gallery/big/ArupB_Big.jpg";
import ArupA_Big from "../../img/gallery/big/ArupA_Big.jpg";
import ArupC_Big from "../../img/gallery/big/ArupC_Big.jpg";
import ArupA_Small from "../../img/gallery/small/ArupA_small.jpg";
import ArupC_Small from "../../img/gallery/small/ArupC_small.jpg";
import ArupB_Small from "../../img/gallery/small/ArupB_small.jpg";

import ArupE02_Big from "../../img/gallery/big/ArupE02_Big.jpg";
import Arup_Bernabeu_Detail_02_Big from "../../img/gallery/big/Arup_Bernabeu_Detail_02_Big.jpg";
//import Arup_BurjKhalifa_Pasarela_Big from "../../img/gallery/big/Arup_BurjKhalifa_Pasarela_Big.jpg";
import Arup_Burj_semiaerea_Pasarellas_resto_MALL_p_Big from "../../img/gallery/big/Arup_Burj_semiaerea_Pasarellas-resto-MALL_p_Big.jpg";
import Atela_E01_Big from "../../img/gallery/big/Atela_E01_Big.jpg";
import Atela_SL11_E01_C_Big from "../../img/gallery/big/Atela_SL11_E01_C_Big.jpg";
import BertolinoARVO_C01_Big from "../../img/gallery/big/BertolinoARVO_C01_Big.jpg";
import Bod_SantaPola_Extra_E01_Big from "../../img/gallery/big/Bod_SantaPola_Extra_E01_Big.jpg";
import DL_AreiaBranca_Invernadero_Big from "../../img/gallery/big/DL_AreiaBranca_Invernadero_Big.jpg";
import Fo_MadridNorte_E02_Big from "../../img/gallery/big/Fo_MadridNorte_E02_Big.jpg";
import HW_BJGNMN_I02_4k_Big from "../../img/gallery/big/HW_BJGNMN_I02_4k_Big.jpg";
import HW_BJGNMN_I03_4K_Big from "../../img/gallery/big/HW_BJGNMN_I03_4K_Big.jpg";
import Metrovacesa_CLESA_Ext_VistaEste_sinpersonas_Big from "../../img/gallery/big/Metrovacesa_CLESA_Ext_VistaEste_sinpersonas_Big.jpg";
//import OL_JC22_C03_C_Big from "../../img/gallery/big/OL_JC22_C03_C_Big.jpg";
import OrtizLeon_MalagaAcciona_C09_Terrazas_B_Big from "../../img/gallery/big/OrtizLeon_MalagaAcciona_C09_Terrazas_B_Big.jpg";
//import Platform_PacHwy_Big from "../../img/gallery/big/Platform_PacHwy_Big.jpg";
import StudiomaacArena_E02_Big from "../../img/gallery/big/StudiomaacArena_E02_Big.jpg";
import StudioMacc_HotelOuidah_C01_V2_Big from "../../img/gallery/big/StudioMacc_HotelOuidah_C01_V2_Big.jpg";
import StudioMacc_HotelOuidah_C02_Big from "../../img/gallery/big/StudioMacc_HotelOuidah_C02_Big.jpg";
import StudioMacc_HotelOuidah_C05_V2_Big from "../../img/gallery/big/StudioMacc_HotelOuidah_C05_V2_Big.jpg";

//------------------- SMALL ---------------------------------

import AGI_1_Small from "../../img/gallery/SMALL/AGI_1_Small.jpg";
import AGI_2_Small from "../../img/gallery/SMALL/AGI_2_Small.jpg";
import Arkitema_1_Small from "../../img/gallery/SMALL/Arkitema_1_Small.jpg";
//import Arkitema_5_Small from "../../img/gallery/SMALL/Arkitema_5_Small.jpg";
//import Atica_1_Small from "../../img/gallery/SMALL/Atica_1_Small.jpg";
import Berilo_13_Small from "../../img/gallery/SMALL/Berilo_13_Small.jpg";
//import Berilo_17_Small from "../../img/gallery/SMALL/Berilo_17_Small.jpg";
//import Berilo_4_Small from "../../img/gallery/SMALL/Berilo_4_Small.jpg";
//import Berilo_7_Small from "../../img/gallery/SMALL/Berilo_7_Small.jpg";
//import BTNY_1_Small from "../../img/gallery/SMALL/BTNY_1_Small.jpg";
//import Burj_1_Small from "../../img/gallery/SMALL/Burj_1_Small.jpg";
import C69_1_Small from "../../img/gallery/SMALL/C69_1_Small.jpg";
//import C69_2_Small from "../../img/gallery/SMALL/C69_2_Small.jpg";
//import Cunef_1_Small from "../../img/gallery/SMALL/Cunef_1_Small.jpg";
import Dipa_1_Small from "../../img/gallery/SMALL/Dipa_1_Small.jpg";
import FO_MN_4_Small from "../../img/gallery/SMALL/FO_MN_4_Small.jpg";
//import FO_MN_7_Small from "../../img/gallery/SMALL/FO_MN_7_Small.jpg";
import Glamping_1_Small from "../../img/gallery/SMALL/Glamping_1_Small.jpg";
//import GMP_1_Small from "../../img/gallery/SMALL/GMP_1_Small.jpg";
import GMP_3_Small from "../../img/gallery/SMALL/GMP_3_Small.jpg";
//import GMP_6_Small from "../../img/gallery/SMALL/GMP_6_Small.jpg";
//import Mars_1_Small from "../../img/gallery/SMALL/Mars_1_Small.jpg";
import Miami_1_Small from "../../img/gallery/SMALL/Miami_1_Small.jpg";
import Numa_1_Small from "../../img/gallery/SMALL/Numa_1_Small.jpg";
import Numa_3_Small from "../../img/gallery/SMALL/Numa_3_Small.jpg";
import Numa_9_Small from "../../img/gallery/SMALL/Numa_9_Small.jpg";
//import OL_1_Small from "../../img/gallery/SMALL/OL_1_Small.jpg";
//import OL_2_Small from "../../img/gallery/SMALL/OL_2_Small.jpg";
//import OL_3_Small from "../../img/gallery/SMALL/OL_3_Small.jpg";
import OP_1_Small from "../../img/gallery/SMALL/OP_1_Small.jpg";
import Oria_1_Small from "../../img/gallery/SMALL/Oria_1_Small.jpg";
import Oria_2_Small from "../../img/gallery/SMALL/Oria_2_Small.jpg";
import Oria_3_Small from "../../img/gallery/SMALL/Oria_3_Small.jpg";
import PA_3_Small from "../../img/gallery/SMALL/PA_3_Small.jpg";
//import SantaPola_1_Small from "../../img/gallery/SMALL/SantaPola_1_Small.jpg";
import SDSU_1_Small from "../../img/gallery/SMALL/SDSU_1_Small.jpg";
import SDSU_2_Small from "../../img/gallery/SMALL/SDSU_2_Small.jpg";
import Sigator_1_Small from "../../img/gallery/SMALL/Sigator_1_Small.jpg";
import Stills_2_Small from "../../img/gallery/SMALL/Stills_2_Small.jpg";
import Rawbank_Small from "../../img/gallery/small/Rawbank_Small.jpg";
import RawbankExterior_Small from "../../img/gallery/small/RawbankExterior_Small.jpg";
import AlexPoulsenArk_MuseumInterior_Courtyardv03_Small from "../../img/gallery/small/AlexPoulsenArk_MuseumInterior_Courtyardv03_Small.jpg";

import ArupE02_Small from "../../img/gallery/small/ArupE02_Small.jpg";
import Arup_Bernabeu_Detail_02_Small from "../../img/gallery/small/Arup_Bernabeu_Detail_02_Small.jpg";
//import Arup_BurjKhalifa_Pasarela_Small from "../../img/gallery/small/Arup_BurjKhalifa_Pasarela_Small.jpg";
import Arup_Burj_semiaerea_Pasarellas_resto_MALL_p_Small from "../../img/gallery/small/Arup_Burj_semiaerea_Pasarellas-resto-MALL_p_Small.jpg";
import Atela_E01_Small from "../../img/gallery/small/Atela_E01_Small.jpg";
import Atela_SL11_E01_C_Small from "../../img/gallery/small/Atela_SL11_E01_C_Small.jpg";
import BertolinoARVO_C01_Small from "../../img/gallery/small/BertolinoARVO_C01_Small.jpg";
import Bod_SantaPola_Extra_E01_Small from "../../img/gallery/small/Bod_SantaPola_Extra_E01_Small.jpg";
import DL_AreiaBranca_Invernadero_Small from "../../img/gallery/small/DL_AreiaBranca_Invernadero_Small.jpg";
import Fo_MadridNorte_E02_Small from "../../img/gallery/small/Fo_MadridNorte_E02_Small.jpg";
import HW_BJGNMN_I02_4k_Small from "../../img/gallery/small/HW_BJGNMN_I02_4k_Small.jpg";
import HW_BJGNMN_I03_4K_Small from "../../img/gallery/small/HW_BJGNMN_I03_4K_Small.jpg";
import Metrovacesa_CLESA_Ext_VistaEste_sinpersonas_Small from "../../img/gallery/small/Metrovacesa_CLESA_Ext_VistaEste_sinpersonas_Small.jpg";
//import OL_JC22_C03_C_Small from "../../img/gallery/small/OL_JC22_C03_C_Small.jpg";
import OrtizLeon_MalagaAcciona_C09_Terrazas_B_Small from "../../img/gallery/small/OrtizLeon_MalagaAcciona_C09_Terrazas_B_Small.jpg";
//import Platform_PacHwy_Small from "../../img/gallery/small/Platform_PacHwy_Small.jpg";
import StudiomaacArena_E02_Small from "../../img/gallery/small/StudiomaacArena_E02_Small.jpg";
import StudioMacc_HotelOuidah_C01_V2_Small from "../../img/gallery/small/StudioMacc_HotelOuidah_C01_V2_Small.jpg";
import StudioMacc_HotelOuidah_C02_Small from "../../img/gallery/small/StudioMacc_HotelOuidah_C02_Small.jpg";
import StudioMacc_HotelOuidah_C05_V2_Small from "../../img/gallery/small/StudioMacc_HotelOuidah_C05_V2_Small.jpg";
import Andoya_1_Big from "../../img/gallery/BIG/Andoya_1_Big.jpg";
import Andoya_2_Big from "../../img/gallery/BIG/Andoya_2_Big.jpg";
import Andoya_3_Big from "../../img/gallery/BIG/Andoya_3_Big.jpg";
import Andoya_1_small from "../../img/gallery/small/Andoya_1_small.jpg";
import Andoya_2_small from "../../img/gallery/small/Andoya_2_small.jpg";
import Andoya_3_small from "../../img/gallery/small/Andoya_3_small.jpg";

const img = [
  {
    big: C69_1_Big,
    small: C69_1_Small,
    filter: "offices all",
    tipo: "image",
  },

  {
    big: Andoya_2_Big,
    small: Andoya_2_small,
    filter: "urbanism all",
    tipo: "image",
  },

  {
    big: Arkitema_1_Big,
    small: Arkitema_1_Small,
    filter: "residential offices all",
    tipo: "image",
  },
  {
    big: "https://vimeo.com/859900574",
    small: "https://vimeo.com/859900574",
    filter: "urbanism all",
    tipo: "video",
  },
  {
    big: FO_MN_4_Big,
    small: FO_MN_4_Small,
    filter: "urbanism all",
    tipo: "image",
  },
  {
    big: Fo_MadridNorte_E02_Big,
    small: Fo_MadridNorte_E02_Small,
    filter: "urbanism all",
    tipo: "image",
  },
  // {
  //   big: HW_BJGNMN_I03_4K_Big,
  //   small: HW_BJGNMN_I03_4K_Small,
  //   filter: "cultural all",
  //   tipo: "image",
  // },
  // {
  //   big: HW_BJGNMN_I02_4k_Big,
  //   small: HW_BJGNMN_I02_4k_Small,
  //   filter: "cultural all",
  //   tipo: "image",
  // },
  {
    big: RawbankExterior_Big,
    small: RawbankExterior_Small,
    filter: "residential all",
    tipo: "image",
  },
  {
    big: Stills_2_Big,
    small: Stills_2_Small,
    filter: "residential all",
    tipo: "image",
  },
  {
    big: "https://vimeo.com/853157093",
    small: "https://vimeo.com/853157093",
    filter: "cultural  all",
    tipo: "video",
  },
  {
    big: HW_TheCore_A_Big,
    small: HW_TheCore_A_Small,
    filter: "cultural all",
    tipo: "image",
  },
  {
    big: DL_AreiaBranca_Invernadero_Big,
    small: DL_AreiaBranca_Invernadero_Small,
    filter: "offices all",
    tipo: "image",
  },
  {
    big: Andoya_1_Big,
    small: Andoya_1_small,
    filter: "urbanism all",
    tipo: "image",
  },
  {
    big: Andoya_3_Big,
    small: Andoya_3_small,
    filter: "urbanism all",
    tipo: "image",
  },
  {
    big: IDOM_1_Big,
    small: IDOM_1_Small,
    filter: "urbanism all",
    tipo: "image",
  },
  {
    big: "https://vimeo.com/880934964",
    small: "https://vimeo.com/880934964",
    filter: "urbanism all",
    tipo: "video",
  },
  {
    big: Arup_Burj_semiaerea_Pasarellas_resto_MALL_p_Big,
    small: Arup_Burj_semiaerea_Pasarellas_resto_MALL_p_Small,
    filter: "urbanism all",
    tipo: "image",
  },

  {
    big: Arup_Bernabeu_Detail_02_Big,
    small: Arup_Bernabeu_Detail_02_Small,
    filter: "cultural all",
    tipo: "image",
  },
  {
    big: Atela_SL11_E01_C_Big,
    small: Atela_SL11_E01_C_Small,
    filter: "residential all",
    tipo: "image",
  },
  {
    big: Bod_SantaPola_Extra_E01_Big,
    small: Bod_SantaPola_Extra_E01_Small,
    filter: "residential all",
    tipo: "image",
  },
  {
    big: ArupC_Big,
    small: ArupC_Small,
    filter: "urbanism all",
    tipo: "image",
  },
  {
    big: ArupB_Big,
    small: ArupB_Small,
    filter: "urbanism all",
    tipo: "image",
  },
  {
    big: ArupA_Big,
    small: ArupA_Small,
    filter: "urbanism all",
    tipo: "image",
  },
  {
    big: SDSU_1_Big,
    small: SDSU_1_Small,
    filter: "urbanism all",
    tipo: "image",
  },
  {
    big: SDSU_2_Big,
    small: SDSU_2_Small,
    filter: "urbanism all",
    tipo: "image",
  },
  {
    big: Burj_2_Big,
    small: Burj_2_small,
    filter: "urbanism all",
    tipo: "image",
  },

  {
    big: Rawbank_Big,
    small: Rawbank_Small,
    filter: "offices all",
    tipo: "image",
  },
  {
    big: AlexPoulsenArk_MuseumInterior_Courtyardv03_Big,
    small: AlexPoulsenArk_MuseumInterior_Courtyardv03_Small,
    filter: "cultural all",
    tipo: "image",
  },
  {
    big: BertolinoARVO_C01_Big,
    small: BertolinoARVO_C01_Small,
    filter: "residential all",
    tipo: "image",
  },
  {
    big: AGI_1_Big,
    small: AGI_1_Small,
    filter: "offices all",
    tipo: "image",
  },
  {
    big: OrtizLeon_MalagaAcciona_C09_Terrazas_B_Big,
    small: OrtizLeon_MalagaAcciona_C09_Terrazas_B_Small,
    filter: "residential all",
    tipo: "image",
  },
  {
    big: PA_1_Big,
    small: PA_1_Small,
    filter: "residential all",
    tipo: "image",
  },
  {
    big: PA_3_Big,
    small: PA_3_Small,
    filter: "residential all",
    tipo: "image",
  },
  {
    big: StudiomaacArena_E02_Big,
    small: StudiomaacArena_E02_Small,
    filter: "cultural all",
    tipo: "image",
  },
  {
    big: StudioMacc_HotelOuidah_C01_V2_Big,
    small: StudioMacc_HotelOuidah_C01_V2_Small,
    filter: "offices all",
    tipo: "image",
  },
  {
    big: StudioMacc_HotelOuidah_C02_Big,
    small: StudioMacc_HotelOuidah_C02_Small,
    filter: "offices all",
    tipo: "image",
  },
  {
    big: StudioMacc_HotelOuidah_C05_V2_Big,
    small: StudioMacc_HotelOuidah_C05_V2_Small,
    filter: "offices all",
    tipo: "image",
  },
  {
    big: Oria_1_Big,
    small: Oria_1_Small,
    filter: "offices all",
    tipo: "image",
  },
  {
    big: Oria_2_Big,
    small: Oria_2_Small,
    filter: "offices all",
    tipo: "image",
  },

  {
    big: Sigator_1_Big,
    small: Sigator_1_Small,
    filter: "offices all",
    tipo: "image",
  },
  {
    big: Oria_3_Big,
    small: Oria_3_Small,
    filter: "offices all",
    tipo: "image",
  },
  {
    big: Miami_1_Big,
    small: Miami_1_Small,
    filter: "residential all",
    tipo: "image",
  },
  {
    big: AGI_2_Big,
    small: AGI_2_Small,
    filter: "offices all",
    tipo: "image",
  },
  {
    big: Glamping_1_Big,
    small: Glamping_1_Small,
    filter: "residential all",
    tipo: "image",
  },
  {
    big: Dipa_1_Big,
    small: Dipa_1_Small,
    filter: "residential all",
    tipo: "image",
  },

  {
    big: Numa_9_Big,
    small: Numa_9_Small,
    filter: "offices all",
    tipo: "image",
  },
  {
    big: GMP_3_Big,
    small: GMP_3_Small,
    filter: "offices all",
    tipo: "image",
  },

  {
    big: Numa_3_Big,
    small: Numa_3_Small,
    filter: "urbanism commercial all",
    tipo: "image",
  },
  {
    big: Numa_1_Big,
    small: Numa_1_Small,
    filter: "urbanism commercial all",
    tipo: "image",
  },

  {
    big: Atela_E01_Big,
    small: Atela_E01_Small,
    filter: "residential all",
    tipo: "image",
  },
  {
    big: OP_1_Big,
    small: OP_1_Small,
    filter: "cultural all",
    tipo: "image",
  },
  {
    big: ArupE02_Big,
    small: ArupE02_Small,
    filter: "cultural all",
    tipo: "image",
  },

  {
    big: Berilo_13_Big,
    small: Berilo_13_Small,
    filter: "residential all",
    tipo: "image",
  },
];

export { img };
