import styled from 'styled-components';

import { useState, useEffect, useRef } from "react";
import gsap from "gsap";

import useMediaQuery from '../hooks/MediaQueryhook';

import playImg from '../img/web/playLittle2.png';
import pauseImg from '../img/web/pauseLittle2.png';
import { Normal, FullScreenCellphone } from '../redux/PlayReelSlice'
import { useDispatch } from "react-redux";

//import "video.js/dist/video-js.css";

const Contenedor = styled.div`

    align-self: center;
    justify-items: center;
    z-index: 3;
    opacity: 1;  
    display: grid;
    grid-template-columns:  1fr ;
    grid-template-rows:   1fr;
    border-radius: 50px;
    
`;



const BtnBw = styled.img`
 align-self: start;
  justify-self: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    display:block;
  
    line-height:80px;
    border: 1px solid white;
    border-radius: 50px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    text-align:center;
    text-decoration:none;
    background-color: white;
    font-size:20px;
    font-weight:bold;
    border-width: 1px;
    position: relative;
    width:50px;
    height:50px;
    background-size:10px 10px;
    @media screen and (min-width:0px) and (max-width: 480px) {
    height:40px;
    width:40px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    height:50px;
    width:50px;
  }

`;




function BTNC({  setPlaying }) {

  const [BTplaying, BTsetPlaying] = useState(false);
  const dispatch = useDispatch()

  let image = pauseImg;
  (BTplaying) ? image = pauseImg : image = playImg;

  const handleClickPause = () => {
    dispatch(Normal())
    setPlaying(true)   // se envia el estado a home -> landing -> VideoVimeo
  }

  const handleClickPlay = () => {
    dispatch(FullScreenCellphone());
    setPlaying(false)    // se envia el estado a home -> landing -> VideoVimeo
  }

  const BtnRef = useRef(null);

  // calls a hook that checks if the mediaquery is true
  const isMediaQuery768px = useMediaQuery('(max-width: 768px)');


  

  return (
    <>
      <Contenedor >
        {isMediaQuery768px &&
          <BtnBw src={image} ref={BtnRef} alt="Btn"

            onClick={() =>
              BTsetPlaying(!BTplaying) & ((BTplaying) ? (handleClickPause() ) : (handleClickPlay()))
            }

            playingState={pauseImg}

            onMouseDown={() =>
              gsap.fromTo(BtnRef.current,
                { duration: 0.5, backgroundColor: 'white', color: 'gray', borderColor: 'black' },
                { duration: 0.5, backgroundColor: 'black', color: 'black', borderColor: 'white' })}

            onMouseUp={() => (isMediaQuery768px &&
              gsap.fromTo(BtnRef.current,
                { duration: 0.5, backgroundColor: 'black', color: 'white' },
                { duration: 0.5, backgroundColor: 'white', color: 'black' }))}
          />
        }
      </Contenedor>
    </>
  );
}

export { BTNC };





