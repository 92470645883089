// Importa las funciones y componentes necesarios de las bibliotecas.
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";


import punteroArrowLeft from "../../img/puntero/LeftArrow.png";
import punteroArrowRight from "../../img/puntero/RightArrow.png";

import playBT from "../../img/puntero/playBt.png";

import { VideoVimeoComponent } from "../../components/VideoVimeoComponent";

import { LazyGalleryImage } from "./LazyImage";

import useMediaQuery from "../../hooks/MediaQueryhook";

// Define un contenedor estilizado para la galería de imágenes.
const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
  @media (min-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

// Define un componente estilizado para una columna en la galería.
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; /* Espacio entre las imágenes */
`;

const FullScreen = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 62;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eeeeee;
  opacity:1;
`;

const ImgFullScreen = styled.div`
  //background-color: red;
  z-index: 63;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 80%;
  height: 100%;
  align-self: center;
  justify-self: center;
  position: fixed;
  top: 0;
  left: 10%;
  right: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 1;
  cursor: pointer;
  display: grid;
  //grid-template-columns: 10fr 10fr 1fr;
  //background-color: red;
`;

const VideoFullScreen = styled.div`
  width: 80%;
  height: 100vh;
  z-index: 63;
  position: fixed;
  top: 0;
  left: 10%;
  //cursor: pointer;
  display: flex;
`;

const VideoFullWrapper = styled.div`
  //background-color: blue;
  z-index: 64;
  width: 80%;
  height: auto;
  align-self: center;
  justify-self: center;
  position: fixed;
  left: 10%;
  //cursor: pointer;
  display: grid;
`;

const VideoFullMask = styled.div`
  // z-index: 2;
  //background-color: aquamarine;
  //opacity: 0.5;
  height: 90%;
  width: 100%;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const BtLeft = styled.div`
  cursor: url(${punteroArrowLeft}), auto;
  background-color: white;
  opacity: 0;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
`;

const BtRight = styled.div`
  background-color: white;
  cursor: url(${punteroArrowRight}), auto;
  opacity: 0;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  display: grid;
  align-items: start;
  justify-items: end;
`;

const BtClose = styled.div`
  //background-color: red;
  opacity: 1;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-left: 20px;
  font-family: Arial, Helvetica, sans-serif;
`;

const ImgWrapper = styled.div`
  //background-color: black;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
`;

const ImgMask = styled.div`
  // z-index: 2;
  //background-color: aquamarine;
  opacity: 0;
  height: 100%;
  width: 100%;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const VideoThumbnailMask = styled.div`
  //background-color: red;
  opacity: 0.8;
  height: 100%;
  width: 100%;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  cursor: pointer;
   //visibility: visible; 
  background: url(${playBT}) center/contain no-repeat rgba(0, 0, 0, 0); /* Cambia el valor de 'contain' según lo que necesites */
  background-size: 10%;
transition: opacity 1s ease; 
   &:hover {
    opacity: 0; /* Cuando el mouse entra, la imagen se vuelve invisible */
   // visibility: hidden; /* Cuando el mouse entra, la imagen se vuelve invisible */
  }

`;

const VideoThumbnail = styled.div`
  display: grid;
  //height: 100%;
  // width: "auto";
`;

const Contenedor = styled.div`
 // background-color: blue;
  @media screen and (min-width: 0px) and (max-width: 480px) {
    margin-top: 1rem;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 1rem;
  }
  @media screen and (min-width: 769px) and (max-width: 1279px) {
    margin-top: 2.5vw;
  }
  @media screen and (min-width: 1280px) {
    margin-top: 2.5vw;
  }
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: auto;
  z-index: 200;
`;

const ContainerNavBar = styled.div`
  z-index: 200;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  background-color: gray;
  position: fixed;
  justify-items: center;
  top: 0;
  right: 0;
  height: 0;
  width: 50vw;
  justify-self: stretch;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 100vw;
    grid-template-columns: 1rem 1rem auto 1rem 80px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    width: 100vw;
    grid-template-columns: 2.5rem 1vw auto 1vw 100px;
  }
  @media screen and (min-width: 769px) {
    grid-template-columns: 2.5vw 1vw auto 1vw 120px;
  }
`;

import cruzBlack from "../../img/puntero/cruzFB.png";
import cruzWhite from "../../img/puntero/cruzFW.png";

const Circle = styled.div`
  background-image: url(${({ isHovered }) =>    isHovered ? cruzWhite : cruzBlack});
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: 50%;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  align-self: start;
  justify-self: center;
  z-index: 202;
  height: 40px;
  width: 40px;
  -moz-outline-radius: 50px;
  border-radius: 50px;
  transform-origin: 1px;
  transition: all 0.1s linear;
  background-color: ${({ isHovered }) => (isHovered ? "black" : "white")};
  @media screen and (min-width: 0px) and (max-width: 480px) {
    height: 40px;
    width: 40px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    height: 50px;
    width: 50px;
  }
  @media screen and (min-width: 769px) and (max-width: 1279px) {
    height: 50px;
    width: 50px;
  }
  @media screen and (min-width: 1280px) {
    height: 50px;
    width: 50px;
  }
`;

const CargaImagenes = ({ images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false); // Nuevo estado
  const [isHovered, setIsHovered] = useState(false);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setIsViewerOpen(true); // Abre el visor al hacer clic en una imagen
  };

  const handleNavigation = (offset) => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex + offset + images.length) % images.length
    );
  };

  const handleViewerClose = () => {
    setIsViewerOpen(false); // Cierra el visor al hacer clic en "Cerrar"
  };

  const columns = Math.min(3, images.length);
  const columnGroups = [];

  for (let i = 0; i < columns; i++) {
    columnGroups.push(
      images.slice(i, images.length).filter((_, index) => index % columns === i)
    );
  }

  const videoRefs = useRef({});

  // Función para llamar a playVideo() en un componente específico
  const playVideoForComponent = (videoId) => {
    if (videoRefs.current[videoId]) {
      videoRefs.current[videoId].playVideo();
    }
  };

  const pauseVideoForComponent = (videoId) => {
    if (videoRefs.current[videoId]) {
      videoRefs.current[videoId].pauseVideo();
    }
  };

  const isMediaQueryMayor769px = useMediaQuery("(min-width: 769px)");

  return (
    <GalleryContainer>
      {(() => {
        const galleryContent = [];

        for (
          let columnIndex = 0;
          columnIndex < columnGroups.length;
          columnIndex++
        ) {
          const columnImages = columnGroups[columnIndex];
          const columnContent = [];

          for (let index = 0; index < columnImages.length; index++) {
            //const img = columnImages[index];
            const globalIndex = columnIndex + index * columns;

            columnContent.push(
              images[globalIndex].tipo === "image" ? (
                <LazyGalleryImage
                  key={globalIndex}
                  src={images[globalIndex].small}
                  alt=""
                  onClick={() => {
                    isMediaQueryMayor769px ?
                    handleImageClick(globalIndex):"";
                  }}
                />
              ) : (
                <VideoThumbnail key={globalIndex}>
                  <VideoVimeoComponent
                    url={images[globalIndex].big}
                    fullScreen={false}
                    onClick={() =>  isMediaQueryMayor769px ?
                      handleImageClick(globalIndex):""}
                    videoId={globalIndex}
                    videoRefs={videoRefs} // Pasar el objeto de referencias
                  />
                  <VideoThumbnailMask
                    onClick={() =>  isMediaQueryMayor769px ?
                      handleImageClick(globalIndex):""}
                    onMouseEnter={() => playVideoForComponent(globalIndex)}
                    onMouseLeave={() => pauseVideoForComponent(globalIndex)}
                  />
                </VideoThumbnail>
              )
            );
          }
          galleryContent.push(
            <Column key={columnIndex}>{columnContent}</Column>
          );
        }
        return galleryContent;
      })()}

      {/*---- CODIGO PARA IMAGEN/VIDEO EN ZOOM ----*/}
      {selectedImageIndex !== null && isViewerOpen && (
        <FullScreen
          onClick={() => handleViewerClose() & console.log("close clicked")}
        >
          <ContainerNavBar>
            <Contenedor>
              <Circle
                isHovered={isHovered}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
               
              </Circle>
            </Contenedor>
          </ContainerNavBar>
        </FullScreen>
      )}

      {selectedImageIndex !== null && isViewerOpen && (
        <>
          {images[selectedImageIndex].tipo === "image" ? (
            <>
              <ImgFullScreen>
                <ImgWrapper
                  style={{
                    backgroundImage: `url(${images[selectedImageIndex].big})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    width: "auto",
                    height: "100%"
                  }}
                ></ImgWrapper>

                <ImgMask>
                  <BtLeft onClick={() => handleNavigation(-1)} />
                  <div />
                  <BtRight onClick={() => handleNavigation(1)} />
                </ImgMask>
              </ImgFullScreen>
            </>
          ) : (
            <VideoFullScreen /*onClick={() => handleViewerClose()}*/>
              <VideoFullWrapper>
                <VideoVimeoComponent
                  key={selectedImageIndex}
                  url={images[selectedImageIndex].big}
                  fullScreen={true}
                ></VideoVimeoComponent>

                <VideoFullMask>
                  <BtLeft onClick={() => handleNavigation(-1)} />
                  <div />
                  <BtRight onClick={() => handleNavigation(1)} />
                </VideoFullMask>
              </VideoFullWrapper>
            </VideoFullScreen>
          )}
        </>
      )}
    </GalleryContainer>
  );
};

export default CargaImagenes;
