import styled from 'styled-components';
import useMediaQuery from '../hooks/MediaQueryhook';


const ImagenImg = styled.img`
  width: 100%;
  height:100%;
  display: block;
`;

function ImageWebp(props) {

 // const isMediaQueryMenor769px = useMediaQuery('(max-width: 769px)');
  const isMediaQueryMayor769px = useMediaQuery('(min-width: 769px)');




  return (
    <>

            <picture>
              <source srcSet={props.webpBigSrc} type="image/webp" alt="Imagen"/>
              <source srcSet={props.imgAltBigSrc} type="image/jpeg" alt="Imagen"/>
              <ImagenImg src={props.imgAltBigSrc} alt="props.altTxt" />
            </picture>
           
    </>)


}



export { ImageWebp };

