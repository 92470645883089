import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import gsap from "gsap";
import playBT from "../../../img/puntero/playBt.png";

//-BODYONEPIC-START-------------------------------------------------------------------------------------------------
const CntBodyOnePicsframe = styled.div`
  background-color: red;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-height: 1050px) and (max-width: 480px) {
    width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const CntBodyOnePicsImage1 = styled.div`
  //background-color: #ef4125;
  padding-left: 20vw;
  padding-right: 20vw;
  display: grid;
  justify-items: start;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  @media screen and (min-width: 0px) and (max-width: 768px) {  
  padding-left: 0vw;
  padding-right: 0vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1280px) {
   padding-left: 10vw;
  padding-right: 10vw;
  }
`;

const Image1BodyOnePics = styled.div`
  //background-color: #ef4125;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    width: 100vw;
    object-fit: cover;
  }
  display: grid;
`;
//-BODYONEPIC-END------------------------------------------------------------------------------------------------------------------

const VideoThumbnailMask = styled.div`

  //background-color: red;
  transition: opacity 1s ease;
  opacity: ${props => (props.clicked ? '0.1' : '0.8')};
  height: 100%;
  width: 100%;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  cursor: pointer;
  background: url(${playBT}) center/contain no-repeat rgba(0, 0, 0, 0);
  background-size: 20%;
`;
const VideoContainer = styled.div`
  display: flex;
  justify-content: start;
  align-content: start;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: auto;
`;

const OneVideoVimeo = ({ url }) => {
  const playerRef = useRef(null);
  const videoWrapperRef = useRef(null);
  
  const contenedorPrincipalRef = useRef(null);
  const pauseVideo = () => {
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().pause();
    }
  };

  const playVideo = () => {
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().play();
    }
  };


  //------------------- para saber si salimos del area del video ---------------

  useEffect(() => {
    const Element = contenedorPrincipalRef.current; // Obtenemos el elemento de video mediante la referencia.
    const observerOptions = {
      root: null, // Observamos el área visible del viewport.
      rootMargin: "0px", // Margen alrededor del área visible (en este caso, ninguno).
      threshold: 0.5, // Define el umbral para considerar que el video está visible (50% en este caso).
    };
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Si el video está dentro del área visible (50% o más), establecemos el estado a true.
          playVideo()
      
        } else {
          // Si el video ya no está en el área visible, establecemos el estado a false.
          pauseVideo();
          
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    ); // Creamos el IntersectionObserver.
    if (Element) {
      observer.observe(Element); // Observamos el elemento de video con el IntersectionObserver.
    }
    return () => {
      if (Element) {
        observer.unobserve(Element); // Dejamos de observar el elemento cuando el componente se desmonta.
      }
    };
  }, []); // El array vacío indica que este efecto se ejecutará solo una vez, al montar el componente.
  //--------------------------------------------------------------------------------------------------------------------------



  return (
    <>
      <CntBodyOnePicsImage1 ref={contenedorPrincipalRef}>
        <Image1BodyOnePics>
          <VideoContainer>
            <VideoWrapper ref={videoWrapperRef}>
              <ReactPlayer
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
                width="100%"
                height="100%"
                ref={playerRef}
                url={url}
                controls={true} // Actualización aquí
                //muted={fullScreen === true ? false : true}
                //autoPlay={fullScreen === true ? true : false}
                config={{
                  vimeo: {
                    quality: "auto",
                  },
                }}
                loop={true}
              />
            </VideoWrapper>
          </VideoContainer>
         
        </Image1BodyOnePics>
      </CntBodyOnePicsImage1>
    </>
  );
};

export default OneVideoVimeo;
