import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import Burger from "../../components/Nav/Burger.jsx";
import Footer from "../Home/Footer.js";
import { AeroportFontCont } from "../../fonts/AeroportFont_Cont.js"; 
import VimeoVideoLowMenu from "./components/VimeoVideoLowMenu.js";
import Spy from "../../Spy.js";



import Card_FO from "../Stories/Cards/CardInidividual_FO.js"; 
import Card_Numa from "../Stories/Cards/CardInidividual_Numa.js"; 
import Card_Stills from "../Stories/Cards/CardInidividual_Stills.js";

//-CONTENEDOR PRINCIPAL (CntFrame) -START-------------------------------------------------------------------------------------
const CntNavBar = styled.div`
  //background-color: #EEEEEE;

  z-index: 50;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  position: fixed;
  justify-items: center;
  top: 0;
  right: 0;
  height: 5%;
  width: 50vw;
  justify-self: stretch;
  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 100vw;
    grid-template-columns: 1rem 1rem auto 1rem 80px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    width: 100vw;
    grid-template-columns: 2.5rem 1vw auto 1vw 100px;
  }
  @media screen and (min-width: 769px) {
    grid-template-columns: 2.5vw 1vw auto 1vw 120px;
  }
`;

const CntFrame = styled.div`
  width: 100vw;
  background-color: #eeeeee;
  color: black;
  //opacity: 0.3;
`;

//-CONTENEDOR PRINCIPAL (CntFrame) -END----------------------------------------------------------------------------------------

//-TEXTBODY_3-START---------------------------------------------------------------------------------------------------
const CntTextBody_3Frame = styled.div`
  opacity: 1;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 10vw;
  width: 50vw;
  height: fit-content;
  background-color: #eeeeee;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding: 4vh 0 4vh 0;
  }
`;

const CntTextBody_3TxtParf1 = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 0%;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin: 5vw;
    margin-top: 10vw;
    width: calc(100vw - 10vw);
  }
`;

const CntTextBody_3TxtParf2 = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-top: 2%;
  margin-right: 10vw;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin: 5vw;
    margin-bottom: 10vw;
    width: calc(100vw - 10vw);
  }
`;
//-TEXTBODY_3-END---------------------------------------------------------------------------------------------------

//-MORESTORIES-START--------------------------------------------------------------------------------------------------

const CntMSService = styled.div`
  padding-top: 10px;
  width: 100vw;
  height: auto;
  background-color: #eeeeee;
  margin-bottom: 80px;
`;

const SliderMS = styled.div`
  width: 100vw;
  height: auto;
  overflow-x: visible;
  grid-auto-flow: column;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
`;

const CntMSTxtTitle = styled.div`
  a {
    //quita el espacio izq de la primera letra
    margin-left: -0.04em;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 40px;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding-left: 5vw;
    padding-bottom: 5vw;
  }
  @media screen and (min-width: 769px) {
    padding-left: 2.5vw;
    padding-bottom: 2.5vw;
  }
`;

const CntMSTxtTitleMain = styled.div`
  a {
    //quita el espacio izq de la primera letra
   // margin-left: -0.04em;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  //padding-bottom: 40px;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding-left: 5vw;
    padding-bottom: 5vw;
  }
  @media screen and (min-width: 769px) {
    padding-left: 2.5vw;
    padding-bottom: 2.5vw;
  }
`;


const CntMSImgGeneral = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 17.5px);

  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: calc(100vw);
  }
  @media screen and (min-width: 0px) and (max-width: 315px) {
    width: calc(200vw - 17.5px);
  }
  @media screen and (min-width: 316px) and (max-width: 480px) {
    width: calc(220vw - 17.5px);
  }
  @media screen and (min-width: 481px) and (max-width: 939px) {
    width: calc(180vw - 17.5px);
  }
  @media screen and (min-width: 940px) and (max-width: 979px) {
    width: calc(120vw - 17.5px);
  }
  @media screen and (min-width: 980px) {
    width: calc(100vw - 17.5px);
  }
`;
//-MORESTORIES-END--------------------------------------------------------------------------------------------------

const LowMenuThird = () => {
  Spy("UrbanDesign", "LowMenu");

   document.body.scrollTop = document.documentElement.scrollTop = 0;
  const cardAnimRef = useRef(null);


  //funcion para quiebre de linea-----------------------------------------------------------------
  const addLineBreaks = (string) =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));
  //-----------------------------------------------------------------------------------------

  return (
    <>
      <CntFrame>
        <CntNavBar id="boton">
          <Burger />
        </CntNavBar>

        <div style={{ paddingTop: "50px" }} />
        <CntMSTxtTitleMain>
            <AeroportFontCont
              sizePhone="55px"
              sizeSmall="85px"
              sizeMedium="90px"
              sizeBig="110px"
              lHeightDefault="1"
              //pTop="30px"
            >
              <a></a>
            </AeroportFontCont>
          </CntMSTxtTitleMain>

        {/*-BODYONEPIC-START--------------------------------------------------------------------------*/}

        <VimeoVideoLowMenu url={"https://vimeo.com/852705926"} />

        {/*-BODYONEPIC-END----------------------------------------------------------------------------*/}

        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        {/* <CntTextBody_3Frame>
          <CntTextBody_3TxtParf1>
            <AeroportFontCont
              sizePhone="20px"
              sizeSmall="20px"
              sizeMedium="27px"
              sizeBig="40px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                <b>Stage 01: Cameras and Mood.</b>
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf1>
          <CntTextBody_3TxtParf2>
            <AeroportFontCont
              sizePhone="14px"
              sizeSmall="14px"
              sizeMedium="14px"
              sizeBig="21px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                We start working in our script and storyboard. It's important to
                make a discovery meeting where everyone involved gets a general
                idea of what the video is going to show and what's the story
                behind the scenes.
                <br />
                <br />
                <b>We Deliver:</b> •Storyboard with the scenes and mood in
                general. •A selection of music tracks. <br />
                <br />
                <b>You Deliver:</b> •Your camera selection. •Comments in the
                scenes order. •Confirm mood and ligthing. •Chose a music track
                for the movie.
              </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf2>
        </CntTextBody_3Frame> */}
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}

        {/*-MORESTORIES-START----------------------------------------------------------------------------*/}
        <CntMSService>
          <CntMSTxtTitle>
            <AeroportFontCont
              sizePhone="55px"
              sizeSmall="85px"
              sizeMedium="55px"
              sizeBig="110px"
              lHeightDefault="1"
              pTop="30px"
            >
              <a>More Stories</a>
            </AeroportFontCont>
          </CntMSTxtTitle>
          <SliderMS id="sliderMoreStories">
            <CntMSImgGeneral ref={cardAnimRef}>
              <Card_FO />
              <Card_Numa />
              {/* <Card_Stills /> */}
            </CntMSImgGeneral>
          </SliderMS>
        </CntMSService>
        {/*-MORESTORIES-END----------------------------------------------------------------------------*/}

        <Footer />
      </CntFrame>
    </>
  );
};

export default LowMenuThird;
