import React, { useState } from "react";
import styled from "styled-components";
import RightNav from "./RightNav";
import { useRef } from "react";
import gsap from "gsap";
import useMediaQuery from "../../hooks/MediaQueryhook";
import Spy from "../../Spy";
import { colorRojoNod } from "../../VariablesGlobales";

const Contenedor = styled.div`
  @media screen and (min-width: 0px) and (max-width: 480px) {
    margin-top: 1rem;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 1rem;
  }
  @media screen and (min-width: 769px) and (max-width: 1279px) {
    margin-top: 2.5vw;
  }
  @media screen and (min-width: 1280px) {
    margin-top: 2.5vw;
  }
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: auto;
`;

//---------------------------------------------------------
const BurgerButtonWrapper = styled.div`
  position: relative;
  cursor: pointer;
  opacity: 1;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  align-self: center;
  justify-self: center;
  z-index: 20;
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  -moz-outline-radius: 50px;
  border-radius: 50px;
  border-color: #ffffff;
  transform-origin: 1px;
  transition: all 0.1s linear;
  @media screen and (min-width: 0px) and (max-width: 480px) {
    height: 50px;
    width: 50px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    height: 50px;
    width: 50px;
  }
  @media screen and (min-width: 769px) and (max-width: 1279px) {
    height: 50px;
    width: 50px;
  }
  @media screen and (min-width: 1280px) {
    height: 50px;
    width: 50px;
  }
`;

const Line1 = styled.div`
  width: 20px;
  height: 1px;
  background-color: #000000;
  position: absolute;
  transition: transform 0.3s;
  border-radius: 2px;
  z-index: 20;

  top: 21px;
  left: 15px;
  ${({ isActive, rotation }) => `
    transform:  rotate(${isActive ? -rotation : 0}deg) translateY(${
    isActive ? 2 : 0
  }px)  translateX(${isActive ? -2 : 0}px);
  `}
`;

const Line2 = styled.div`
  width: 20px;
  height: 1px;
  background-color: #070707;
  position: absolute;
  transition: transform 0.3s;
  border-radius: 2px;
  z-index: 20;

  top: 27px;
  left: 15px;
  ${({ isActive, rotation }) => `
    transform:  rotate(${isActive ? -rotation : 0}deg) translateY(${
    isActive ? -2 : 0
  }px)  translateX(${isActive ? -2 : 0}px);
  `}
`;
//----------------------------------------------------
import SpyGA from "../../Observer";

const Burger = () => {
  const [open, setOpen] = useState(false);
  if (open == true) {
    window.addEventListener("click", function (e) {
      if (!document.getElementById("boton").contains(e.target)) {
        setOpen(false);
        //click fuera de la caja
        setIsActive(false);
       /* gsap.to(lineMenuRef1.current, {
          duration: 0.1,
          backgroundColor: "black",
        }) &
          gsap.to(lineMenuRef2.current, {
            duration: 0.1,
            backgroundColor: "black",
          }) &
          gsap.to(circleRef.current, {
            duration: 0.8,
            backgroundColor: "white",
          });*/
          AnimaMUp()
      }
    });
    document.addEventListener("scroll", () => {
      setOpen(false);
      setIsActive(false);

     /* gsap.to(lineMenuRef1.current, {
        duration: 0.1,
        backgroundColor: "black",
      }) &
        gsap.to(lineMenuRef2.current, {
          duration: 0.1,
          backgroundColor: "black",
        }) &
        gsap.to(circleRef.current, {
          duration: 0.8,
          backgroundColor: "white",
        });*/
        AnimaMUp()
    });
  }

  const circleRef = useRef(null);
  const lineMenuRef1 = useRef(null);
  const lineMenuRef2 = useRef(null);
  const isMediaQuery769px = useMediaQuery("(min-width: 769px)");

  const AnimaMDown = () => {
    open
     /* ? gsap.to(circleRef.current, {
          duration: 0.3,
          backgroundColor: colorRojoNod,
        })
      :*/ gsap.to(circleRef.current, { duration: 0.1, backgroundColor: "black" });
    gsap.to(lineMenuRef1.current, { duration: 0.1, backgroundColor: "white" });
    gsap.to(lineMenuRef2.current, { duration: 0.1, backgroundColor: "white" });
  };

  const AnimaMUp = () => {
    open
     /* ? gsap.to(circleRef.current, {
          duration: 0.3,
          backgroundColor: colorRojoNod,
        })
      :*/
       gsap.to(circleRef.current, { duration: 0.1, backgroundColor: "white" });
    gsap.to(lineMenuRef1.current, { duration: 0.1, backgroundColor: "black" });
    gsap.to(lineMenuRef2.current, { duration: 0.1, backgroundColor: "black" });
  };

  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <Contenedor>
        <BurgerButtonWrapper
          ref={circleRef}
          open={open}
          onClick={() =>
            setOpen(!open) &
            SpyGA("Clicked", "BT_MENU_CLICKED") &
            setIsActive(!isActive)
          }
          className={isActive ? "active" : ""}
          onMouseLeave={() => (isMediaQuery769px ? AnimaMUp() : "")}
          onMouseEnter={() => (isMediaQuery769px ? AnimaMDown() : "")}
        >
          <Line1 ref={lineMenuRef1} isActive={isActive} rotation={45} />
          <Line2 ref={lineMenuRef2} isActive={isActive} rotation={-45} />
        </BurgerButtonWrapper>
        <RightNav open={open} />
      </Contenedor>
    </>
  );
};

export default Burger;
