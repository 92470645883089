import styled from 'styled-components';
import { ImageWebp } from '../../../components/ImageWebp';


const CntHeaderImage = styled.div`
  //background-color: blue;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
  display: relative;
  height: 100%;
  display: grid;
  justify-items: start;
  align-items: start;
  grid-template-rows: (repeat(), 1fr);
  grid-template-columns: (repeat(), 1fr);
  z-index: 1;
  overflow: hidden;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
  }
`;

const ImageHeader = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
  line-height:0;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    align-self: end;
    justify-self: end;
    object-fit: cover;
  }
  @media screen and (min-width: 769px) and (max-width: 1279px) {
    width: 105%;
  }
  @media screen and (min-width: 1280px) {
  }
`;



function HeaderPic(props) {

  return (
    <>

      <CntHeaderImage>
        <ImageHeader>
          <ImageWebp webPBigSrc={props.imgWebpBig} imgAltBigSrc={props.imgAltBig} />
        </ImageHeader>
      </CntHeaderImage>


    </>
  );

}



export default HeaderPic;


