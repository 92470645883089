import React from 'react';

import Card from "./Card";


import cara from "../../../img/web/Est/caraBig.jpg";
import carb from "../../../img/web/Est/carbBig.webp";

const Card_Car= () => {
  return (
    <>
                <Card
                Id_Card="Id8"
                Header_Title="YOUR PROJECT IN MOTION"
                Header_SubTitle="MOVIES FOR ARCHITECTS AND DEVELOPERS."
                Size_Card="small"
                Footer_Paragraph="Over the past few years, video has risen into popularity across the globe. You see it everywhere.

                Software like Twinmotion and Lumion are some of the solutions that allow architects to make decent images and videos with little time and experience.
                
                So when should you call a specialist like us?"
                Image_Src_Jpg={cara}
                Image_Src_Webp={carb}
                SetLink="/work/Car"
              />
    </>
  );
};
export default Card_Car;