//prueba
import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import Burger from "../../../components/Nav/Burger.jsx";

import Footer from "../../Home/Footer";


import { AeroportFontCont } from "../../../fonts/AeroportFont_Cont.js";
import { HLineMinMax } from "../../../components/HLine.js";

import punteroArrowLeft from "../../../img/puntero/LeftArrow.png"
import punteroArrowRight from "../../../img/puntero/RightArrow.png"

import Card_FO from "../Cards/CardInidividual_FO.js";//arch
import Card_GMP from "../Cards/CardInidividual_GMP.js";// arch
import Card_HCMA from "../Cards/CardInidividual_HCMA.js";//arch

import reactGA from "react-ga4"

import HeaderPic from "../WorksComponents/HeaderPic.js";
import OnePic from "../WorksComponents/OnePic.js";
import TwoPics from "../WorksComponents/TwoPics.js";
//import TextParf1 from "../WorksComponents/TextParf1.js";
//import TextParf2 from "../WorksComponents/TextParf2.js";
//import TextParf3 from "../WorksComponents/TextParf3.js";

import Spy from "../../../Spy.js";

//-------------- styled Components abajo-------------------

//-CONTENEDOR PRINCIPAL (CntFrame) -START-------------------------------------------------------------------------------------
const CntNavBar = styled.div`
  //background-color: #EEEEEE;

  z-index: 50;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  position: fixed;
  justify-items: center;
  top: 0;
  right: 0;
  height: 5%;
  width: 50vw;
  justify-self: stretch;
  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 100vw;
    grid-template-columns: 1rem 1rem auto 1rem 80px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    width: 100vw;
    grid-template-columns: 2.5rem 1vw auto 1vw 100px;
  }
  @media screen and (min-width: 769px) {
    grid-template-columns: 2.5vw 1vw auto 1vw 120px;
  }

`;

const CntFrame = styled.div`
  width: 100vw;
  background-color: #EEEEEE;
  color: black;
  //opacity: 0.3;
`;

//-CONTENEDOR PRINCIPAL (CntFrame) -END----------------------------------------------------------------------------------------



//-HEADER-START----------------------------------------------------------------------------------------------------------------
const CntHeaderFrame = styled.div`

  width: 100%; //auto;º
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    align-items: start;
    justify-content: start;
    height:100vh;
  }
  @media screen and (min-width: 769px) {
    padding-top: 120px;
  }
  @media (max-height: 1050px) and (max-width: 480px) {
    width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;


const CntHeaderInside = styled.div`
  //background-color: blueviolet  ;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  top: 0px;
  left: 0px;
  margin-right: 10vw;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows:  1fr auto ;
    width: 100vw;
    height:100vh;
  }
  @media screen and (min-width: 769px) and (max-width: 1279px) {
    grid-template-columns: 55% 45%;
  }
  @media screen and (min-width: 1280px) {
    grid-template-columns: 65% 35%;
  }
`;

const CntHeaderTxTSuperScreen_S_P = styled.div`
  display: grid;
  align-items: end;
  
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  height:auto;
  @media screen and (min-width: 769px)  {
    display: none;
  }
  
`;

const CntHeaderExtra = styled.div`

  flex-direction: column;
  width: fit-content;
  margin-left: 5vw;
  margin-bottom: 5vw;
`;

const CntHeaderTxTSuperScreen_M_B = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    display: none;
  }

`;

const CntHeaderTxTinferior = styled.div`

  justify-self: end;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: fit-content;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-top: 5vw;
    margin-bottom: 10vw;
  }
`;

const CntHeaderTxt = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  margin: 0;
  display: grid;

  @media screen and (min-width: 769px)  {
    padding-left: 2.5vw;
    padding-top: 2.2vw;
  }
  @media screen and (min-width: 0px) and (max-width: 768px) {
      display:none;
  }

`;

const CntHeaderParrafo = styled.div`
  line-height: 0.5;
  width: 100%;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    width: calc(100% - 10vw);
    margin-left: 5vw;
  }
  @media screen and (min-width:1279px) and (max-width: 1570px) {
    div{
      font-size: 15px;
    }
  }
  @media screen and (min-width:1279px) and (max-width: 1340px) {
    div{
      font-size: 13px;
    }
  }
`;

const CntHeaderTitle = styled.div`
  margin-left: -0.08em;
  @media screen and (min-width:1279px) and (max-width: 1500px) {
    font-size: 68px;
  }
  @media screen and (min-width:769px) and (max-width: 830px) {
    font-size: 40px;
  }
  @media screen and (min-width: 0px) and  (max-width: 768px) {
    margin-right: 5vw;
  }
`;

const ImageHeader = styled.div`

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
  line-height:0;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    align-self: end;
    justify-self: end;
    object-fit: cover;
  }
  @media screen and (min-width: 769px) and (max-width: 1279px) {
    width: 105%;
  }
  @media screen and (min-width: 1280px) {
  }
`;

const CntTextoHeaderPhone = styled.div`
  opacity: 1;
  width: 100vw;
  height: fit-content;
  background-color: white;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    display:none;
  }
`;
//-HEADER-END---------------------------------------------------------------------------------------------------------



//-TEXTBODY_1-START---------------------------------------------------------------------------------------------------
const CntTextBody_1Frame = styled.div`
  opacity: 1;
  width: 100vw;
  height: fit-content;
  background-color: #eeeeee;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;  
  align-content: center;
`;




const CntTextBody_1TxtParf = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 5%;
  margin-bottom: 5%;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 10vw;
    margin-bottom: 10vw;
    width: calc(100vw - 10vw);
  }
`;
//-TEXTBODY_1-END---------------------------------------------------------------------------------------------------



//-TEXTBODY_2-START---------------------------------------------------------------------------------------------------
const CntTextBody_2Frame = styled.div`
  opacity: 1;
  width: 100vw;
  height: fit-content;
  background-color: #eeeeee;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const CntTextBody_2TxtParf = styled.div`
  justify-self: start;
  align-self: start;
  margin-left:10vw;
  margin-right: 10vw;
  margin-top: 5%;
  margin-bottom: 5%;
  @media screen and (min-width: 0px) and  (max-width: 768px) {
    margin-left: 5vw;
    margin-right:5vw;
    margin-top: 20vw;
    margin-bottom: 20vw;

  }
`;
//-TEXTBODY_2-END---------------------------------------------------------------------------------------------------


//-BODYONEPIC-START-------------------------------------------------------------------------------------------------
const CntBodyOnePicsframe = styled.div`
  width: 100%;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-height: 1050px) and (max-width: 480px) {
    width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const CntBodyOnePicsImage1 = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  margin-left: 10vw;
  margin-right: 10vw;
  height: 100%;
  display: grid;
  justify-items: start;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-right: calc(10%);
    width: calc(90 - 10%);
    height: 100%;
  }
`;

const Image1BodyOnePics = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 100%;
  width: 100%;
  object-fit: contain; 
  overflow: hidden;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    width: 100vw;
    object-fit: cover;
  }
`;
//-BODYONEPIC-END------------------------------------------------------------------------------------------------------------------


//-TEXTBODY_3-START---------------------------------------------------------------------------------------------------
const CntTextBody_3Frame = styled.div`
  opacity: 1;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 25vw;
  width: 50vw;
  height: fit-content;
  background-color: #eeeeee;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: 0px) and  (max-width: 768px) {
    
    padding: 4vh 0 4vh 0;
  }
`;

const CntTextBody_3TxtParf1 = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 0%;
  @media screen and (min-width: 0px) and  (max-width: 768px) {
    margin: 5vw;
    margin-top: 10vw;
    width: calc(100vw - 10vw);
  }
`;

const CntTextBody_3TxtParf2 = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-top: 2%;
  margin-right: 10vw;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin: 5vw;
    margin-bottom: 10vw;
    width: calc(100vw - 10vw);
  }
`;
//-TEXTBODY_3-END---------------------------------------------------------------------------------------------------



//-CARROUSEL-START--------------------------------------------------------------------------------------------------
const CntCarrouselFrame = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
 // background-color: red;
  padding-top: 10px;
  //margin-top: 80px;
  width: 100vw;
  height: auto;
  //margin-bottom: 80px;
  margin-left: 10vw;
  //cursor: url( ${props => props.cursorFlecha}),auto;

  @media screen and (min-width:0px) and (max-width: 768px) {
    margin-left: 0vw;  
  }
`;

const ImagenCarrousel = styled.div`
  //background-color: blueviolet;
  background-size: cover;
  object-fit: cover;
  height: 45vw;
  width: 80vw;
  @media screen and (min-width:0px) and (max-width: 768px) {
    object-fit: contain;
    height: 50vw;
    width: 100vw;     
  }

`;

const DivPointLeft = styled.div`
  position: absolute;
  cursor: url( ${punteroArrowLeft}),auto;
  opacity: 0.5;
  //background-color: red;
  width: 20vw;
  height:42vw; 
  left:0px;
  z-index: 300;
`;

const DivPointRight = styled.div`
  position: absolute;
  cursor: url( ${punteroArrowRight}),auto;
  opacity: 0.5;
  //background-color: red;
  width: 20vw;
  height:42vw;  
  right:0px;
  z-index: 300;
`;

//-CARROUSEL-END--------------------------------------------------------------------------------------------------



//-MORESTORIES-START--------------------------------------------------------------------------------------------------

const CntMSService = styled.div`
  padding-top: 10px;
  width: 100vw;
  height: auto;
  background-color: #eeeeee;
  margin-bottom: 80px;
 `;



const SliderMS = styled.div`

  width: 100vw;
  height: auto;
  overflow-x: visible;
  grid-auto-flow: column;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

`;

const CntMSTxtTitle = styled.div`
  a {
    //quita el espacio izq de la primera letra
    margin-left: -0.04em;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 40px;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding-left: 5vw;
    padding-bottom:5vw;
  }
  @media screen and (min-width: 769px)  {
    padding-left: 2.5vw;
    padding-bottom:2.5vw;
  }

`;

const CntMSImgGeneral = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 17.5px);
  
  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: calc(100vw);
  } 
  @media screen and (min-width:0px) and (max-width: 315px) {
    width: calc(200vw - 17.5px);
  }
  @media screen and (min-width:316px) and (max-width: 480px) {
    width: calc(220vw - 17.5px);
  }
  @media screen and (min-width:481px) and (max-width: 939px) {
    width: calc(180vw - 17.5px);
  }
  @media screen and (min-width:940px) and (max-width: 979px) {
    width: calc(120vw - 17.5px);
  }
  @media screen and (min-width:980px) {
    width: calc(100vw - 17.5px); 
  }
  
`;

//-MORESTORIES-END--------------------------------------------------------------------------------------------------




// import de Imagenes------------------------------------------
import image_1a from "../../../img/Work/OP/OP_1Big.jpg";
import image_1b from "../../../img/Work/OP/OP_1Big.webp";


import image_2a from "../../../img/Work/OP/OP_2Big.jpg";
import image_2b from "../../../img/Work/OP/OP_2Big.webp";

import image_3a from "../../../img/Work/OP/OP_3Big.jpg";
import image_3b from "../../../img/Work/OP/OP_3Big.webp";


import image_4a from "../../../img/Work/OP/OP_4Big.jpg";
import image_4b from "../../../img/Work/OP/OP_4Big.webp";

import image_5a from "../../../img/Work/OP/OP_5Big.jpg";
import image_5b from "../../../img/work/OP/OP_5Big.webp";

//-------------------------------------------------------------

//-IMAGENES SLIDER FINAL --------------------------------------

import imageSlider1Jpg from "../../../img/web/Est/urban_berilo_E21Big.jpg";
import imageSlider1Webp from "../../../img/web/Est/urban_berilo_E21Big.webp";

import imageSlider2Jpg from "../../../img/web/Est/Ortiz_Leon_Arq_GMP_PatioBig.jpg";
import imageSlider2Webp from "../../../img/web/Est/Ortiz_Leon_Arq_GMP_PatioBig.webp";

import imageSlider3Jpg from "../../../img/web/Est/OrtizLeon_Fuencarral_FrenteBig.jpg";
import imageSlider3Webp from "../../../img/web/Est/OrtizLeon_Fuencarral_FrenteBig.webp";

//--------------------------------------------------------------




const Work_Individual_OP = () => {
  Spy("OpenPages","OPWorkAccess")


  /*--- A TENER EN CUENTA ---

  imagenes se cargan mas arriba por variable.s
  los textos se escriben igual que en una plantilla HTML

  el componente : <AeroportFontCont>
  es una caja de texto donde ya lleva las mediaQuerys 
  incluidas para modificar el tamaño de letra y el espaciado vertical.
  asi mismo si uno hace un DIV hijo puede agregar otro tamaño de letra con una mediaQuery nueva.
                  
                  sizePhone=  (min-width:0px) and (max-width: 480px) 
                  sizeSmall=  (min-width:481px) and (max-width: 768px) 
                  sizeMedium= (min-width:769px) and (max-width: 1279px) 
                  sizeBig=    (min-width:1280px)

                  lHeightPhone= igual que para el size
                  lHeightSmall=
                  lHeightMedium=
                  lHeightBig=
  --------------------------------------- */
  document.body.scrollTop = document.documentElement.scrollTop = 0;

  const cardAnimRef = useRef(null);

  useEffect(() => {
    //carrousel MORE STORIES START-----------------------------------------------------------------
    //drag & move 
    const slider = document.getElementById('sliderMoreStories');
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      slider.style.userSelect = 'none';
      isDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener('mouseleave', () => {
      isDown = false;
    });

    slider.addEventListener('mouseup', () => {
      isDown = false;
    });

    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
    //carrousel MORE STORIES END-----------------------------------------------------------------
  }, []);


  //funcion para quiebre de linea-----------------------------------------------------------------
  const addLineBreaks = string =>
    string.split('\n').map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));
  //-----------------------------------------------------------------------------------------



  return (
    <>
      <CntFrame>
        <CntNavBar id="boton">
          <Burger />
        </CntNavBar>

        {/*-HEADER-START----------------------------------------------------------------------------*/}
        <CntHeaderFrame id="Header">
          <CntHeaderInside>

            {/*------- ESTO SOLO APARECE EN WIDTH < 769 -----------------*/}
            {/*SE HIZO PARA PODER INTERCALAR TEXTO DE ABAJO HACIA ARRIBA */}

            <CntHeaderTxTSuperScreen_S_P>
              <CntHeaderExtra>
                <AeroportFontCont sizePhone="45px" sizeSmall="65px" lHeightDefault="1">

                  <CntHeaderTitle>
                    <p>OBSERVATION POINT.</p>
                  </CntHeaderTitle>
                </AeroportFontCont>

                <AeroportFontCont sizePhone="12px" sizeSmall="16px" lHeightPhone="1.5" lHeightSmall="1.8">
                  <p>RENDERS FOR ARCHITECTS.</p>
                </AeroportFontCont>
              </CntHeaderExtra>
            </CntHeaderTxTSuperScreen_S_P>
            {/*--------------------------------------------------------*/}

            <HeaderPic imgWebpBig={image_1a} imgAltBig={image_1b} altTxt1={"texto alternativo"}/>

            {/*------- ESTO SOLO APARECE EN WIDTH > 770 ----------------*/}
            <CntHeaderTxt>
              <CntHeaderTxTSuperScreen_M_B>
                <AeroportFontCont sizeMedium="10px" sizeBig="16px" lHeightMedium="1.8" lHeightBig="2.6">
                  <p>RENDERS FOR ARCHITECTS.</p>
                </AeroportFontCont>
                <AeroportFontCont sizeMedium="55px" sizeBig="80px" lHeightMedium="0.8" lHeightBig="0.8">
                  <CntHeaderTitle>
                    <a>OBSERVATION <br /> POINT.</a>
                  </CntHeaderTitle>
                </AeroportFontCont>

              </CntHeaderTxTSuperScreen_M_B>
              <CntHeaderTxTinferior>
                <CntHeaderParrafo>
                  <HLineMinMax startPx="100px" endPx="3000px" width="calc(100%)" left="0px" mBottom="" mtop="0" />
                  <AeroportFontCont sizeMedium="14px" sizeBig="18px" lHeightMedium="1" lHeightBig="1.2" style={{ marginTop: "2.5vw" }}>
                    <p>
                      On June 2022 a Heatherwick associate contact us for a small personal project.
                      It was a competition proposal for a small observation point in Portugal.
                      <br /><br />
                      The geometry, scale, and the landscape were perfect to teach the junior members of our studio on how to make quick drafts, test different camera options and lighting.
                      <br /><br />
                      So we did 8 different options and let the client choose for the best ones.
                    </p>
                  </AeroportFontCont>
                </CntHeaderParrafo>
              </CntHeaderTxTinferior>
            </CntHeaderTxt>
          </CntHeaderInside>
        </CntHeaderFrame>
        {/*----------------------------------------------------------*/}

        {/*------- ESTO SOLO APARECE EN WIDTH < 769 -----------------*/}
        <CntTextoHeaderPhone>
          <CntHeaderTxTinferior>
            <CntHeaderParrafo>
              <AeroportFontCont sizePhone="14px" sizeSmall="14px" lHeightPhone="1" lHeightSmall="0.9" style={{ marginTop: "2.5vw" }}>
                <p>
                  On June 2022 a Heatherwick associate contact us for a small personal project.
                  It was a competition proposal for a small observation point in Portugal.
                  <br /><br />
                  The geometry, scale, and the landscape were perfect to teach the junior members of our studio on how to make quick drafts, test different camera options and lighting.
                  <br /><br />
                  So we did 8 different options and let the client choose for the best ones.
                </p>
              </AeroportFontCont>
            </CntHeaderParrafo>
          </CntHeaderTxTinferior>
        </CntTextoHeaderPhone>
        {/*----------------------------------------------------------*/}
        {/*-HEADER-END----------------------------------------------------------------------------*/}

        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3Frame>
        </CntTextBody_3Frame>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}



        {/*-BODYTWOPICS-START----------------------------------------------------------------------------*/}
        <TwoPics 	imgWebpBig1={image_2a} imgAltBig1={image_2b} altTxt1={"texto alternativo"} 
		imgWebpBig2={image_3a} imgAltBig2={image_3b} altTxt2={"texto alternativo"} />
        {/*-BODYTWOPICS-END----------------------------------------------------------------------------*/}


        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3Frame>
        </CntTextBody_3Frame>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}


        {/*-BODYONEPIC-START----------------------------------------------------------------------------*/}
        <OnePic imgWebpBig={image_4a} imgAltBig={image_4b} altTxt1={"texto alternativo"} />
        {/*-BODYONEPIC-END----------------------------------------------------------------------------*/}


        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3Frame>
        </CntTextBody_3Frame>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}

        {/*-BODYONEPIC-START----------------------------------------------------------------------------*/}
        <OnePic imgWebpBig={image_5a} imgAltBig={image_5b} altTxt1={"texto alternativo"} />
        {/*-BODYONEPIC-END----------------------------------------------------------------------------*/}



        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3Frame>
        </CntTextBody_3Frame>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}




        {/*-MORESTORIES-START----------------------------------------------------------------------------*/}
        <CntMSService>
          <CntMSTxtTitle>
            <AeroportFontCont
              sizePhone="55px"
              sizeSmall="85px"
              sizeMedium="55px"
              sizeBig="110px"
              lHeightDefault="1"
              pTop="30px"
            >
              <a>More Stories</a>
            </AeroportFontCont>
          </CntMSTxtTitle>
          <SliderMS id="sliderMoreStories">
            <CntMSImgGeneral ref={cardAnimRef}>
              <Card_FO />
              <Card_GMP />
              <Card_HCMA />
            </CntMSImgGeneral>
          </SliderMS>
        </CntMSService>
        {/*-MORESTORIES-END----------------------------------------------------------------------------*/}

        <Footer />



      </CntFrame>
    </>
  );
};

export default Work_Individual_OP;
