const colorRojoNod = () =>{
    return "#ef4125";  // "#ef4125";  //"#555555";//
}


//margenes para tarjetas
const MargenesCards = (e) =>{
    let size = "1px";
    if(e=="B"){
        size = "10px"
    }else
    if(e=="A"){
        size = "16px"
    }
    return size;  
}





export {colorRojoNod,MargenesCards};
 //var colorRojoNod = "#ef4125";  