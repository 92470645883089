import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Stories } from "./Pages/Stories/Stories";
import { GalleryImages } from "./Pages/Gallery/GalleryImages";
import { Form } from "./Pages/forms/form";
import "./style.css";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import { GlobalStyle } from "./fonts/fonts";

// -- cargar aqui nuevos trabajos individuales. -------------------

import Work_Individual_Arkitema from "./Pages/Stories/Individual/WorkIndividual_Arkitema";
import Work_Individual_Berilo from "./Pages/Stories/Individual/WorkIndividual_Berilo";
import Work_Individual_FO from "./Pages/Stories/Individual/WorkIndividual_FO";
import Work_Individual_Glamping from "./Pages/Stories/Individual/WorkIndividual_Glamping";
import Work_Individual_GMP from "./Pages/Stories/Individual/WorkIndividual_GMP";
import Work_Individual_Macarena from "./Pages/Stories/Individual/WorkIndividual_Macarena";
import Work_Individual_Numa from "./Pages/Stories/Individual/WorkIndividual_Numa";
import Work_Individual_OP from "./Pages/Stories/Individual/WorkIndividual_OP";
import Work_Individual_Car from "./Pages/Stories/Individual/WorkIndividual_Car";
import Work_Individual_Stills from "./Pages/Stories/Individual/WorkIndividual_Stills";
import Work_Individual_HCMA from "./Pages/Stories/Individual/WorkIndividual_HCMA";
import LowMenuFirst from "./Pages/LowMenu/LowMenuFirst";
import LowMenuSecond from "./Pages/LowMenu/LowMenuSecond";
import LowMenuThird from "./Pages/LowMenu/LowMenuThird";
//import Interiorism from "./Pages/LowMenu/Interiorism";

import { gsap } from "gsap"; // Importar GSAP

//const TRACKING_ID = "G-RBZ079NC5M"; // YOUR_OWN_TRACKING_ID

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <GlobalStyle />
    <HashRouter>
      <Routes>
        <Route path="/" element={<HomeWithAnimation />} />
        <Route path="Stories" element={<StoriesWithAnimation />} />
        <Route path="Form" element={<Form />} />
        <Route path="GalleryImages" element={<GalleryImagesWithAnimation />} />

        {/*path es el nombre de acceso (el link sobre el sitio), y element es el nombre del componente*/}
        <Route path="Work/Arkitema" element={<Work_Individual_Arkitema />} />
        <Route path="Work/Berilo" element={<Work_Individual_Berilo />} />
        <Route path="Work/FO" element={<Work_Individual_FO />} />
        <Route path="Work/Glamping" element={<Work_Individual_Glamping />} />
        <Route path="Work/GMP" element={<Work_Individual_GMP />} />
        <Route path="Work/Macarena" element={<Work_Individual_Macarena />} />
        <Route path="Work/Numa" element={<Work_Individual_Numa />} />
        <Route path="Work/OP" element={<Work_Individual_OP />} />
        <Route path="Work/Car" element={<Work_Individual_Car />} />
        <Route path="Work/Stills" element={<Work_Individual_Stills />} />
        <Route path="Work/HCMA" element={<Work_Individual_HCMA />} />

        <Route path="LowMenuFirst" element={<LowMenuFirst />} />
        <Route path="LowMenuSecond" element={<LowMenuSecond />} />
        <Route path="LowMenuThird" element={<LowMenuThird />} />
        

      </Routes>
    </HashRouter>
  </Provider>
);

function GalleryImagesWithAnimation() {
  // En esta función, configuramos las animaciones de entrada y salida de la página "GalleryImages"
  // utilizando GSAP.

  // Animación de entrada al montar el componente
  React.useEffect(() => {
    gsap.fromTo(".page", { opacity: 0 }, { opacity: 1, duration: 1 });
  }, []);

  // Animación de salida al desmontar el componente
  React.useEffect(() => {
    return () => {
      gsap.to(".page", { opacity: 0, duration: 1 });
    };
  }, []);

  // Renderizamos el componente "GalleryImages" con una clase "page" para aplicar las animaciones.
  return (
    <div className="page">
      <GalleryImages />
    </div>
  );
}

function StoriesWithAnimation() {
  // En esta función, configuramos las animaciones de entrada y salida de la página "GalleryImages"
  // utilizando GSAP.

  // Animación de entrada al montar el componente
  React.useEffect(() => {
    gsap.fromTo(".page2", { opacity: 0 }, { opacity: 1, duration: 1 });
  }, []);

  // Animación de salida al desmontar el componente
  React.useEffect(() => {
    return () => {
      gsap.to(".page2", { opacity: 0, duration: 1 });
    };
  }, []);

  // Renderizamos el componente "GalleryImages" con una clase "page" para aplicar las animaciones.
  return (
    <div className="page2">
      <Stories />
    </div>
  );
}

function HomeWithAnimation() {
  // En esta función, configuramos las animaciones de entrada y salida de la página "GalleryImages"
  // utilizando GSAP.

  // Animación de entrada al montar el componente
  React.useEffect(() => {
    gsap.fromTo(
      ".page2",
      { opacity: 0, y: -50 },
      { opacity: 1, y: 0, duration: 1 }
    );
  }, []);

  // Animación de salida al desmontar el componente
  React.useEffect(() => {
    return () => {
      gsap.to(".page3", { opacity: 0, y: -50, duration: 1 });
    };
  }, []);

  // Renderizamos el componente "GalleryImages" con una clase "page" para aplicar las animaciones.
  return (
    <div className="page3">
      <App />
    </div>
  );
}
