import React from 'react';


import Card from "./Card";


import macarenaa from "../../../img/web/Est/macarenaaBig.jpg";
import macarenab from "../../../img/web/Est/macarenabBig.webp";


const Card_Macarena = () => {
  return (
    <>
                <Card
                Id_Card="Id4"
                Header_Title="LA MACARENA 555."
                Header_SubTitle="RENDERS FOR DEVELOPERS."
                Size_Card="small"
                Footer_Paragraph=" In the most beautiful area of Villa Allende Golf there is a place that is much more 
              than a housing.  A place with plenty of room to grow and fewer expenses that limit you. A place with security 
              for your future and tranquility for your day to day life. That is La Macarena 555. Welcome to the place where 
              life is full of possibilities."
                Image_Src_Jpg={macarenaa}
                Image_Src_Webp={macarenab}
                SetLink="/work/Macarena"
              />
    </>
  );
};
export default Card_Macarena;