// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/woff/Aeroport.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*,\r\n*::after,\r\n*::before {\r\n  margin: 0;\r\n  padding: 0;\r\n \r\n}\r\n\r\nhtml, body {\r\n  width: 100vw;\r\n  margin: 0; \r\n  padding: 0;\r\n}\r\n@media (max-width: 800px) {\r\n  html, body {\r\n  overflow-x: hidden;\r\n  }\r\n}\r\n@media screen and (min-width: 768px) {\r\n  html, body {\r\n    overflow-x: hidden;\r\n  }\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Aeroport';\r\n  src: local('Aeroport'), local('Aeroport'),\r\n  url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\r\n  font-weight: 300;\r\n  font-style: normal;\r\n}", "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;;;EAGE,SAAS;EACT,UAAU;;AAEZ;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;AACA;EACE;EACA,kBAAkB;EAClB;AACF;AACA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,uBAAuB;EACvB;wDACgD;EAChD,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["*,\r\n*::after,\r\n*::before {\r\n  margin: 0;\r\n  padding: 0;\r\n \r\n}\r\n\r\nhtml, body {\r\n  width: 100vw;\r\n  margin: 0; \r\n  padding: 0;\r\n}\r\n@media (max-width: 800px) {\r\n  html, body {\r\n  overflow-x: hidden;\r\n  }\r\n}\r\n@media screen and (min-width: 768px) {\r\n  html, body {\r\n    overflow-x: hidden;\r\n  }\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Aeroport';\r\n  src: local('Aeroport'), local('Aeroport'),\r\n  url(\"./fonts/woff/Aeroport.woff\") format('woff');\r\n  font-weight: 300;\r\n  font-style: normal;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
