import React from 'react';


import Card from "./Card";


import glampinga from "../../../img/web/Est/glampingBig.jpg";
import glampingb from "../../../img/web/Est/glampingBig.webp";

const Card_Glamping = () => {
  return (
    <>
                <Card
                Id_Card="Id2"
                Header_Title="VINES AND LODGES. GLAMPING UNITS"
                Header_SubTitle="RENDERS FOR DEVELOPERS."
                Size_Card="small"
                Footer_Paragraph="Glamping units were created to enjoy the nature that surrounds us, to experience the freedom of a 
              campsite without losing the comfort and warmth of luxury conditioning."
                Image_Src_Jpg={glampinga}
                Image_Src_Webp={glampingb}
                SetLink="/work/Glamping"
              />
    </>
  );
};
export default Card_Glamping;