import React from 'react';


import Card from "./Card";


import numaa from "../../../img/web/Est/numaaBig.jpg";
import numab from "../../../img/web/Est/numabBig.webp";


const Card_Numa = () => {
  return (
    <>
                 <Card
                Id_Card="Id5"
                Header_Title="NUMA.
              RESIDENTIAL
              NEIGHBORHOOD."
                Header_SubTitle="MOVIES FOR DEVELOPERS."
                Size_Card="big"
                Footer_Paragraph="Nuevo Malagueño is a quality development that invites you to inhabit an 
              incredible natural environment and lead a more active, connected and healthy life.70 hectares 
              designed according to the new urban trends that promote interaction between the community to 
              achieve synergy and achieve a better lifestyle."
                Image_Src_Jpg={numaa}
                Image_Src_Webp={numab}
                SetLink="/work/Numa"
              />
    </>
  );
};
export default Card_Numa;