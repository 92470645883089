
import styled from 'styled-components';

const HLine = styled.div`
  width: ${props => props.wSize};
  height: 1px;
  left:${props => props.left};
  background-color: #000000;
  padding-top: 0;
  padding-bottom:0;
  z-index: 100;

 
  @media (max-height: 600px)  {
    display:none;
    background-color: #FFFFFF;
  }

`;

const HLineMinMax = styled.div`

  width: 100%;
  height: 1px;
  padding-top: 0;
  padding-bottom:0;
  z-index: 10;
  margin-bottom:  ${props => props.mBottom};
  margin-top:  ${props => props.mBottom};
  
  display:block  ;
  background-color: #000000;
 @media (min-width: ${props => props.startPx}) and (max-width: ${props => props.endPx})  {
  width:  ${props => props.width};
  left: ${props => props.left};
  left: ${props => props.left};
  margin-left: ${props => props.mLeft};
  //margin-bottom:20px;
  display:block  ;
  background-color: #000000;
 }
 @media (min-width: ${props => props.endPx})   {
  //background-color: #184848;
  display:none  ;
 }
 @media (max-width: ${props => props.startPx})   {
  //background-color: #213123;
  display:none  ;
 }

`;

export {HLine ,HLineMinMax};