import React from 'react';


import Card from "./Card";


import OPointa from "../../../img/web/Est/OPointBig.jpg";
import OPointb from "../../../img/web/Est/OPointBig.webp";

const Card_OP = () => {
  return (
    <>
           <Card
                Id_Card="Id3"
                Header_Title="OBSERVATION POINT."
                Header_SubTitle="RENDERS FOR ARCHITECTS."
                Size_Card="small"
                Footer_Paragraph="On June 2022 a Heatherwick associate contact us for a small personal project. 
              It was a competition proposal for a small observation point in Portugal. "
                Image_Src_Jpg={OPointa}
                Image_Src_Webp={OPointb}
                SetLink="/work/OP"
              />
    </>
  );
};
export default Card_OP;