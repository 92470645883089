import { useRef } from "react";
import reactGA from "react-ga4";

const SpyGA = (Categoria, Accion, Ref) => {
  if (Categoria === "Clicked") {
    SpyOld(Categoria, Accion);
    console.log("Clicked");
  } else if (Categoria === "GoOver") {
    console.log("GoOver");
    const Element = Ref.current;

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    return new Promise((resolve) => {
      const observerCallback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            SpyOld(Categoria, Accion);
            resolve(true);
          } else {
            resolve(false);
          }
        });
      };

      const observer = new IntersectionObserver(
        observerCallback,
        observerOptions
      );

      if (Element) {
        observer.observe(Element);
      }
      return () => {
        if (Element) {
          observer.unobserve(Element); // Dejamos de observar el elemento cuando el componente se desmonta.
        }
      };
    });
  }
};

const SpyOld = (Categoria, Accion) => {
  reactGA.event({
    action: Accion,
    category: Categoria,
  });
};
export default SpyGA;

// const spyRef = useRef(null);

// ref={spyRef}

/*

 useEffect(() => {
    SpyGA_CheckAreaView = (Ref, Categoria, Accion)
  },[]);
  
*/
