import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import gsap from "gsap";
import playBT from "../../../img/puntero/playBt.png";

//-BODYONEPIC-START-------------------------------------------------------------------------------------------------
const CntBodyOnePicsframe = styled.div`
  background-color: red;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-height: 1050px) and (max-width: 480px) {
    width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const CntBodyOnePicsImage1 = styled.div`
  /* background-color: #ef4125; */
  //margin-left: 10vw;
  //margin-right: 10vw;
  padding-left: 20vw;
  padding-right: 20vw;
  //width:auto;
  // height: auto;
  display: grid;
  justify-items: start;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    //margin-right: calc(10%);
   // width: calc(90 - 10%);
   // height: 100%;
   padding-left: 0vw;
  padding-right: 0vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1280px) {
   padding-left: 10vw;
  padding-right: 10vw;
  }
`;

const Image1BodyOnePics = styled.div`
  /* background-color: #ef4125; */
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;

  width: 100%;
  object-fit: contain;
  overflow: hidden;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    width: 100vw;
    object-fit: cover;
  }
  display: grid;
`;
//-BODYONEPIC-END------------------------------------------------------------------------------------------------------------------

const VideoThumbnailMask = styled.div`

  //background-color: red;
  transition: opacity 1s ease;
  opacity: ${props => (props.clicked ? '0.1' : '0.8')};
  height: 100%;
  width: 100%;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  cursor: pointer;
  background: url(${playBT}) center/contain no-repeat rgba(0, 0, 0, 0);
  background-size: 20%;
  
 /* &:hover {
    opacity: 0;
  }*/
`;
const VideoContainer = styled.div`
  display: flex;
  justify-content: start;
  align-content: start;
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  //background-color: gray;
  height: auto;
  //display:grid;
`;

const VimeoVideoLowMenu = ({ url }) => {
  const playerRef = useRef(null);
  const videoWrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const pauseVideo = () => {
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().pause();
    }
  };

  const playVideo = () => {
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().play();
    }
  };

  /*const controlsVideo = () => {
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().;
    }
  };*/



  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (videoWrapperRef.current) {
      observer.observe(videoWrapperRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      gsap.fromTo(
        videoWrapperRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 1, delay: 1 }
      );
    }
    //console.log("play:", playerRef.current.getInternalPlayer().onReady)
  }, [isVisible]);

  /*useEffect(() => {
    if (videoRefs) {
      videoRefs.current[videoId] = {
        playVideo: playVideo,
        pauseVideo: pauseVideo,
      };
    }
  }, [videoRefs, videoId]);*/

  
  const [clicked, setClicked] = useState(false);

/*  const handleClick = () => {
    setShowControls(true);
    setClicked(true);
  };*/


  return (
    <>
      <CntBodyOnePicsImage1>
        <Image1BodyOnePics>
          <VideoContainer>
            <VideoWrapper ref={videoWrapperRef}>
              <ReactPlayer
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                }}
                width="100%"
                height="100%"
                ref={playerRef}
                url={url}
                controls={true} // Actualización aquí
                //muted={fullScreen === true ? false : true}
                //autoPlay={fullScreen === true ? true : false}
                config={{
                  vimeo: {
                    quality: "auto",
                  },
                }}
              />
            </VideoWrapper>
          </VideoContainer>
          {/*<VideoThumbnailMask
            onClick={() => {
              if (clicked) {
                pauseVideo();
              } else {
                playVideo();
              }
              setClicked(!clicked);
            }}
            clicked={clicked} 
          />*/}
        </Image1BodyOnePics>
      </CntBodyOnePicsImage1>
    </>
  );
};

export default VimeoVideoLowMenu;
