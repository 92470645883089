
import styled from 'styled-components';

const AeroportFontCont = styled.div`
//background-color: aquamarine;
 padding-bottom:  ${props => props.pBottom};
 padding-top:  ${props => props.pTop};
 padding-left:  ${props => props.pLeft};
 padding-right:  ${props => props.pRight};
 margin-bottom:  ${props => props.mBottom};
 margin-top:  ${props => props.mTop};
 margin-left:  ${props => props.mLeft};
 margin-right:  ${props => props.mRight};
 font-family: "Aeroport";
 font-weight: ${props => props.weightDefault};
 line-height: ${props => props.lHeightDefault};
 font-size:   ${props => props.sizeDefault};
 color: ${props => props.color};
 list-style:none;

          

 //formula para fuente respo
//calc(50px + 8 * ((100vw - 320px) / 960));
    @media screen and (min-width:0px) and (max-width: 480px) {
        font-weight: ${props => props.weightPhone};
        line-height: ${props => props.lHeightPhone};
        font-size:   ${props => props.sizePhone};
    }
    @media screen and (min-width:481px) and (max-width: 768px) {
        font-weight: ${props => props.weightSmall};
        line-height: ${props => props.lHeightSmall};
        font-size:   ${props => props.sizeSmall};
    }
    @media screen and (min-width:769px) and (max-width: 1279px) {
        font-weight: ${props => props.weightMedium};
        line-height: ${props => props.lHeightMedium};
        font-size:   ${props => props.sizeMedium};
    }
    @media screen and (min-width:1280px){
        font-weight: ${props => props.weightBig};
        line-height: ${props => props.lHeightBig};
        font-size:   ${props => props.sizeBig};  
    }
 
`;

export {AeroportFontCont};