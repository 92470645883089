import React from 'react';

import Card from "./Card";


import arkitemaa from "../../../img/web/Est/arkitemaBig.jpg";
import arkitemab from "../../../img/web/Est/arkitemaBig.webp";

const Card_Akitema = () => {
  return (
    <>
              <Card
                Id_Card="Id1"
                Header_Title="HOW TO GET YOUR RENDERS FAST."
                Header_SubTitle="RENDERS FOR ARCHITECTS."
                Size_Card="small"
                Footer_Paragraph="On February 2021 Arkitema offer us to collaborate with his team for the 
              “Barkabystaden Residential Towers” competition.              
              The project was a multi-tower residential complex in Sweden for which they needed 3 renders in 3 working days.              
              The scope:  • Model of the environment, • 9 camera options,  and • 3 final images."
                Image_Src_Jpg={arkitemaa}
                Image_Src_Webp={arkitemab}
                SetLink="/work/Arkitema"
              />
    </>
  );
};
export default Card_Akitema;