import React from "react";


import Card from "./Card";

import HCMAa from "../../../img/web/Est/HCMAaBig.jpg";
import HCMAb from "../../../img/web/Est/HCMAbBig.webp";

const Card_HCMA = () => {
  return (
    <>
      <Card
        Id_Card="Id8"
        Header_Title="COMMUNITY RECREATION CENTRE."
        Header_SubTitle="MOVIES FOR ARCHITECTS."
        Size_Card="small"
        Footer_Paragraph="                      The new HJCRC embraces the City’s vision of A Healthy City
                for All, creating a welcoming, vibrant, social heart of
                the community. Focused on physical and mental health,
                wellness, inclusion and diversity, the new HJCRC will
                create a barrier-free, accessible space for everyone to
                enjoy."
        Image_Src_Jpg={HCMAa}
        Image_Src_Webp={HCMAb}
        SetLink="/work/HCMA"
      />
    </>
  );
};
export default Card_HCMA;
