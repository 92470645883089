import React from 'react';

import Card from "./Card";


import foa from "../../../img/web/Est/foaBig.jpg";
import fob from "../../../img/web/Est/fobBig.webp";


const Card_FO = () => {
  return (
    <>
            <Card
                Id_Card="Id7"
                Header_Title="MADRID NORTE CENTRAL PARK."
                Header_SubTitle="RENDERS FOR ARCHITECTS."
                Size_Card="big"
                Footer_Paragraph="On March 2022 James Corner Field Operations offer us to collaborate with his team 
              for the “Parque Central de Madrid Nuevo Norte” competition.
              When we looked at the scale of the project, we were a little taken back. They need us to create mostly 
              aerial views that showed a huge portion of Madrid skyline."
                Image_Src_Jpg={foa}
                Image_Src_Webp={fob}
                SetLink="/work/FO"
              />
    </>
  );
};
export default Card_FO;