import React from 'react';
import Card from "./Card";

import beriloa from "../../../img/web/Est/BeriloABig.jpg";
import berilob from "../../../img/web/Est/BeriloBBig.webp";

const Card_Berilo = () => {
  return (
    <>
              <Card
                Id_Card="id1"
                Header_Title="BERILO ASUNCION. HOTEL CONDOMINIUM"
                Header_SubTitle="RENDERS FOR DEVELOPERS."
                Size_Card="big"
                Footer_Paragraph="With an area of ​nearly 40,000 m² and 145 tall, the Torre Berilo is a project 
              of Grupo Urban Architecture, projected to be one of the tallest buildings in the region. 
              The complex is composed of basement, ground floor, seven floors of parking and two distinguishable 
              towers: one intended for housing and another forms a hotel."
                Image_Src_Jpg={beriloa}
                Image_Src_Webp={berilob}
                SetLink="/work/Berilo"
              />
    </>
  );
};
export default Card_Berilo;