import { createSlice } from '@reduxjs/toolkit'

export const InPage = createSlice({


  name: 'inPageHome',
  initialState: {
    value: "in",
  },
  reducers: {
    InPageHome: (state) => {
      state.value = "in"
      
    },
    NotInPageHome: (state) => {
      state.value = "out" 
    },
  },




})


export const { InPageHome,NotInPageHome} = InPage.actions

export default InPage.reducer
