import React, { useState, useRef, useEffect  } from 'react';
import styled from "styled-components";
import { gsap } from "gsap"; // Importa gsap

// Define un componente estilizado para una imagen en la galería.
const GalleryImage = styled.img`
  width: 100%;
  height: auto;
`;

// Define un componente funcional LazyGalleryImage que implementa la carga perezosa de imágenes.
const LazyGalleryImage = ({ src, alt, onClick }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      gsap.fromTo(
        imageRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 1, delay: 1 }
      );
    }
  }, [isVisible]);

  
  return (
    <div ref={imageRef} onClick={onClick}>
      {isVisible ? (
        <GalleryImage
          style={{  opacity: "1", cursor: "pointer" }}
          src={src}
          alt={alt}
        />
      ) : (
        <div style={{ height: "200px" }}></div>
      )}
    </div>
  );
};

//----------------------------------------------------------------------------------------------------

export { LazyGalleryImage };