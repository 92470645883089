//prueba
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Burger from "../../../components/Nav/Burger.jsx";

import Footer from "../../Home/Footer";

import useMediaQuery from '../../../hooks/MediaQueryhook';

import { AeroportFontCont } from "../../../fonts/AeroportFont_Cont.js";
import { HLineMinMax } from "../../../components/HLine.js";

import punteroArrowLeft from "../../../img/puntero/LeftArrow.png"
import punteroArrowRight from "../../../img/puntero/RightArrow.png"

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Navigation } from "swiper";

import "swiper/css/free-mode";
import "swiper/css/pagination";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';


import "./SwiperCss.css";


import { ImageWebp } from "../../../components/ImageWebp.js"

import Card_Stills from "../Cards/CardInidividual_Stills.js";//dev
import Card_Berilo from "../Cards/CardInidividual_Berilo.js"; // dev
import Card_Glamping from "../Cards/CardInidividual_Glamping.js";//dev

import reactGA from "react-ga4"


import HeaderPic from "../WorksComponents/HeaderPic.js";
import OneVideo from  "../WorksComponents/OneVideo.js";

import Spy from "../../../Spy.js";

import OneVideoVimeo from "../WorksComponents/OneVideoVimeo.js";


//-------------- styled Components abajo-------------------

//-CONTENEDOR PRINCIPAL (CntFrame) -START-------------------------------------------------------------------------------------
const CntNavBar = styled.div`
  //background-color: #EEEEEE;

  z-index: 50;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  position: fixed;
  justify-items: center;
  top: 0;
  right: 0;
  height: 5%;
  width: 50vw;
  justify-self: stretch;
  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 100vw;
    grid-template-columns: 1rem 1rem auto 1rem 80px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    width: 100vw;
    grid-template-columns: 2.5rem 1vw auto 1vw 100px;
  }
  @media screen and (min-width: 769px) {
    grid-template-columns: 2.5vw 1vw auto 1vw 120px;
  }

`;

const CntFrame = styled.div`
  width: 100vw;
  background-color: #EEEEEE;
  color: black;
  //opacity: 0.3;
`;

//-CONTENEDOR PRINCIPAL (CntFrame) -END----------------------------------------------------------------------------------------



//-HEADER-START----------------------------------------------------------------------------------------------------------------
const CntHeaderFrame = styled.div`

  width: 100%; //auto;º
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #cfcfcf;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    align-items: start;
    justify-content: start;
    height:100vh;
  }
  @media screen and (min-width: 769px) {
    padding-top: 120px;
  }
  @media (max-height: 1050px) and (max-width: 480px) {
    width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;


const CntHeaderInside = styled.div`
  //background-color: blueviolet  ;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  top: 0px;
  left: 0px;
  margin-right: 10vw;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows:  1fr auto ;
    width: 100vw;
    height:100vh;
  }
  @media screen and (min-width: 769px) and (max-width: 1279px) {
    grid-template-columns: 55% 45%;
  }
  @media screen and (min-width: 1280px) {
    grid-template-columns: 65% 35%;
  }
`;

const CntHeaderTxTSuperScreen_S_P = styled.div`
  display: grid;
  align-items: end;
  
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  height:auto;
  @media screen and (min-width: 769px)  {
    display: none;
  }
  
`;

const CntHeaderExtra = styled.div`

  flex-direction: column;
  width: fit-content;
  margin-left: 5vw;
  margin-bottom: 5vw;
`;

const CntHeaderTxTSuperScreen_M_B = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    display: none;
  }

`;

const CntHeaderTxTinferior = styled.div`

  justify-self: end;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: fit-content;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-top: 5vw;
    margin-bottom: 10vw;
  }
`;

const CntHeaderTxt = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  margin: 0;
  display: grid;

  @media screen and (min-width: 769px)  {
    padding-left: 2.5vw;
    padding-top: 2.2vw;
  }
  @media screen and (min-width: 0px) and (max-width: 768px) {
      display:none;
  }

`;

const CntHeaderParrafo = styled.div`
  line-height: 0.5;
  width: 100%;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    width: calc(100% - 10vw);
    margin-left: 5vw;
  }
  @media screen and (min-width:1279px) and (max-width: 1570px) {
    div{
      font-size: 15px;
    }
  }
  @media screen and (min-width:1279px) and (max-width: 1340px) {
    div{
      font-size: 13px;
    }
  }
`;

const CntHeaderTitle = styled.div`
  margin-left: -0.08em;
  @media screen and (min-width:1279px) and (max-width: 1500px) {
    font-size: 80px;
  }
  @media screen and (min-width:769px) and (max-width: 830px) {
    font-size: 40px;
  }
  @media screen and (min-width: 0px) and  (max-width: 768px) {
    margin-right: 5vw;
  }
`;

const CntTextoHeaderPhone = styled.div`
  opacity: 1;
  width: 100vw;
  height: fit-content;
  background-color: #cfcfcf;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    display:none;
  }
`;
//-HEADER-END---------------------------------------------------------------------------------------------------------



//-TEXTBODY_1-START---------------------------------------------------------------------------------------------------
const CntTextBody_1Frame = styled.div`
  opacity: 1;
  width: 100vw;
  height: fit-content;
  background-color: #eeeeee;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;  
  align-content: center;
`;




const CntTextBody_1TxtParf = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 5%;
  margin-bottom: 5%;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 10vw;
    margin-bottom: 10vw;
    width: calc(100vw - 10vw);
  }
`;
//-TEXTBODY_1-END---------------------------------------------------------------------------------------------------



//-BODYTWOPICS-START------------------------------------------------------------------------------------------------

//-TEXTBODY_2-START---------------------------------------------------------------------------------------------------
const CntTextBody_2Frame = styled.div`
  opacity: 1;
  width: 100vw;
  height: fit-content;
  background-color: #eeeeee;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const CntTextBody_2TxtParf = styled.div`
  justify-self: start;
  align-self: start;
  margin-left:10vw;
  margin-right: 10vw;
  margin-top: 5%;
  margin-bottom: 5%;
  @media screen and (min-width: 0px) and  (max-width: 768px) {
    margin-left: 5vw;
    margin-right:5vw;
    margin-top: 20vw;
    margin-bottom: 20vw;

  }
`;
//-TEXTBODY_2-END---------------------------------------------------------------------------------------------------





//-TEXTBODY_3-START---------------------------------------------------------------------------------------------------
const CntTextBody_3Frame = styled.div`

  opacity: 1;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 25vw;
  width: 50vw;
  height: fit-content;
  background-color: #eeeeee;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: 0px) and  (max-width: 768px) {
    
    padding: 4vh 0 4vh 0;
  }
`;

const CntTextBody_3TxtParf1 = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 0%;
  @media screen and (min-width: 0px) and  (max-width: 768px) {
    margin: 5vw;
    margin-top: 10vw;
    width: calc(100vw - 10vw);
  }
`;

const CntTextBody_3TxtParf2 = styled.div`
  justify-self: start;
  align-self: start;
  margin-left: 10vw;
  margin-top: 2%;
  margin-right: 10vw;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin: 5vw;
    margin-bottom: 10vw;
    width: calc(100vw - 10vw);
  }
`;
//-TEXTBODY_3-END---------------------------------------------------------------------------------------------------



//-CARROUSEL-START--------------------------------------------------------------------------------------------------
const CntCarrouselFrame = styled.div`

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
 // background-color: red;
  padding-top: 10px;
  //margin-top: 80px;
  width: 100vw;
  height: auto;
  //margin-bottom: 80px;
  margin-left: 10vw;
  //cursor: url( ${props => props.cursorFlecha}),auto;

  @media screen and (min-width:0px) and (max-width: 768px) {
    margin-left: 0vw;  
  }
`;

const ImagenCarrousel = styled.div`
  //background-color: blueviolet;
  background-size: cover;
  object-fit: cover;
  height: 45vw;
  width: 80vw;
  @media screen and (min-width:0px) and (max-width: 768px) {
    object-fit: contain;
    height: 50vw;
    width: 100vw;     
  }

`;

const DivPointLeft = styled.div`
  position: absolute;
  cursor: url( ${punteroArrowLeft}),auto;
  opacity: 0.5;
  //background-color: red;
  width: 20vw;
  height:42vw; 
  left:0px;
  z-index: 300;
`;

const DivPointRight = styled.div`
  position: absolute;
  cursor: url( ${punteroArrowRight}),auto;
  opacity: 0.5;
  //background-color: red;
  width: 20vw;
  height:42vw;  
  right:0px;
  z-index: 300;
`;

//-CARROUSEL-END--------------------------------------------------------------------------------------------------



//-MORESTORIES-START--------------------------------------------------------------------------------------------------

const CntMSService = styled.div`
  padding-top: 10px;
  width: 100vw;
  height: auto;
  background-color: #eeeeee;
  margin-bottom: 80px;
 `;



const SliderMS = styled.div`

  width: 100vw;
  height: auto;
  overflow-x: visible;
  grid-auto-flow: column;
  overflow-y: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

`;

const CntMSTxtTitle = styled.div`
  a {
    //quita el espacio izq de la primera letra
    margin-left: -0.04em;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 40px;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding-left: 5vw;
    padding-bottom:5vw;
  }
  @media screen and (min-width: 769px)  {
    padding-left: 2.5vw;
    padding-bottom:2.5vw;
  }

`;

const CntMSImgGeneral = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100vw - 17.5px);
  
  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: calc(100vw);
  } 
  @media screen and (min-width:0px) and (max-width: 315px) {
    width: calc(200vw - 17.5px);
  }
  @media screen and (min-width:316px) and (max-width: 480px) {
    width: calc(220vw - 17.5px);
  }
  @media screen and (min-width:481px) and (max-width: 939px) {
    width: calc(180vw - 17.5px);
  }
  @media screen and (min-width:940px) and (max-width: 979px) {
    width: calc(120vw - 17.5px);
  }
  @media screen and (min-width:980px) {
    width: calc(100vw - 17.5px); 
  }
  
`;

//-MORESTORIES-END--------------------------------------------------------------------------------------------------




// import de Imagenes------------------------------------------
import image_1a from "../../../img/Work/Macarena/Macarena_1Big.jpg";
import image_1b from "../../../img/Work/Macarena/Macarena_1Big.webp";


import image_2a from "../../../img/Work/Macarena/Macarena_2Big.jpg";
import image_2b from "../../../img/Work/Macarena/Macarena_2Big.webp";

import image_3a from "../../../img/Work/Macarena/Macarena_3Big.jpg";
import image_3b from "../../../img/Work/Macarena/Macarena_3Big.webp";


import image_4a from "../../../img/Work/Macarena/Macarena_4Big.jpg";
import image_4b from "../../../img/Work/Macarena/Macarena_4Big.webp";

import image_5a from "../../../img/Work/Macarena/Macarena_5Big.jpg";
import image_5b from "../../../img/work/Macarena/Macarena_5Big.webp";

import image_6a from "../../../img/Work/Macarena/Macarena_6Big.jpg";
import image_6b from "../../../img/work/Macarena/Macarena_6Big.webp";

import image_7a from "../../../img/Work/Macarena/Macarena_7Big.jpg";
import image_7b from "../../../img/work/Macarena/Macarena_7Big.webp";

import image_8a from "../../../img/Work/Macarena/Macarena_8Big.jpg";
import image_8b from "../../../img/work/Macarena/Macarena_8Big.webp";

import image_9a from "../../../img/Work/Macarena/Macarena_9Big.jpg";
import image_9b from "../../../img/work/Macarena/Macarena_9Big.webp";


//-------------------------------------------------------------


const Work_Individual_Macarena = () => {
  Spy("OpenPages","MacarenaWorkAccess")


  /*--- A TENER EN CUENTA ---

  imagenes se cargan mas arriba por variable.s
  los textos se escriben igual que en una plantilla HTML

  el componente : <AeroportFontCont>
  es una caja de texto donde ya lleva las mediaQuerys 
  incluidas para modificar el tamaño de letra y el espaciado vertical.
  asi mismo si uno hace un DIV hijo puede agregar otro tamaño de letra con una mediaQuery nueva.
                  
                  sizePhone=  (min-width:0px) and (max-width: 480px) 
                  sizeSmall=  (min-width:481px) and (max-width: 768px) 
                  sizeMedium= (min-width:769px) and (max-width: 1279px) 
                  sizeBig=    (min-width:1280px)

                  lHeightPhone= igual que para el size
                  lHeightSmall=
                  lHeightMedium=
                  lHeightBig=
  --------------------------------------- */
  document.body.scrollTop = document.documentElement.scrollTop = 0;

  const cardAnimRef = useRef(null);
  const cntCarrouselFrame_Ref = useRef(null);
  const isMediaQueryMayor769px = useMediaQuery('(min-width: 769px)');

  let imagenCursor = punteroArrowLeft;
  //punteroArrowLeft 
  //punteroArrowRight 

  useEffect(() => {
    //carrousel MORE STORIES START-----------------------------------------------------------------
    //drag & move 
    const slider = document.getElementById('sliderMoreStories');
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      slider.style.userSelect = 'none';
      isDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener('mouseleave', () => {
      isDown = false;
    });

    slider.addEventListener('mouseup', () => {
      isDown = false;
    });

    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
    //carrousel MORE STORIES END-----------------------------------------------------------------
  }, []);

  //SWIPER-START-------------------------------------------------------------------------------
  const SwLeft = (num) => {
    const swiper = document.getElementById("Swiperid"+num).swiper;
    swiper.slideNext();
  };

  const SwRight = (num) => {
    const swiper = document.getElementById("Swiperid"+num).swiper;
    swiper.slidePrev();
  };

  let swiperCant = 1;
  //marca la cantidad de imagenes en el swiper que va a haber segun el mediaQuery
  isMediaQueryMayor769px ? swiperCant = 1.1 : swiperCant = 1;
  //SWIPER-END-------------------------------------------------------------------------------



  //funcion para quiebre de linea-----------------------------------------------------------------
  const addLineBreaks = string =>
    string.split('\n').map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));
  //-----------------------------------------------------------------------------------------


  return (
    <>
      <CntFrame>
        <CntNavBar id="boton">
          <Burger />
        </CntNavBar>

        {/*-HEADER-START----------------------------------------------------------------------------*/}
        <CntHeaderFrame id="Header">
          <CntHeaderInside>

            {/*------- ESTO SOLO APARECE EN WIDTH < 769 -----------------*/}
            {/*SE HIZO PARA PODER INTERCALAR TEXTO DE ABAJO HACIA ARRIBA */}

            <CntHeaderTxTSuperScreen_S_P>
              <CntHeaderExtra>
                <AeroportFontCont sizePhone="45px" sizeSmall="80px" lHeightDefault="1">

                  <CntHeaderTitle>
                    <a>LA MACARENA 555.<br /><br /> VILLAGE.
                    </a>
                  </CntHeaderTitle>
                </AeroportFontCont>

                <AeroportFontCont sizePhone="12px" sizeSmall="16px" lHeightPhone="1.5" lHeightSmall="1.8">
                  <p>RENDERS FOR REALSTATE.</p>
                </AeroportFontCont>
              </CntHeaderExtra>
            </CntHeaderTxTSuperScreen_S_P>
            {/*--------------------------------------------------------*/}

            <HeaderPic imgWebpBig={image_1a} imgAltBig={image_1b} altTxt1={"texto alternativo"}/>

            {/*------- ESTO SOLO APARECE EN WIDTH > 770 ----------------*/}
            <CntHeaderTxt>
              <CntHeaderTxTSuperScreen_M_B>
                <AeroportFontCont sizeMedium="10px" sizeBig="16px" lHeightMedium="1.8" lHeightBig="2.6">
                  <p>RENDERS FOR REALSTATE.</p>
                </AeroportFontCont>
                <AeroportFontCont sizeMedium="40px" sizeBig="80px" lHeightMedium="0.8" lHeightBig="0.8">
                  <CntHeaderTitle>
                    <a>LA MACARENA 555.<br /> VILLAGE.
                    </a>
                  </CntHeaderTitle>
                </AeroportFontCont>

              </CntHeaderTxTSuperScreen_M_B>
              <CntHeaderTxTinferior>
                <CntHeaderParrafo>
                  <HLineMinMax startPx="100px" endPx="3000px" width="calc(100%)" left="0px" mBottom="" mtop="0" />
                  <AeroportFontCont sizeMedium="14px" sizeBig="18px" lHeightMedium="1" lHeightBig="1.2" style={{ marginTop: "2.5vw" }}>
                    <p>
                      In the most beautiful area of Villa Allende Golf there is a place that is much more than a housing.
                      <br /><br />
                      A place with plenty of room to grow and fewer expenses that limit you. A place with security for your future and tranquility for your day to day life. That is La Macarena 555. Welcome to the place where life is full of possibilities.
                    </p>
                  </AeroportFontCont>
                </CntHeaderParrafo>
              </CntHeaderTxTinferior>
            </CntHeaderTxt>
          </CntHeaderInside>
        </CntHeaderFrame>
        {/*----------------------------------------------------------*/}

        {/*------- ESTO SOLO APARECE EN WIDTH < 769 -----------------*/}
        <CntTextoHeaderPhone>
          <CntHeaderTxTinferior>
            <CntHeaderParrafo>
              <AeroportFontCont sizePhone="14px" sizeSmall="14px" lHeightPhone="1" lHeightSmall="0.9" style={{ marginTop: "2.5vw" }}>
                <p>
                  In the most beautiful area of Villa Allende Golf there is a place that is much more than a housing.
                  <br /><br />
                  A place with plenty of room to grow and fewer expenses that limit you. A place with security for your future and tranquility for your day to day life. That is La Macarena 555. Welcome to the place where life is full of possibilities.
                </p>
              </AeroportFontCont>
            </CntHeaderParrafo>
          </CntHeaderTxTinferior>
        </CntTextoHeaderPhone>
        {/*----------------------------------------------------------*/}
        {/*-HEADER-END----------------------------------------------------------------------------*/}



        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3Frame>

          <CntTextBody_3TxtParf2>
            <AeroportFontCont
              sizePhone="14px"
              sizeSmall="14px"
              sizeMedium="14px"
              sizeBig="18px"
              lHeightPhone="1"
              lHeightSmall="0.9"
              lHeightMedium="1"
              lHeightBig="1.2"
            >
              <p>
                We developed a virtual tour with a customizable menu to be able to buy the two types of houses. In the following links you can find the <a href='https://www.estudionod.com/lamacarena/' style={{ color: 'black' }}>Virtual Tour</a> and <a href='https://lamacarena555.com.ar/' style={{ color: 'black' }}>Website.</a> </p>
            </AeroportFontCont>
          </CntTextBody_3TxtParf2>
        </CntTextBody_3Frame>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}
        <CntCarrouselFrame ref={cntCarrouselFrame_Ref}>
          <DivPointLeft onClick={() => SwLeft("1")}/>
          <DivPointRight onClick={() => SwRight("1")}/>
          <CntCarrouselFrame />
        </CntCarrouselFrame>

        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          slidesPerView={swiperCant}
          spaceBetween={1}
          loop={true}
          rewind={true}
          centeredSlides={true}
          className="mySwiper"
          id="Swiperid1"
          grabCursor={true}
        >

          <SwiperSlide>
            <ImagenCarrousel>
              <ImageWebp webPBigSrc={image_5a} imgAltBigSrc={image_5b} />
            </ImagenCarrousel>
          </SwiperSlide>
          <SwiperSlide>
            <ImagenCarrousel>
              <ImageWebp webPBigSrc={image_3a} imgAltBigSrc={image_3b} />
            </ImagenCarrousel>
          </SwiperSlide>
          <SwiperSlide>
            <ImagenCarrousel>
              <ImageWebp webPBigSrc={image_4a} imgAltBigSrc={image_4b} />
            </ImagenCarrousel>
          </SwiperSlide>
          <SwiperSlide>
            <ImagenCarrousel>
              <ImageWebp webPBigSrc={image_2a} imgAltBigSrc={image_2b} />
            </ImagenCarrousel>
          </SwiperSlide>

        </Swiper>


        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3Frame>
        </CntTextBody_3Frame>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}
    <CntCarrouselFrame ref={cntCarrouselFrame_Ref}>
          <DivPointLeft onClick={() => SwLeft("2")}/>
          <DivPointRight onClick={() => SwRight("2")}/>
          <CntCarrouselFrame />
        </CntCarrouselFrame>

        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          slidesPerView={swiperCant}
          spaceBetween={1}
          loop={true}
          rewind={true}
          centeredSlides={true}
          className="mySwiper"
          id="Swiperid2"
          grabCursor={true}
        >

          <SwiperSlide>
            <ImagenCarrousel>
              <ImageWebp webPBigSrc={image_9a} imgAltBigSrc={image_9b} />
            </ImagenCarrousel>
          </SwiperSlide>
          <SwiperSlide>
            <ImagenCarrousel>
              <ImageWebp webPBigSrc={image_6a} imgAltBigSrc={image_6b} />
            </ImagenCarrousel>
          </SwiperSlide>
          <SwiperSlide>
            <ImagenCarrousel>
              <ImageWebp webPBigSrc={image_7a} imgAltBigSrc={image_7b} />
            </ImagenCarrousel>
          </SwiperSlide>
          <SwiperSlide>
            <ImagenCarrousel>
              <ImageWebp webPBigSrc={image_8a} imgAltBigSrc={image_8b} />
            </ImagenCarrousel>
          </SwiperSlide>

        </Swiper>


        {/*-TEXTBODY_3-START----------------------------------------------------------------------------*/}
        <CntTextBody_3Frame>
        </CntTextBody_3Frame>
        {/*-TEXTBODY_3-END----------------------------------------------------------------------------*/}


        {/*-MORESTORIES-START----------------------------------------------------------------------------*/}
        <CntMSService>
          <CntMSTxtTitle>
            <AeroportFontCont
              sizePhone="55px"
              sizeSmall="85px"
              sizeMedium="55px"
              sizeBig="110px"
              lHeightDefault="1"
              pTop="30px"
            >
              <a>More Stories</a>
            </AeroportFontCont>
          </CntMSTxtTitle>
          <SliderMS id="sliderMoreStories">
            <CntMSImgGeneral ref={cardAnimRef}>
              <Card_Stills />
              <Card_Glamping />
              <Card_Berilo />
            </CntMSImgGeneral>
          </SliderMS>
        </CntMSService>
        {/*-MORESTORIES-END----------------------------------------------------------------------------*/}

        <Footer />



      </CntFrame>
    </>
  );
};

export default Work_Individual_Macarena;
