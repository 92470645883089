import reactGA from "react-ga4"


const Spy = (Categoria,Accion) =>{
reactGA.event({
    action:Accion,
    category:Categoria,
  })

}
export default Spy;