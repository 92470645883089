import styled from 'styled-components';
import { ImageWebp } from '../../../components/ImageWebp';

//-BODYONEPIC-START-------------------------------------------------------------------------------------------------
const CntBodyOnePicsframe = styled.div`
  width: 100%;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-height: 1050px) and (max-width: 480px) {
    width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const CntBodyOnePicsImage1 = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  margin-left: 10vw;
  margin-right: 10vw;
  height: 100%;
  display: grid;
  justify-items: start;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin-right: calc(10%);
    width: calc(90 - 10%);
    height: 100%;
  }
`;

const Image1BodyOnePics = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 100%;
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    width: 100vw;
    object-fit: cover;
  }
`;
//-BODYONEPIC-END------------------------------------------------------------------------------------------------------------------


function OnePic(props) {

    return (
        <>      
        <CntBodyOnePicsframe>
          <CntBodyOnePicsImage1>
            <Image1BodyOnePics>
                <ImageWebp webPBigSrc={props.imgWebpBig} imgAltBigSrc={props.imgAltBig} />
            </Image1BodyOnePics>
          </CntBodyOnePicsImage1>
        </CntBodyOnePicsframe>

        </>
    );

}



export  default  OnePic ;


