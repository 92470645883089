//prueba
import React from "react";
import styled from "styled-components";
import { AeroportFontCont } from "../../fonts/AeroportFont_Cont";
import Burger from "../../components/Nav/Burger.jsx";
import gsap from "gsap";
import { useState, useEffect, useRef } from "react";
import Footer from "../Home/Footer";
import Card_Berilo from "./Cards/CardInidividual_Berilo";
import Card_Akitema from "./Cards/CardInidividual_Akitema";
import Card_Glamping from "./Cards/CardInidividual_Glamping";
import Card_OP from "./Cards/CardInidividual_OP";
import Card_Macarena from "./Cards/CardInidividual_Macarena";
import Card_Numa from "./Cards/CardInidividual_Numa";
import Card_FO from "./Cards/CardInidividual_FO";
import Card_GMP from "./Cards/CardInidividual_GMP";
import Card_Car from "./Cards/CardInidividual_Car";
import Card_Stills from "./Cards/CardInidividual_Stills";
import Card_HCMA from "./Cards/CardInidividual_HCMA";

import useMediaQuery from "../../hooks/MediaQueryhook";

import { useDispatch, useSelector } from "react-redux";
import { DesactivadoFiltroArchitects } from "../../redux/Filter_Architects";
import { DesactivadoFiltroDevelopers } from "../../redux/Filter_Developers";

import SpyGA from "../../Observer.js";


import { ImageWebp } from "../../components/ImageWebp.js";
import beriloa from "../../img/web/Est/BeriloABig.jpg";
import berilob from "../../img/web/Est/BeriloBBig.webp";

import cara from "../../img/web/Est/caraBig.jpg";
import carb from "../../img/web/Est/carbBig.webp";

import { MargenesCards } from "../../VariablesGlobales.js";

const varMargenBig = MargenesCards("A");
const varMargenSmall = MargenesCards("B");

const Imagen1 = styled.div`
  width: calc(100% - 20px);
  height: auto;
  overflow: hidden;
  object-fit: contain;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin: ${varMargenSmall} ${varMargenSmall};
  }
  @media screen and (min-width: 769px) {
    margin: ${varMargenBig} ${varMargenBig};
  }
`;

const Imagen2 = styled.div`
  width: calc(50% - 10px);
  height: auto;
  overflow: hidden;
  object-fit: contain;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    margin: ${varMargenSmall} ${varMargenSmall};
  }
  @media screen and (min-width: 769px) {
    margin: ${varMargenBig} ${varMargenBig};
  }
`;

const ContenedorImagenAUXiliar = styled.div`
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -2;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    width: 100vw;
  }
  @media screen and (min-width: 769px) {
    display: none;
    width: 100vw;
  }
`;

const ContainerNavBar = styled.div`
  z-index: 50;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  position: fixed;
  justify-items: center;
  top: 0;
  right: 0;
  height: 5%;
  width: 50vw;
  justify-self: stretch;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 100vw;
    grid-template-columns: 1rem 1rem auto 1rem 80px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    width: 100vw;
    grid-template-columns: 2.5rem 1vw auto 1vw 100px;
  }
  @media screen and (min-width: 769px) {
    grid-template-columns: 2.5vw 1vw auto 1vw 120px;
  }
`;

const ContainerService = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100vw;
  background-color: #eeeeee;
`;

const ConTxT = styled.div`
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding-left: 5vw;
    padding-bottom: 5vw;
  }
  @media screen and (min-width: 769px) {
    padding-left: 2.5vw;
    padding-bottom: 2.5vw;
  }
`;

const ContTxtTitle = styled.div`
  a {
    //quita el espacio izq de la primera letra
    margin-left: -0.04em;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    height: 100%;
  }
`;

const ContImgGeneral = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: calc(100vw - 17.5px);
  height: 100%;

  @media screen and (min-width: 0px) and (max-width: 768px) {
    width: calc(100vw);
  }
  margin-bottom: 80px;
`;

const Filtros = styled.div`
  cursor: default;
  a:hover {
    color: #ef4125;
  }
`;

const MaxCont = styled.div`
  opacity: 1;
  background-color: #eeeeee;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
`;

const Stories = () => {
  

  

  document.body.scrollTop = document.documentElement.scrollTop = 0;

  var isMediaQuery769px = useMediaQuery("(max-width: 769px)");

  const [All, setAll] = useState(true);
  const [renders, setStateRenders] = useState(true);
  const [movies, setStateMovies] = useState(true);
  const [webVr, setStateWebVr] = useState(true);
  const [architects, setStateArchitects] = useState(true);
  const [developers, setStateDevelopers] = useState(true);

 
  const [firstFilterCardSize, setFirstFilterCardSize] = useState("G");

  //redux - globales
  let ArchitectsEstadoRedux = useSelector(
    (state) => state.architectsEstado.value
  );
  let DevelopersEstadoRedux = useSelector(
    (state) => state.developersEstado.value
  );
  const dispatch = useDispatch();

  const Reset = () => {
    setAll(true);
    setStateRenders(true);
    setStateMovies(true);
    setStateWebVr(true);
    setStateArchitects(true);
    setStateDevelopers(true);
    document.getElementById("idALL").style.color = "#ef4125";
    document.getElementById("idRENDERS").style.color = "black";
    document.getElementById("idMOVIES").style.color = "black";
    document.getElementById("idWEBVR").style.color = "black";
    document.getElementById("idARCHITECTS").style.color = "black";
    document.getElementById("idDEVELOPERS").style.color = "black";
  };

  const AllOff = () => {
    setAll(false);
    setStateRenders(false);
    setStateMovies(false);
    setStateWebVr(false);
    setStateArchitects(false);
    setStateDevelopers(false);
    document.getElementById("idALL").style.color = "black";
  };

  const TextColor = (rojo) => {
    document.getElementById("idALL").style.color = "black";
    document.getElementById("idRENDERS").style.color = "black";
    document.getElementById("idMOVIES").style.color = "black";
    document.getElementById("idWEBVR").style.color = "black";
    document.getElementById("idARCHITECTS").style.color = "black";
    document.getElementById("idDEVELOPERS").style.color = "black";
    document.getElementById(rojo).style.color = "#ef4125";
  };

  const Anima = () => {
    gsap.fromTo(
      cardAnimRef.current,
      {
        duration: 1,
        translateY: +2000,
        ease: "Expo.easeOut",
        opacity: 0,
      },
      {
        duration: 1,
        translateY: 0,
        ease: "Expo.easeOut",
        opacity: 1,
      }
    );
  };

  const ContTxtTitleRef = useRef(null);
  const cardAnimRef = useRef(null);

  const FirstImg = useRef(null);
  const SecondImg = useRef(null);
 

  useEffect(() => {
   
    // filtros establecidos desde afuera cuando se ingresa a la pagina desde el footer - desde secciones o desde el menu filtrado
    // utilizando redux y con dos estados globales

    if (ArchitectsEstadoRedux == true) {
      AllOff();
      setStateArchitects(true);
      TextColor("idARCHITECTS");
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    } else if (DevelopersEstadoRedux == true) {
      AllOff();
      setStateDevelopers(true);
      TextColor("idDEVELOPERS");
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  });


  





  return (
    <>
     { /* usado para tomar altura de imagenes. */}
      <ContenedorImagenAUXiliar id="contenedorImg">
        <Imagen1 ref={FirstImg}>
          <ImageWebp webPBigSrc={beriloa} imgAltBigSrc={berilob} />
        </Imagen1>
        <Imagen2 ref={SecondImg}>
          <ImageWebp webPBigSrc={cara} imgAltBigSrc={carb} />
        </Imagen2>
      </ContenedorImagenAUXiliar>
     {/*---------------------------------------*/}

      <MaxCont>
        <ContainerService
          id="cServ"
          style={{
            ...(isMediaQuery769px
              ? {
                  height: `calc( ${window.innerHeight}px - ${
                    firstFilterCardSize == "G" ? 
                    FirstImg.current.clientHeight + 20 : 
                    SecondImg.current.clientHeight + 8}px )`,
                }
              : {}),
          }}
        >
          <ContainerNavBar id="boton">
            <Burger />
          </ContainerNavBar>

          <ContTxtTitle ref={ContTxtTitleRef}>
            <ConTxT>
              <AeroportFontCont
                sizePhone="55px"
                sizeSmall="85px"
                sizeMedium="55px"
                sizeBig="110px"
                lHeightDefault="1"
                pTop="30px"
              >
                <a>Our Stories</a>
              </AeroportFontCont>
              <AeroportFontCont
                sizePhone="16px"
                sizeSmall="16px"
                sizeMedium="10px"
                sizeBig="16px"
                lHeightPhone="1.5"
                lHeightSmall="1.8"
                lHeightMedium="1.5"
                lHeightBig="1.6"
              >
                <Filtros
                  id="filtros1"
                  onClick={() =>
                    dispatch(DesactivadoFiltroArchitects()) &
                    dispatch(DesactivadoFiltroDevelopers())
                  }
                >
                  <a
                    id="idALL"
                    style={{ color: "#ef4125" }}
                    onClick={() =>
                      Reset() & Anima() & setFirstFilterCardSize("G")
                    }
                  >
                    ALL,{" "}
                  </a>
                  <a
                    id="idRENDERS"
                    onClick={() =>
                      AllOff() &
                      setStateRenders(true) &
                      Anima() &
                      TextColor("idRENDERS") &
                      SpyGA("Clicked", "BT_OURSTORIES_RENDERS") &
                      setFirstFilterCardSize("G")
                    }
                  >
                    RENDERS,{" "}
                  </a>
                  <a
                    id="idMOVIES"
                    onClick={() =>
                      AllOff() &
                      setStateMovies(true) &
                      Anima() &
                      TextColor("idMOVIES") &
                      SpyGA("Clicked", "BT_OURSTORIES_MOVIES") &
                      setFirstFilterCardSize("G")
                    }
                  >
                    MOVIES,{" "}
                  </a>
                  <a
                    id="idWEBVR"
                    onClick={() =>
                      AllOff() &
                      setStateWebVr(true) &
                      Anima() &
                      TextColor("idWEBVR") &
                      SpyGA("Clicked", "BT_OURSTORIES_WEBVR") &
                      setFirstFilterCardSize("S")
                    }
                  >
                    WEBVR,{" "}
                  </a>
                  <br />
                  <a
                    id="idARCHITECTS"
                    onClick={() =>
                      AllOff() &
                      setStateArchitects(true) &
                      Anima() &
                      TextColor("idARCHITECTS") &
                      SpyGA("Clicked", "BT_OURSTORIES_ARCHITECTS") &
                      setFirstFilterCardSize("S")
                    }
                  >
                    FOR ARCHITECTS,{" "}
                  </a>
                  <a
                    id="idDEVELOPERS"
                    onClick={() =>
                      AllOff() &
                      setStateDevelopers(true) &
                      Anima() &
                      TextColor("idDEVELOPERS") &
                      SpyGA("Clicked", "BT_OURSTORIES_DEVELOPERS") &
                      setFirstFilterCardSize("G")
                    }
                  >
                    FOR DEVELOPERS
                  </a>
                </Filtros>
              </AeroportFontCont>
            </ConTxT>
          </ContTxtTitle>
        </ContainerService>
      </MaxCont>

      <ContImgGeneral ref={cardAnimRef}>
        {/*   ACA SE AGREGA EL COMPONENTE (CARD_....) CON EL FILTRO CORRESPONDIENTE */}

        {          /*FITLROS*/ (renders || developers) && (<Card_Berilo onClick={() => SpyGA("Clicked", "BT_OURSTORIES_BERILO")}/>)}
        {          /*FILTROS*/ (renders || architects) && (<Card_Akitema onClick={() => SpyGA("Clicked", "BT_OURSTORIES_ARKITEMA")}/>)}
        {          /*FILTROS*/ (renders || developers) && (<Card_Glamping onClick={() => SpyGA("Clicked", "BT_OURSTORIES_GLAMPING")}/>)}
        {          /*FILTROS*/ (renders || architects) && (<Card_OP onClick={() => SpyGA("Clicked", "BT_OURSTORIES_OP")} />)}
        {          /*FILTROS*/ (webVr || renders || developers) && (<Card_Macarena onClick={() => SpyGA("Clicked", "BT_OURSTORIES_LAMACARENA")}/>)}
        {          /*FITLROS*/ (movies || renders || developers) && (<Card_Numa onClick={() => SpyGA("Clicked", "BT_OURSTORIES_NUMA")} />)}
        {          /*FITLROS*/ (renders || architects) && (<Card_FO onClick={() => SpyGA("Clicked", "BT_OURSTORIES_FO")} />)}
        {          /*FILTROS*/ (renders || architects) && (<Card_GMP onClick={() => SpyGA("Clicked", "BT_OURSTORIES_GMP")} />)}
        {          /*FILTROS*/ (webVr || movies || architects || developers) && (<Card_Car onClick={() => SpyGA("Clicked", "BT_OURSTORIES_CAR")} />)}
        {          /*FILTROS*/ (renders || architects || developers) && (<Card_Stills onClick={() => SpyGA("Clicked", "BT_OURSTORIES_STILLS")}/>)}
        {          /*FILTROS*/ (movies || architects) && (<Card_HCMA onClick={() => SpyGA("Clicked", "BT_OURSTORIES_HCMA")} />)}
      </ContImgGeneral>

      <Footer />
    </>
  );
};

export { Stories };
