import { createSlice } from '@reduxjs/toolkit'

export const VideoEstadoWorked2Slice = createSlice({
  

  name: 'videoEstadoWorked2',
  initialState: {
    value: false,
  },
  reducers: {
    PlayingW2: (state) => {
      state.value = true
    },
    PausedW2: (state) => {
      state.value = false 
    },
  },



})


export const { PlayingW2, PausedW2 } = VideoEstadoWorked2Slice.actions

export default VideoEstadoWorked2Slice.reducer

