import { createSlice } from '@reduxjs/toolkit'

export const VideoEstadoWorked4Slice = createSlice({
  

  name: 'videoEstadoWorked4',
  initialState: {
    value: false,
  },
  reducers: {
    PlayingW4: (state) => {
      state.value = true
    },
    PausedW4: (state) => {
      state.value = false 
    },
  },



})


export const { PlayingW4, PausedW4 } = VideoEstadoWorked4Slice.actions

export default VideoEstadoWorked4Slice.reducer

