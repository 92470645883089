import React from 'react';


import Card from "./Card";

import gmpa from "../../../img/web/Est/gmpBig.jpg";
import gmpb from "../../../img/web/Est/gmpBig.webp";


const Card_GMP = () => {
  return (
    <>
             <Card
                Id_Card="Id8"
                Header_Title="SANCHINARRO OFFICES"
                Header_SubTitle="RENDERS FOR ARCHITECTS."
                Size_Card="small"
                Footer_Paragraph="The biggest challenge for the realization of this building is to achieve a completely 
              sustainable building in which zero consumption is achieved."
                Image_Src_Jpg={gmpa}
                Image_Src_Webp={gmpb}
                SetLink="/work/GMP"
              />
    </>
  );
};
export default Card_GMP;