
import styled from 'styled-components';

const VLine = styled.div `
  width: 1PX;
  height: ${props => props.hSize};
  background-color: #000000;
  top:${props => props.top};
  z-index: 35; 
  padding: 0;
  margin: 0;
  position:relative;

  @media (max-width: 1050px)  {
    margin-left:4px;  
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  @media (min-width: 1050px)  {
    display:none;
  }
  @media (min-width: 800px)  {
    margin-left:20px;
  }
  `;



  const VLineBasic = styled.div `
  
  width: 1px;
  height: ${props => props.hSize}; //calc(100% - calc(5vw))
  background-color: #000000;
  //top:${props => props.top};
  align-self: center;

  @media (max-height: 500px)  {
    display: none;
  }

  @media screen and (min-width:0px) and (max-width: 480px) {
    height: calc(100% - calc(2rem));
  }
  //position:relative;  
    `;
  
  export {VLine ,VLineBasic};