import { createSlice } from '@reduxjs/toolkit'

export const LandingVideoSlice = createSlice({
  name: 'screen',
  initialState: {
    value: "Normal",
  },
  reducers: {
    FullScreen: (state) => {
      state.value = "FullScreen" //,
      //console.log("landing screen FullScreen")
    },
    Normal: (state) => {
      state.value = "Normal"// ,
      //console.log("landing screen Normal")
    },
    FullScreenCellphone: (state) => {
      state.value = "FullScreenCellphone" //,
      //console.log("landing screen FullScreen cellPhone")
    },
  },

})


export const { FullScreen, Normal, FullScreenCellphone} = LandingVideoSlice.actions

export default LandingVideoSlice.reducer

