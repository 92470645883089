
import { configureStore } from '@reduxjs/toolkit'
import Reducer1 from './PlayReelSlice'
import Reducer2 from './VideoEstado'
import Reducer3 from './InPage'
import Reducer4 from './VideoEstadoWorked1'
import Reducer5 from './VideoEstadoWorked2'
import Reducer6 from './VideoEstadoWorked3'
import Reducer7 from './VideoEstadoWorked4'
import Reducer8 from './Filter_Architects'
import Reducer9 from './Filter_Developers'
import Reducer10 from './LoaderRun'
import Reducer11 from './ImageWorkHeight'

export default configureStore({
  reducer: {
    
    screen: Reducer1,
    videoEstado: Reducer2,
    inPageHome: Reducer3,
    videoEstadoWorked1: Reducer4,
    videoEstadoWorked2: Reducer5,
    videoEstadoWorked3: Reducer6,
    videoEstadoWorked4: Reducer7,
    architectsEstado:Reducer8,
    developersEstado:Reducer9,
    loaderRuned:Reducer10,
    HeightEstado:Reducer11,
  },
})