import React from 'react';
import { useRef } from "react";
import styled from 'styled-components';
import { GlobalStyle } from '../../../src/fonts/fonts';
import { HLine } from '../HLine';
import { VLineBasic } from '../VLine';
import { BTNBlackWhite } from '../BTNBlackWh';
import { Arrow } from '../Arrow';
import { AeroportFontCont } from "../../fonts/AeroportFont_Cont.js";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { useDispatch, useSelector } from "react-redux";
import { InPageHome, NotInPageHome } from '../../redux/InPage'
import { ActivadoFiltroArchitects, DesactivadoFiltroArchitects } from '../../redux/Filter_Architects'
import { ActivadoFiltroDevelopers, DesactivadoFiltroDevelopers } from '../../redux/Filter_Developers'
//import Spy from '../../Spy';
//--------variables globales--------------
import { colorRojoNod } from '../../VariablesGlobales';
import SpyGA from "../../Observer.js";




const Underline = styled.div`
 width:0%;
 height:1px;
 background-color: black;
`;





const DivA = styled.div`
  cursor: default;
    z-index: 10;
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    background-color: ${colorRojoNod};
    position: fixed;
    justify-items: stretch;
    top: 0;
    right: 0;
    height: 100vh;
    width: 50vw;
    
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease-in-out;



    @media screen and (min-width:0px) and (max-width: 480px) {
      width: 100vw;
      grid-template-columns: 1rem 1rem auto 1rem 80px;
    }
    @media screen and (min-width:481px) and (max-width: 768px) {
      width: 100vw;
      grid-template-columns: 2.5rem 1vw auto 1vw 100px;  
      
    }
    @media screen and (min-width:769px) and (max-width: 1279px) {
      grid-template-columns: 2.5vw 1vw auto 1vw 120px;  
    }
    @media screen and (min-width:1280px){
      grid-template-columns: 2.5vw  1vw auto 1vw 120px;      
    }   
    a {
  color: #000000;
  text-decoration: none;

}

    //height
    @media  (max-height: 300px)  {
      height: 100vh;
    }
    @media  (max-height: 260px)  {
          height: 260px;
    }

 
    
`;


const DivAA = styled.div`
  display:grid;
  justify-items: end;
  //background-color: #333333;
`;

const DivSep = styled.div`
  //background-color: #356333;
`;


const DivAB = styled.div`
//background-color: azure;
  display: grid;
  align-items: start;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 36% 1px 30% 16% 1px 1px 10% 1px 1px 3% ;//8
  @media (min-height: 300px) and (max-height: 600px)  {
    grid-template-rows: 36% 1px 30% 16% 1px 1px 10% 1px 1px 3% ;//8
  }
  @media (min-height: 1px) and (max-height: 300px)  {
    grid-template-rows: 26% 1px 20% 16% 1px 1px 10% 1px 1px 1% ;//8
  }

`;

const DivAC = styled.div`
  //background-color: gray;
  display:grid;
  justify-items: start;
  justify-self: stretch;
`;


const DivABA = styled.div`
  //margin-top:2.5vw;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end ;

//--------------height
 @media (min-height: 300px) and (max-height: 800px)  {
 font-size:20px;
 }
 @media (min-height: 1px) and (max-height: 430px)  {
  margin-bottom:70px;
  font-size:15px;
  }
 

`;



const DivButton = styled.div`
  //opacity: 0.7px;
  display: grid;
  justify-items: start;
  align-items:end;
  z-index: 30;
  height:100%;
  width:100%;
  //margin-bottom: 50px;
  //background-color: green;

`;



const DivContactMedia = styled.div`
 // background-color: #559987;
  display: grid;
  justify-items: start;
  align-items: center;
  grid-template-columns: 58% 2% 40% ;
  grid-template-rows: repeat(1, 1fr);
  
  height:100%;
`;



const DivGriD = styled.div`

  display: flex;
  flex-direction: column;
  width:100%;
  font-family: 'Aeroport';
  font-size: 12px; 
  justify-items: center;
  align-items:start;
  
  @media screen and (min-width:0px) and (max-width: 480px) {
    font-size:9px;
    line-height: 1.0;
  }
  @media screen and (min-width:481px) and (max-width: 768px) {
    font-size:9px;
    line-height: 1.2;
  }
  @media screen and (min-width:769px) and (max-width: 1023px) {
    font-size:10px;
    line-height: 1.2;  
  }
  @media screen and (min-width:1024px) and (max-width: 1279px) {
    font-size:11px;
    line-height: 1.2;  
  }
  @media screen and (min-width:1280px){
    font-size:12px;
    line-height: 1.2;     
  }

    
//height
 @media  (max-height: 655px)  {
      font-size:8px;
      //line-height: 1.;
 }
 @media  (max-height: 426px) {
      font-size:7px;
 //     line-height: 1.0;
 }
 
`;





const FlechaDiv = styled.div`
  opacity: 0.01;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  //background-color: beige;
`;

const DivLinksF = styled.div`
  width: 100%;
  //background-color: aqua;
  display: grid;
  justify-items: start;
  align-items:start;
  grid-template-columns: 80%;
  grid-template-rows: repeat(1, 1fr);
`;

var Linked = styled.li`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width:100%;
  //background-color: blue;
`;

var Link2 = styled.a`
line-height: 1.2rem;
  &:hover{
    color: white;
  }

`;



const RightNav = ({ open }) => {


  // or you can also write
  // import htmlContent from 'path/to/html/file.html');





  //console.log("open :" + open)
  const flechaRef1 = useRef(null);
  const flechaRef2 = useRef(null);
  const flechaRef3 = useRef(null);
  const flechaRef4 = useRef(null);
  const flechaRef5 = useRef(null);
  const flechaRef6 = useRef(null);
  const flechaRef7 = useRef(null);
  const flechaRef8 = useRef(null);
  const flechaRef9 = useRef(null);

  const contTxTRef1 = useRef(null); //contTxTinferiorSubtxt <- div
  const contTxTRef2 = useRef(null);
  const contTxTRef3 = useRef(null);
  const contTxTRef4 = useRef(null);
  const contTxTRef5 = useRef(null);
  const contTxTRef6 = useRef(null);
  const contTxTRef7 = useRef(null);
  const contTxTRef8 = useRef(null);
  const contTxTRef9 = useRef(null);



  const underlineRef1 = useRef(null); //contTxTinferiorSubtxt <- div
  const underlineRef2 = useRef(null);
  const underlineRef3 = useRef(null);
  const underlineRef4 = useRef(null);
  const underlineRef5 = useRef(null);
  const underlineRef6 = useRef(null);
  const underlineRef7 = useRef(null);
  const underlineRef8 = useRef(null);
  const underlineRef9 = useRef(null);

  const fontP = "calc(35px + 8 * ((100vw - 320px) / 960))";//"45px";
  const fontS = "30px";
  const fontM = "30px";
  const fontB = "40px";

  const arrowP = "10";
  const arrowS = "13";
  const arrowM = "13";
  const arrowB = "15";

  let inPageHome = useSelector((state) => state.inPageHome.value)
  const dispatch = useDispatch()
  let current = window.location.pathname;

  return (
    <DivA open={open} id="menu" >
      <GlobalStyle />

      <DivAA>
        <VLineBasic hSize="calc(100% - calc(5vw))" />
      </DivAA>
      {/*------------------------------------------------------------------------------------------------------------------*/}
      <DivSep />
      {/*------------------------------------------------------------------------------------------------------------------*/}
      <DivAB>
        <DivABA style={{ marginTop: '2.5vw' }} >
          <DivLinksF onMouseLeave={() => gsap.fromTo(flechaRef1.current, { opacity: 1, duration: 0.5 }, { opacity: 0, duration: 0.5 })
            & gsap.fromTo(contTxTRef1.current, { translateX: 20, duration: 0.5 }, { translateX: 0, duration: 0.5 })
            & gsap.fromTo(underlineRef1.current, { width: "100%", duration: 0.5 }, { width: 0, duration: 0.5 })
          }
            onMouseEnter={() => gsap.fromTo(flechaRef1.current, { opacity: 0, duration: 0.5 }, { opacity: 1, duration: 0.5 })
              & gsap.fromTo(contTxTRef1.current, { translateX: 0, duration: 0.5 }, { translateX: 20, duration: 0.5 })
              & gsap.fromTo(underlineRef1.current, { width: 0, duration: 0.5 }, { width: "100%", duration: 0.5 })

            }>
            <FlechaDiv ref={flechaRef1}>
              <Arrow
                scaleNormal="10"
                scalePhone={arrowP}
                scaleSmall={arrowS}
                scaleMiddle={arrowM}
                scaleBig={arrowB}
                gCStart="1"
                gCEnd="2"
                gRStart="2"
                gREnd="3"
              />
            </FlechaDiv>
            <Linked ref={contTxTRef1}>
              <AeroportFontCont
                sizePhone={fontP}
                sizeSmall={fontS}
                sizeMedium={fontM}
                sizeBig={fontB}
                lHeightDefault="1"
              >
                <Link to="/" onClick={() => dispatch(InPageHome()) &
                 SpyGA("Clicked", "BT_MENU_HOME")} >
                  Home.
                </Link>


                <Underline ref={underlineRef1} />
              </AeroportFontCont>
            </Linked>
          </DivLinksF>

          <DivLinksF onMouseLeave={() => gsap.fromTo(flechaRef2.current, { opacity: 1, duration: 0.5 }, { opacity: 0, duration: 0.5 })
            & gsap.fromTo(contTxTRef2.current, { translateX: 20, duration: 0.5 }, { translateX: 0, duration: 0.5 })
            & gsap.fromTo(underlineRef2.current, { width: "100%", duration: 0.5 }, { width: 0, duration: 0.5 })
          }
            onMouseEnter={() => gsap.fromTo(flechaRef2.current, { opacity: 0, duration: 0.5 }, { opacity: 1, duration: 0.5 })
              & gsap.fromTo(contTxTRef2.current, { translateX: 0, duration: 0.5 }, { translateX: 20, duration: 0.5 })
              & gsap.fromTo(underlineRef2.current, { width: 0, duration: 0.5 }, { width: "100%", duration: 0.5 })
            }>
            <FlechaDiv ref={flechaRef2}>
              <Arrow
                scaleNormal="10"
                scalePhone={arrowP}
                scaleSmall={arrowS}
                scaleMiddle={arrowM}
                scaleBig={arrowB}
                gCStart="1"
                gCEnd="2"
                gRStart="2"
                gREnd="3"
              />
            </FlechaDiv>
            <Linked ref={contTxTRef2}>
              <AeroportFontCont
                sizePhone={fontP}
                sizeSmall={fontS}
                sizeMedium={fontM}
                sizeBig={fontB}
                lHeightDefault="1"
              >
                <p onClick={() => document.getElementById('Footer').scrollIntoView({ behavior: "smooth" })&
                   SpyGA("Clicked", "BT_MENU_CONTACT")
              }>
                  Contact.
                </p>

                <Underline ref={underlineRef2} />
              </AeroportFontCont>
            </Linked>
          </DivLinksF>

          <DivLinksF onMouseLeave={() => gsap.fromTo(flechaRef3.current, { opacity: 1, duration: 0.5 }, { opacity: 0, duration: 0.5 })
            & gsap.fromTo(contTxTRef3.current, { translateX: 20, duration: 0.5 }, { translateX: 0, duration: 0.5 })
            & gsap.fromTo(underlineRef3.current, { width: "100%", duration: 0.5 }, { width: 0, duration: 0.5 })
          }
            onMouseEnter={() => gsap.fromTo(flechaRef3.current, { opacity: 0, duration: 0.5 }, { opacity: 1, duration: 0.5 })
              & gsap.fromTo(contTxTRef3.current, { translateX: 0, duration: 0.5 }, { translateX: 20, duration: 0.5 })
              & gsap.fromTo(underlineRef3.current, { width: 0, duration: 0.5 }, { width: "100%", duration: 0.5 })
            }>
            <FlechaDiv ref={flechaRef3}>
              <Arrow
                scaleNormal="10"
                scalePhone={arrowP}
                scaleSmall={arrowS}
                scaleMiddle={arrowM}
                scaleBig={arrowB}
                gCStart="1"
                gCEnd="2"
                gRStart="2"
                gREnd="3"
              />
            </FlechaDiv>
            <Linked ref={contTxTRef3}>
              <AeroportFontCont
                sizePhone={fontP}
                sizeSmall={fontS}
                sizeMedium={fontM}
                sizeBig={fontB}
                lHeightDefault="1"
              >
                <div onClick={() =>
                  dispatch(InPageHome()) & 
                  ((current == '/') ? document.getElementById('Worked').scrollIntoView({ behavior: "smooth" }) : "") &
                  SpyGA("Clicked", "BT_MENU_CLIENTS")
                }>
                  <Link to="/" >
                    Clients.
                  </Link>

                </div>
                <Underline ref={underlineRef3} />
              </AeroportFontCont>
            </Linked>
          </DivLinksF>

          <DivLinksF onMouseLeave={() => gsap.fromTo(flechaRef4.current, { opacity: 1, duration: 0.5 }, { opacity: 0, duration: 0.5 })
            & gsap.fromTo(contTxTRef4.current, { translateX: 20, duration: 0.5 }, { translateX: 0, duration: 0.5 })
            & gsap.fromTo(underlineRef4.current, { width: "100%", duration: 0.5 }, { width: 0, duration: 0.5 })
          }
            onMouseEnter={() => gsap.fromTo(flechaRef4.current, { opacity: 0, duration: 0.5 }, { opacity: 1, duration: 0.5 })
              & gsap.fromTo(contTxTRef4.current, { translateX: 0, duration: 0.5 }, { translateX: 20, duration: 0.5 })
              & gsap.fromTo(underlineRef4.current, { width: 0, duration: 0.5 }, { width: "100%", duration: 0.5 })
            }>
            <FlechaDiv ref={flechaRef4}>
              <Arrow
                scaleNormal="10"
                scalePhone={arrowP}
                scaleSmall={arrowS}
                scaleMiddle={arrowM}
                scaleBig={arrowB}
                gCStart="1"
                gCEnd="2"
                gRStart="2"
                gREnd="3"
              />
            </FlechaDiv>
            <Linked ref={contTxTRef4}>
              <AeroportFontCont
                sizePhone={fontP}
                sizeSmall={fontS}
                sizeMedium={fontM}
                sizeBig={fontB}
                lHeightDefault="1"
              >

                <Link to="/Stories" onClick={() => 
                  dispatch(NotInPageHome()) & 
                  window.removeEventListener('scroll', onscroll) &
                  SpyGA("Clicked", "BT_MENU_STORIES")} >
                  Stories.
                </Link>

                {/*href="#Worked" */}
                <Underline ref={underlineRef4} />
              </AeroportFontCont>
            </Linked>
          </DivLinksF>


          {/*         gallery       */}

          <DivLinksF onMouseLeave={() => gsap.fromTo(flechaRef5.current, { opacity: 1, duration: 0.5 }, { opacity: 0, duration: 0.5 })
            & gsap.fromTo(contTxTRef5.current, { translateX: 20, duration: 0.5 }, { translateX: 0, duration: 0.5 })
            & gsap.fromTo(underlineRef5.current, { width: "100%", duration: 0.5 }, { width: 0, duration: 0.5 })
          }
            onMouseEnter={() => gsap.fromTo(flechaRef5.current, { opacity: 0, duration: 0.5 }, { opacity: 1, duration: 0.5 })
              & gsap.fromTo(contTxTRef5.current, { translateX: 0, duration: 0.5 }, { translateX: 20, duration: 0.5 })
              & gsap.fromTo(underlineRef5.current, { width: 0, duration: 0.5 }, { width: "100%", duration: 0.5 })
            }>
            <FlechaDiv ref={flechaRef5}>
              <Arrow
                scaleNormal="10"
                scalePhone={arrowP}
                scaleSmall={arrowS}
                scaleMiddle={arrowM}
                scaleBig={arrowB}
                gCStart="1"
                gCEnd="2"
                gRStart="2"
                gREnd="3"
              />
            </FlechaDiv>
            <Linked ref={contTxTRef5}>
              <AeroportFontCont
                sizePhone={fontP}
                sizeSmall={fontS}
                sizeMedium={fontM}
                sizeBig={fontB}
                lHeightDefault="1"
              >

                <Link to="/GalleryImages" onClick={() => 
                  dispatch(NotInPageHome()) & 
                  window.removeEventListener('scroll', onscroll) & 
                  (document.body.scrollTop = document.documentElement.scrollTop = 0) &
                  SpyGA("Clicked", "BT_MENU_WORK")
                  } >
                  Work.
                </Link>

                {/*href="#Worked" */}
                <Underline ref={underlineRef5} />
              </AeroportFontCont>
            </Linked>
          </DivLinksF>


          {/*         gallery       */}





        </DivABA>

        <DivSep>

          <HLine left="0px" wSize="75%" />

        </DivSep>

        <DivABA style={{ marginTop: '1vw' }} >
          <DivLinksF onMouseLeave={() => gsap.fromTo(flechaRef6.current, { opacity: 1, duration: 0.5 }, { opacity: 0, duration: 0.5 })
            & gsap.fromTo(contTxTRef6.current, { translateX: 20, duration: 0.5 }, { translateX: 0, duration: 0.5 })
            & gsap.fromTo(underlineRef6.current, { width: "100%", duration: 0.5 }, { width: 0, duration: 0.5 })
          }
            onMouseEnter={() => gsap.fromTo(flechaRef6.current, { opacity: 0, duration: 0.5 }, { opacity: 1, duration: 0.5 })
              & gsap.fromTo(contTxTRef6.current, { translateX: 0, duration: 0.5 }, { translateX: 20, duration: 0.5 })
              & gsap.fromTo(underlineRef6.current, { width: 0, duration: 0.5 }, { width: "100%", duration: 0.5 })
            }>
            <FlechaDiv ref={flechaRef6}>
              <Arrow
                scaleNormal="10"
                scalePhone={arrowP}
                scaleSmall={arrowS}
                scaleMiddle={arrowM}
                scaleBig={arrowB}
                gCStart="1"
                gCEnd="2"
                gRStart="2"
                gREnd="3"
              />
            </FlechaDiv>
            <Linked ref={contTxTRef6}>
              <AeroportFontCont
                sizePhone={fontP}
                sizeSmall={fontS}
                sizeMedium={fontM}
                sizeBig={fontB}
                lHeightDefault="1"
              >
                <Link to="/LowMenuFirst" >
                  <div onClick={() => console.log("clickeado") &
                    dispatch(NotInPageHome()) &
                    window.removeEventListener('scroll', onscroll) &
                    dispatch(ActivadoFiltroArchitects()) &
                    dispatch(DesactivadoFiltroDevelopers()) &
                    SpyGA("Clicked", "BT_MENU_ARCHITECTS")
                    }>

                    Architects.

                  </div>
                </Link>
                <Underline ref={underlineRef6} />
              </AeroportFontCont>
            </Linked>

          </DivLinksF>

         {/* <DivLinksF onMouseLeave={() => gsap.fromTo(flechaRef7.current, { opacity: 1, duration: 0.5 }, { opacity: 0, duration: 0.5 })
            & gsap.fromTo(contTxTRef7.current, { translateX: 20, duration: 0.5 }, { translateX: 0, duration: 0.5 })
            & gsap.fromTo(underlineRef7.current, { width: "100%", duration: 0.5 }, { width: 0, duration: 0.5 })
          }
            onMouseEnter={() => gsap.fromTo(flechaRef7.current, { opacity: 0, duration: 0.5 }, { opacity: 1, duration: 0.5 })
              & gsap.fromTo(contTxTRef7.current, { translateX: 0, duration: 0.5 }, { translateX: 20, duration: 0.5 })
              & gsap.fromTo(underlineRef7.current, { width: 0, duration: 0.5 }, { width: "100%", duration: 0.5 })
            }>
            <FlechaDiv ref={flechaRef7}>
              <Arrow
                scaleNormal="10"
                scalePhone={arrowP}
                scaleSmall={arrowS}
                scaleMiddle={arrowM}
                scaleBig={arrowB}
                gCStart="1"
                gCEnd="2"
                gRStart="2"
                gREnd="3"
              />
            </FlechaDiv>
            <Linked ref={contTxTRef7}>
              <AeroportFontCont
                sizePhone={fontP}
                sizeSmall={fontS}
                sizeMedium={fontM}
                sizeBig={fontB}
                lHeightDefault="1">

                <Link to="/LowMenuSecond" >
                  <div onClick={() =>
                    dispatch(NotInPageHome()) &
                    window.removeEventListener('scroll', onscroll) &
                    dispatch(DesactivadoFiltroArchitects()) &
                    dispatch(ActivadoFiltroDevelopers()) &
                    Spy("Events", "BMenuInteriorismClicked")
                  }>
                    Developers.
                  </div>
                </Link>
                <Underline ref={underlineRef7} />
              </AeroportFontCont>
            </Linked>
                </DivLinksF>*/}

          <DivLinksF onMouseLeave={() => gsap.fromTo(flechaRef8.current, { opacity: 1, duration: 0.5 }, { opacity: 0, duration: 0.5 })
            & gsap.fromTo(contTxTRef8.current, { translateX: 20, duration: 0.5 }, { translateX: 0, duration: 0.5 })
            & gsap.fromTo(underlineRef8.current, { width: "100%", duration: 0.5 }, { width: 0, duration: 0.5 })
          }
            onMouseEnter={() => gsap.fromTo(flechaRef8.current, { opacity: 0, duration: 0.5 }, { opacity: 1, duration: 0.5 })
              & gsap.fromTo(contTxTRef8.current, { translateX: 0, duration: 0.5 }, { translateX: 20, duration: 0.5 })
              & gsap.fromTo(underlineRef8.current, { width: 0, duration: 0.5 }, { width: "100%", duration: 0.5 })
            }>
            <FlechaDiv ref={flechaRef8}>
              <Arrow
                scaleNormal="10"
                scalePhone={arrowP}
                scaleSmall={arrowS}
                scaleMiddle={arrowM}
                scaleBig={arrowB}
                gCStart="1"
                gCEnd="2"
                gRStart="2"
                gREnd="3"
              />
            </FlechaDiv>
            <Linked ref={contTxTRef8}>
              <AeroportFontCont
                sizePhone={fontP}
                sizeSmall={fontS}
                sizeMedium={fontM}
                sizeBig={fontB}
                lHeightDefault="1">

                <Link to="/LowMenuThird" >
                  <div onClick={() =>
                    dispatch(NotInPageHome()) &
                    window.removeEventListener('scroll', onscroll) &
                    dispatch(DesactivadoFiltroArchitects()) &
                    dispatch(ActivadoFiltroDevelopers()) &
                    SpyGA("Clicked", "BT_MENU_URBANIST")
                  }>
                    Urbanists.
                  </div>
                </Link>
                <Underline ref={underlineRef8} />
              </AeroportFontCont>
            </Linked>
          </DivLinksF>

          {/*<DivLinksF onMouseLeave={() => gsap.fromTo(flechaRef9.current, { opacity: 1, duration: 0.5 }, { opacity: 0, duration: 0.5 })
            & gsap.fromTo(contTxTRef9.current, { translateX: 20, duration: 0.5 }, { translateX: 0, duration: 0.5 })
            & gsap.fromTo(underlineRef9.current, { width: "100%", duration: 0.5 }, { width: 0, duration: 0.5 })
          }
            onMouseEnter={() => gsap.fromTo(flechaRef9.current, { opacity: 0, duration: 0.5 }, { opacity: 1, duration: 0.5 })
              & gsap.fromTo(contTxTRef9.current, { translateX: 0, duration: 0.5 }, { translateX: 20, duration: 0.5 })
              & gsap.fromTo(underlineRef9.current, { width: 0, duration: 0.5 }, { width: "100%", duration: 0.5 })
            }>
            <FlechaDiv ref={flechaRef9}>
              <Arrow
                scaleNormal="10"
                scalePhone={arrowP}
                scaleSmall={arrowS}
                scaleMiddle={arrowM}
                scaleBig={arrowB}
                gCStart="1"
                gCEnd="2"
                gRStart="2"
                gREnd="3"
              />
            </FlechaDiv>
            <Linked ref={contTxTRef9}>
              <AeroportFontCont
                sizePhone={fontP}
                sizeSmall={fontS}
                sizeMedium={fontM}
                sizeBig={fontB}
                lHeightDefault="1">

                <Link to="/Interiorism" >
                  <div onClick={() =>
                    dispatch(NotInPageHome()) &
                    window.removeEventListener('scroll', onscroll) &
                    dispatch(DesactivadoFiltroArchitects()) &
                    dispatch(ActivadoFiltroDevelopers()) &
                    Spy("Events", "BMenuRealStateClicked")
                  }>
                    Interiorism.
                  </div>
                </Link>
                <Underline ref={underlineRef9} />
              </AeroportFontCont>
            </Linked>
          </DivLinksF>*/}

        </DivABA>

        <DivButton>
          <Link to="/Form" onClick={() =>  SpyGA("Clicked", "BT_LETSTALK_MENU")} >
            <BTNBlackWhite txt="Let's Talk" pBottomDefault="1vw" />
          </Link>
        </DivButton>

        <DivSep />

        <HLine left="0px" wSize="100%" />

        <DivContactMedia>
          <DivGriD>
            <Link2>ESTUDIO NOD, INC.</Link2>
            <Link2 href="mailto:hello@estudionod.com" onClick={() =>  SpyGA("Clicked", "BT_MENU_EMAIL")}>HELLO@ESTUDIONOD.COM</Link2>

            <Link2 href="tel:+1 302 538 9684" onClick={() =>  SpyGA("Clicked", "BT_MENU_CELNUMBER")}>+13025389684</Link2>
          </DivGriD>
          <VLineBasic hSize="calc(100% - calc(2vw))" />
          <DivGriD style={{ marginLeft: "0.5vw" }}>

            <Link2 href="https://www.instagram.com/estudionod/"  onClick={() =>  SpyGA("Clicked", "BT_MENU_INSTAGRAM")}>INSTAGRAM,</Link2>


            <Link2 href="https://www.linkedin.com/company/nod3d/" onClick={() =>  SpyGA("Clicked", "BT_MENU_LINKEDIN")}>LINKEDIN</Link2>


            <Link2 href="https://www.behance.net/estudionod" onClick={() =>  SpyGA("Clicked", "BT_MENU_BEHANCE")}>BEHANCE</Link2>

          </DivGriD>
        </DivContactMedia>

        <DivSep />

        <HLine left="0px" wSize="100%" />



      </DivAB>
      {/*------------------------------------------------------------------------------------------------------------------*/}
      <DivSep />
      {/*------------------------------------------------------------------------------------------------------------------*/}
      <DivAC>
        <VLineBasic hSize="calc(100% - calc(5vw))" />
      </DivAC>
    </DivA>
  )
}

export default RightNav

