import styled from 'styled-components';
import ARROW from '../img/web/flecha.png';

const xSize = "124px";
const ySize = "124px";

const Arrow = styled.div`
    background-image: url(${ARROW});
    mix-blend-mode: multiply;
    background-repeat:no-repeat;
    //background-color: red;
    padding-bottom:  ${props => props.pBottom};
    padding-top:  ${props => props.pTop};
    padding-left:  ${props => props.pLeft};
    padding-right:  ${props => props.pRight};
    margin-bottom:  ${props => props.mBottom};
    margin-top:  ${props => props.mTop};
    margin-left:  ${props => props.mLeft};
    margin-right:  ${props => props.mRight};
    
    //background-size: 708px 162px; //tiene que ser igual al width y al height
    transform-origin: 0 0;
    width:calc((${xSize})*  (${props => props.scaleNormal}) /100  );
    height:calc((${ySize})   *  (${props => props.scaleNormal}) /100  );

    background-size: calc((${xSize}) *  (${props => props.scaleNormal})  /100 ) 
                     calc((${ySize})   *  (${props => props.scaleNormal}) /100  );
                   
                    
     //scalePhone="" scaleSmall="" scaleMiddle="" scaleBig=""
    @media screen and (min-width:0px) and (max-width: 480px) {
    width:calc((${xSize})  *  (${props => props.scalePhone})  / 100 );
    height:calc((${ySize})  *  (${props => props.scalePhone})  / 100 );

    background-size: calc((${xSize})  *  (${props => props.scalePhone})  / 100 ) 
                     calc((${ySize})  *  (${props => props.scalePhone})  / 100 );
                 
    }
    @media screen and (min-width:481px) and (max-width: 768px) {
    width:calc((${xSize}) *  (${props => props.scaleSmall})  / 100 );
    height:calc((${ySize}) *  (${props => props.scaleSmall})  / 100 );

    background-size: calc((${xSize}) *  (${props => props.scaleSmall})  / 100 ) 
                     calc((${ySize}) *  (${props => props.scaleSmall})  / 100 );   
                   
    }
    @media screen and (min-width:769px) and (max-width: 1279px) {
    width:calc((${xSize}) *  (${props => props.scaleMiddle})  / 100 );
    height:calc((${ySize}) *  (${props => props.scaleMiddle})  / 100 );

    background-size: calc((${xSize}) *  (${props => props.scaleMiddle})  / 100 ) 
                     calc((${ySize}) *  (${props => props.scaleMiddle})  / 100 );
                     
    }
    @media screen and (min-width:1280px){
    width:calc((${xSize}) *  (${props => props.scaleBig})  / 100 );
    height:calc((${ySize}) *  (${props => props.scaleBig})  / 100 );

    background-size: calc((${xSize}) *  (${props => props.scaleBig})  / 100 ) 
                     calc((${ySize}) *  (${props => props.scaleBig})  / 100 );
                     
    }
    

`;




export { Arrow };