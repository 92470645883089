import styled from 'styled-components';

import { useRef } from "react";
import gsap from "gsap";

import useMediaQuery from '../hooks/MediaQueryhook';



const Contenedor = styled.div`

      padding-bottom:  ${props => props.bottom};
      padding-top:  ${props => props.top};
`;

const BtnBw = styled.button`
cursor: pointer;
  background-color: rgb(0, 0, 0);

  color: rgb(255, 255, 255);
  font-size: 20px;
  border: solid 0.063rem #141414;
  margin-left: 0px;
  padding: 7px 20px;
   @media screen and (min-width:0px) and (max-width: 480px) {
      font-size: 10px;
  }
  @media screen and (min-width:481px) and (max-width: 768px) {
      font-size: 10px;
      padding: 5px 15px;
  }
  @media screen and (min-width:769px) and (max-width: 1279px) {
      font-size: 13px;
  }
  @media screen and (min-width:1280px){
      font-size: 15px;
  }

`;


function  BTNBlackWhite(props){

 const BtnRef = useRef(null);
 
 // calls a hook that checks if the mediaquery is true
 const isMediaQuery480px = useMediaQuery('(max-width: 480px)');


 



  return (

    
    <Contenedor bottom={props.pBottomDefault} top={props.pTopDefault}>
      <BtnBw    ref={BtnRef}  
                
                onMouseDown ={() => 
                  gsap.fromTo(BtnRef.current,
                  {duration: 0.5,backgroundColor:'white',color: 'gray',borderColor:'white'},
                  {duration: 0.5,backgroundColor:'white',color: 'black',borderColor:'black'}) 
                 } 
 
                onMouseUp ={() => (isMediaQuery480px && 
                  gsap.fromTo(BtnRef.current,
                  {duration: 0.5,backgroundColor:'white',color: 'black'},
                  {duration: 0.5,backgroundColor:'black',color: 'white'}))}


                
                onMouseLeave={() => 
                  gsap.fromTo(BtnRef.current,
                  {duration: 0.5,backgroundColor:'white',color: 'black'},
                  {duration: 0.5,backgroundColor:'black',color: 'white'})} 
                  
                onMouseEnter={() => gsap.to(BtnRef.current,
                  {duration: 0.5,backgroundColor:'white',color: 'black'})}>

       {
       props.txt
       } 
      </BtnBw>
      </Contenedor>
  );


}



export {BTNBlackWhite};