import styled from 'styled-components';
import { ImageWebp } from '../../../components/ImageWebp';

//-BODYTWOPICS-START------------------------------------------------------------------------------------------------
const CntBodyTwoPicsframe = styled.div`
  width: 100%;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-height: 1050px) and (max-width: 480px) {
    width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const CntBodyTwoPicsInside = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  top: 0px;
  left: 0px;
  width: 100vw;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 40% 8% 52%;
    width: 100vw;
  }
  @media screen and (min-width: 769px) {
    grid-template-columns: 40% 10% 50%;
  }
`;

const CntBodyTwoPicsImage1 = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 110%;
  position: relative;
  //left: -50%;
  height: 100%;
  display: grid;
  justify-items: start;
  align-items: center;
  z-index: 1;
  overflow: hidden;

  @media screen and (min-width: 0px) and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    justify-content: start;
    //left: -55%;
    width: 90%;
    height: 100%;
    align-items: end;
  }
`;

const Image1BodyTwoPics1 = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 100%;
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  @media (min-height: 769px) {
    object-fit: cover;
  }
`;

const Image1BodyTwoPics2 = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 100%;
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  @media (min-height: 769px) {
    object-fit: cover;
  }
`;

const CntBodyTwoPicsImage2 = styled.div`
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
  height: 70%;
  align-self: center;
  display: grid;
  overflow: hidden;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    width: 90%;
    height: 100%;
    position: relative;
    justify-self: end;
    align-self: start;
  }
`;


const VacioBodyTwoPics = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
`;
//-BODYTWOPICS-END-------------------------------------------------------------------------------------------------------

function TwoPics(props) {

    return (
        <>

            <CntBodyTwoPicsframe>
                <CntBodyTwoPicsInside>
                    <CntBodyTwoPicsImage1>
                        <Image1BodyTwoPics1>
                            <ImageWebp webPBigSrc={props.imgWebpBig1} imgAltBigSrc={props.imgAltBig1} />
                        </Image1BodyTwoPics1>            
                    </CntBodyTwoPicsImage1>
                    <VacioBodyTwoPics />
                    <CntBodyTwoPicsImage2>
                        <Image1BodyTwoPics2>
                            <ImageWebp webPBigSrc={props.imgWebpBig2} imgAltBigSrc={props.imgAltBig2} />
                        </Image1BodyTwoPics2>
                    </CntBodyTwoPicsImage2>
                </CntBodyTwoPicsInside>
            </CntBodyTwoPicsframe>


        </>
    );

}



export  default TwoPics ;


