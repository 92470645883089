import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import gsap from "gsap/gsap-core";
import styled from "styled-components";
import Burger from "../../components/Nav/Burger.jsx";
import { BTNC } from "../../components/CircleButton";
import Loadable from "react-loadable";

const FullSite = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  width: 100vw;

  div {
    //opacity: 0.5;
    //  border-color: black;
    // border-style: solid;
    //  border-width: 1px;
  }
  cursor: default;
`;

const ContainerNavBar = styled.div`
  z-index: 50;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  position: fixed;
  justify-items: center;
  top: 0;
  right: 0;
  height: 0;
  width: 50vw;
  justify-self: stretch;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 100vw;
    grid-template-columns: 1rem 1rem auto 1rem 80px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    width: 100vw;
    grid-template-columns: 2.5rem 1vw auto 1vw 100px;
  }
  @media screen and (min-width: 769px) {
    grid-template-columns: 2.5vw 1vw auto 1vw 120px;
  }
`;

const ContainerNavBarInf = styled.div`
  z-index: 45;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  background-color: lightgray;
  position: fixed;
  justify-items: center;
  align-items: start;
  top: calc(100vh - 50px - 1rem - 50px);
  right: 0;
  height: 0%;
  width: 50vw;
  justify-self: stretch;

  @media screen and (min-width: 0px) and (max-width: 480px) {
    width: 100vw;
    grid-template-columns: 1rem 1rem auto 1rem 80px;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    width: 100vw;
    grid-template-columns: 2.5rem 1vw auto 1vw 100px;
  }
  @media screen and (min-width: 769px) {
    grid-template-columns: 2.5vw 1vw auto 1vw 120px;
  }
`;

const ContainerBloq = styled.div`
  z-index: 5;
  background-color: #eeeeee;
  height: 120px;
  width: 100vw;
  @media screen and (min-width: 0px) and (max-width: 480px) {
    display: none;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    display: none;
  }
`;

const DivContBt = styled.div`
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;
  z-index: 3;
  opacity: 1;
  border-radius: 50px;
`;

const Section1 = Loadable({
  //ex-landing
  loader: () => import("./Section_1"),
  loading() {
    return <div>Loading...</div>;
  },
});

const Section2 = Loadable({
  // ex arquitects
  loader: () => import("./Section_2"),
  loading() {
    return <div>Loading...</div>;
  },
});

const Section3 = Loadable({
  // our histories
  loader: () => import("./Section_3"),
  loading() {
    return <div>Loading...</div>;
  },
});

const Section4 = Loadable({
  //testimonials
  loader: () => import("./Section_4"),
  loading() {
    return <div>Loading...</div>;
  },
});

const Section5 = Loadable({
  //footer
  loader: () => import("./Footer"),
  loading() {
    return <div>Loading...</div>;
  },
});

function Home() {
  const [playing, setPlaying] = useState(true);
  const [loader, SetLoader] = useState(true);
  const buttonPlayRef = useRef(null);
  const full = useRef(null);

  window.addEventListener("scroll", scrolling);

  function scrolling() {
    if (!loader) {
      if (window.scrollY >= 10) {
        gsap.to(buttonPlayRef.current, {
          scale: 0,
          opacity: 0,
          duration: 0.5,
        });

        return;
      }
      if (window.scrollY < 10) {
        gsap.to(buttonPlayRef.current, {
          scale: 1,
          opacity: 1,
          duration: 0.5,
        });
        return;
      }
    }
  }

  let loaderRuned = useSelector((state) => state.loaderRuned.value);

  const [activacion, setActivacion] = useState(false);

  useEffect(() => {
    if (loader) {
      setTimeout(() => {
        SetLoader(false);
      }, 6000);
    }
    setActivacion(false);
  }, [loader]);

  //------------------------------------------------------------------------------------

  // Función para activar el estado
  const activate = () => {
    setActivacion(true);
  };

  // Función para agregar event listeners
  const addEventListeners = () => {
    window.addEventListener("mousemove", activate);
    window.addEventListener("wheel", activate);
    window.addEventListener("scroll", activate);
  };

  // Función para eliminar event listeners
  const removeEventListeners = () => {
    window.removeEventListener("mousemove", activate);
    window.removeEventListener("wheel", activate);
    window.removeEventListener("scroll", activate);
  };

  useEffect(() => {
    // Agregar event listeners al montar el componente
    addEventListeners();
    // Eliminar event listeners al desmontar el componente
    return removeEventListeners;
  }, []);
  //--------------------------------------------------------------------------

  return (
    <>
      {loader && (
        <style>
          {`/*------------ ESCONDER BARRA DURANTE LOADER ------------------*/
          /* Estilo para el contenedor principal */
          body {
            overflow-y: auto; /* Añade una barra de desplazamiento solo cuando sea necesario */
          }

          /* Estilo para ocultar la barra de desplazamiento en WebKit (Chrome, Safari, etc.) */
          body::-webkit-scrollbar {
            width: 0em; /* Ajusta el ancho según tus preferencias */
          }

          body::-webkit-scrollbar-thumb {
            background-color: #888; /* Color de la barra de desplazamiento */
          }
        `}
        </style>
      )}
      <meta name="Nod Studio" content="Architects Developers EstudioNod" />
      <FullSite ref={full} className="FullSite" id="FullSite">
        {loaderRuned == "yes" && (
          <div>
            <ContainerNavBar id="boton">
              <Burger />
            </ContainerNavBar>

            <ContainerNavBarInf>
              <DivContBt ref={buttonPlayRef}>
                <BTNC setPlaying={setPlaying} />
              </DivContBt>
            </ContainerNavBarInf>
          </div>
        )}
        {/*<p>Estado de activación: {activacion ? "true" : "false"}</p>*/}

        <Section1 playing={playing} />

        <ContainerBloq className="fade-in-section" />
        <Section2 className="fade-in-section" />
        {activacion && (
          <div>
            <Section3 className="fade-in-section" />
            <Section4 className="fade-in-section" />
            <Section5 className="fade-in-section" />
          </div>
        )}
      </FullSite>
    </>
  );
}

export default Home;
