import React from 'react';
import styled from 'styled-components';
import { AeroportFontCont } from "../../../fonts/AeroportFont_Cont.js";
import gsap from "gsap";
import { useState, useRef, useEffect } from "react";
import { AeroportFontBoldCont } from "../../../fonts/AeroportFontBold_Cont.js";
import { Link } from "react-router-dom";
//import { ImagenSize } from '../Work';
import useMediaQuery from '../../../hooks/MediaQueryhook';
import { ImageWebp } from "../../../components/ImageWebp.js"
import { MargenesCards } from '../../../VariablesGlobales.js';

import { useDispatch, useSelector } from "react-redux";
import { heightState } from '../../../redux/ImageWorkHeight'


//prueba

// con esto se cambian los margenes de estas tarjetas
const varMargenBig = MargenesCards("A");
const varMargenSmall = MargenesCards("B");


const ContFrame = styled.div`
background-color: #EEEEEE;
  display: grid;
  width:        ${props => props.$sizeCard == "big" ? 'calc(50% - 2px)' : 'calc(25% - 2px)'};//calc(50% - 2px);// resto el borde
  flex:         ${props => props.$sizeCard == "big" ? '1 1 50vw' : '1 1 20vw'};//1 1 50vw; //aca
  max-width:    ${props => props.$sizeCard == "big" ? '70vw' : ''}; //max-width: 70vw;

  @media screen and (min-width:0px) and (max-width: 939px) {
    flex:       ${props => props.$sizeCard == "big" ? '1 1 100vw' : '1 1 40vw'};
    max-width:  ${props => props.$sizeCard == "big" ? '100vw' : ''};
  }
  @media screen and (min-width:940px)  {
    flex:       ${props => props.$sizeCard == "big" ? '1 1 calc(50% - 4px)' : '1 1 calc(25% - 4px)'}; //'1 1 48vw':'1 1 30vw'};
    max-width:  ${props => props.$sizeCard == "big" ? '50%' : '25%'};
  }  
  height:auto;

`;

const ContainerService = styled.div`

  width: 100%;
  height:100%;
  display: grid;

`;

const CardImage = styled.div`
  width:calc(100% - 2px);
  display:flex;
  flex-direction: column;
  grid-template-columns: repeat(1, 1fr);
  border-color: #D6D6D6;
  border-width: 1px;
  border-style:solid ;

  @media screen and (min-width:0px) and (max-width: 769px) {
    border-top:${({ id }) => { if (id == "Principal") { return '0px solid #D6D6D6' } else { return '1px solid #D6D6D6' } }};
  } 
 
  justify-content: space-between;
  @media screen and (min-width:0px) and (max-width: 768px) {
    &:hover{
   //   background-color:#d6d6d6;
    }
  }

`;

const ContTxtSuper = styled.div`
  align-self: start;
  background-size:cover;

  @media screen and (min-width:0px) and (max-width: 768px) {
    margin-left: ${varMargenSmall};
    margin-right: ${varMargenSmall};
    margin-bottom: ${varMargenSmall};
    width: calc(100% - calc( ${varMargenSmall} * 2 ))  ;
  }
    @media screen and (min-width:769px) {
    margin-left: ${varMargenBig};
    margin-right: ${varMargenBig};
    margin-bottom: ${varMargenBig};
    width: calc(100% - calc( ${varMargenBig} * 2 ))  ;
  }

  object-fit: contain;
`;

const ContenedorSuperior = styled.div`

//background-color: gray;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  

  

  //utilizo esto porque en modo movil la imagen de la primera card se quita 
  @media screen and (min-width:0px) and (max-width: 269px) {
    height:${({ id }) => { if (id == "Principal") { return '30' } else { return '130vw' } }};
  } 
  @media screen and (min-width:270px) and (max-width: 319px) {
    height:${({ id }) => { if (id == "Principal") { return '30' } else { return '90vw' } }};
  } 
  @media screen and (min-width:320px) and (max-width: 349px) {
    height:${({ id }) => { if (id == "Principal") { return '30' } else { return '80vw' } }}; 
  } 
   @media screen and (min-width:350px) and (max-width: 650px) {
    height:${({ id }) => { if (id == "Principal") { return '30' } else { return '70vw' } }};
  } 
  @media screen and (min-width:651px) and (max-width: 768px) {
    height:${({ id }) => { if (id == "Principal") { return '30' } else { return '60vw' } }};  
  }



  @media screen and (min-width:769px) and (max-width: 939px) {
    height:60vw;   
  } 
  @media screen and (min-width:940px) and (max-width: 1500px) {
    height:35vw; 
  } 
  @media screen and (min-width:1501px) {
    height:32vw; 
  } 


`;

const ContTxTInferior = styled.div`
  display:flex;
  flex-direction: column;
  align-items:  flex-end;
  justify-content: flex-end;
  height:200px;
   @media screen and (min-width:0px) and (max-width: 480px) {
    width: 100%;
  }
  @media screen and (min-width:481px)  {
    width: ${props => props.$sizeCard == "big" ? '50%' : '100%'};
  }


`;



const ContTxtSub = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  justify-self: start;
  align-self: start;

  @media screen and (min-width:0px) and (max-width: 768px) {
    margin-left: ${varMargenSmall};
    margin-right: ${varMargenSmall};
    margin-top: ${varMargenSmall};
    margin-bottom: ${varMargenSmall};
    width: calc(100% - calc( ${varMargenSmall} * 2 ))  ;
  }
    @media screen and (min-width:769px) {
    margin-left: ${varMargenBig};
    margin-right: ${varMargenBig};
    margin-top: ${varMargenBig};
    width: calc(100% - calc( ${varMargenBig} * 2 ))  ;
  }



  

  //cursor: pointer;
  color:black;
  &:hover{
    color:#ef4125;
  }
`;

const ContTxtParf = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  justify-self: start;
  align-self: start;

  @media screen and (min-width:0px) and (max-width: 768px) {
    margin-left: ${varMargenSmall};
    margin-right: ${varMargenSmall};
    margin-top: ${varMargenSmall};
    width: calc(100% - calc( ${varMargenSmall} * 2 ))  ;
  }
    @media screen and (min-width:769px) {
    margin-left: ${varMargenBig};
    margin-right: ${varMargenBig};
    margin-top: ${varMargenBig};
    width: calc(100% - calc( ${varMargenBig} * 2 ))  ;
  }


`;


const Imagen = styled.div`
  width:  100%;
  height: auto;
  overflow: hidden;
  object-fit: contain;
 
`;


const ContenedorImagen = styled.div`
  overflow: hidden;

  @media screen and (min-width:0px) and (max-width: 768px) {
    margin: ${varMargenSmall}  ${varMargenSmall};
    width: calc(100% - calc( ${varMargenSmall} * 2 ))  ;
  }
    @media screen and (min-width:769px) {
    margin: ${varMargenBig}  ${varMargenBig};
    width: calc(100% - calc( ${varMargenBig} * 2 ))  ;
  }


`;




const Card = ({ Id_Card,
  Size_Card,
  Header_Title,
  Header_SubTitle,
  Footer_Paragraph,
  Image_Src_Webp,
  Image_Src_Jpg,
  SetLink
}) => {



  const [readmore, setReadmore] = useState(null);
  const [col, setCol] = useState("");
  const [allCol, setAllCol] = useState(false);

  const cardRef = useRef(null);
  const paragraphRef = useRef(null);
  const readMoreRef = useRef(null);
  const ImagenRef = useRef(null);

  let titleFontP = "12px";
  Size_Card == "big" ? titleFontP = "12px" : titleFontP = "12px";

  const titleFontS = "14px";
  const titleFontM = "16px";
  const titleFontB = "22px";

  const subTitleFontP = "10px";
  const subTitleFontS = "12px";
  const subTitleFontM = "12px";
  const subTitleFontB = "14px";

  const parrafFontP = "12px";
  const parrafFontS = "12px";
  const parrafFontM = "13px";
  const parrafFontB = "14px";

  const isMediaQueryMin769px = useMediaQuery('(min-width: 769px)');

  const AnimaLeave = () => {
    (isMediaQueryMin769px) ? (
      gsap.to(cardRef.current, { duration: 1, backgroundColor: '#EEEEEE', })
      &
      gsap.to(paragraphRef.current, { duration: 0.5, translateY: 0 })
      &
      gsap.to(readMoreRef.current, { duration: 1, opacity: 0, translateY: 0 }))
      : CallAllIdcardstoStyled()

  }

  const AnimaEnter = () => {

    (isMediaQueryMin769px) ? (
      gsap.to(cardRef.current, { duration: 1, backgroundColor: '#D6D6D6', })
      &
      gsap.to(paragraphRef.current, { duration: 0.5, translateY: -10 })
      &
      gsap.to(readMoreRef.current, { duration: 1, opacity: 1, translateY: -10 }))
      : CallAllIdcardstoStyled()


  }

  // crea un array de todos los id para luego estilarlos
  const CallAllIdcardstoStyled = () => {
    var elms = document.querySelectorAll("[id='readWorkid1']");
    for (var i = 0; i < elms.length; i++)
      elms[i].style.opacity = "1";
  }


  
  //-------------------------
  useEffect(() => {
    //llama al derector de incremento de ventana
    isMediaQueryMin769px ? setAllCol(false) : setAllCol(true)
  },[isMediaQueryMin769px]);





  return (
    <>
      <ContFrame $sizeCard={Size_Card} >
        <Link to={SetLink} style={{ textDecoration: 'none', color: 'black' }}>
          <ContainerService id={Id_Card} >

            <CardImage ref={cardRef} id={Id_Card}
              onMouseLeave={() =>
                AnimaLeave() &
                setReadmore(false) &
                setCol("")
              }
              onMouseEnter={() =>
                AnimaEnter() &
                setReadmore(true) &
                setCol(1)}
            >

              <ContenedorSuperior id={Id_Card}>

                {/*  //utilizo esto porque en modo movil la imagen de la primera card id:"principal"se quita */ }
                {(Id_Card != "Principal" || isMediaQueryMin769px) &&

                  <ContenedorImagen>
                    <Imagen ref={ImagenRef} $sizeCard={Size_Card}>
                      <ImageWebp webPBigSrc={Image_Src_Webp} imgAltBigSrc={Image_Src_Jpg} />
                    </Imagen>
                  </ContenedorImagen>

                }
                <ContTxtSuper>
                  <AeroportFontBoldCont //aeroport 4
                    sizePhone={titleFontP}
                    sizeSmall={titleFontS}
                    sizeMedium={titleFontM}
                    sizeBig={titleFontB}
                    lHeightDefault="1"
                    weightDefault="400"
                    UpLowCase="uppercase"
                  >
                    <a>{Header_Title}</a>
                  </AeroportFontBoldCont>
                  <AeroportFontCont  //aeroport 2
                    sizePhone={subTitleFontP}
                    sizeSmall={subTitleFontS}
                    sizeMedium={subTitleFontM}
                    sizeBig={subTitleFontB}
                    lHeightPhone="1.2"
                    weightDefault="10">
                    <a>{Header_SubTitle}</a>


                  </AeroportFontCont>
                </ContTxtSuper>

              </ContenedorSuperior>


              {/*<ContMiddle/>*/}

              <ContTxTInferior $sizeCard={Size_Card}>

                <ContTxtParf ref={paragraphRef}   >
                  <AeroportFontCont  //aeroport 2
                    sizePhone={parrafFontP}
                    sizeSmall={parrafFontS}
                    sizeMedium={parrafFontM}
                    sizeBig={parrafFontB}
                    lHeightPhone="1"
                    lHeightMedium="1.2"
                    weightDefault="10">
                    <p>{Footer_Paragraph}</p>
                  </AeroportFontCont>
                </ContTxtParf>



                <ContTxtSub readmore={readmore} ref={readMoreRef} id="readWorkid1">
                  {(col == 1 || allCol == true) && (

                    <AeroportFontBoldCont  //aeroport 2
                      sizePhone={subTitleFontP}
                      sizeSmall={subTitleFontS}
                      sizeMedium={subTitleFontM}
                      sizeBig={subTitleFontB}
                      weightDefault="400">
                      <p> Read more.</p>
                    </AeroportFontBoldCont>)}

                </ContTxtSub>


              </ContTxTInferior>
            </CardImage>

          </ContainerService>
        </Link>
      </ContFrame>
    </>
  );
};





export default Card;