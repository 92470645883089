import { createSlice } from '@reduxjs/toolkit'

export const ArchitectsEstadoSlice = createSlice({
  

  name: 'ArchitectsEstado',
  initialState: {
    value: false,
  },
  reducers: {
    ActivadoFiltroArchitects: (state) => {
      state.value = true
    },
    DesactivadoFiltroArchitects: (state) => {
      state.value = false 
    },
  },



})


export const { ActivadoFiltroArchitects, DesactivadoFiltroArchitects } = ArchitectsEstadoSlice.actions

export default ArchitectsEstadoSlice.reducer

