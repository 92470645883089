import { createSlice } from '@reduxjs/toolkit'

export const VideoEstadoWorked1Slice = createSlice({
  

  name: 'videoEstadoWorked1',
  initialState: {
    value: false,
  },
  reducers: {
    PlayingW1: (state) => {
      state.value = true
    },
    PausedW1: (state) => {
      state.value = false 
    },
  },



})


export const { PlayingW1, PausedW1 } = VideoEstadoWorked1Slice.actions

export default VideoEstadoWorked1Slice.reducer

